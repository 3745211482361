import { ILaunchListApiResponse } from "@/interfaces/ILaunchListDTO";
import { useEffect, useState } from "react";

function useModuleForM5<T>(targetUrl: string): T{
    const [data, setData] = useState<ILaunchListApiResponse>();
    let formattedData:ILaunchListApiResponse;
    const fetchData = () => fetch(targetUrl).then(response => response.json()).then(data => setData(data));
    
    if(Array.isArray(data?.data)){
        formattedData = {
            data: data?.data[0],
            resultInfo: data?.resultInfo!
        }
    }else{
        formattedData = data as ILaunchListApiResponse
    }

    useEffect(() => {
        if(targetUrl) fetchData();
    }, [targetUrl])

    return formattedData as T
}

export default useModuleForM5;