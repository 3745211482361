import { IAccordionListApiResponse } from "@/interfaces/IAccordionListDTO";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "@/hooks";

import ModuleComponentProps from "@/interfaces/ModuleComponent";
import FaqList from "./FaqList";
import NewsList from "./NewsList";
import AccordionList from "./AccordionList";

const ModuleTwelveWrapper: React.FC<ModuleComponentProps> = ({ data, language }: ModuleComponentProps) => {

    const moduleData = data as IAccordionListApiResponse;
    const { i18n } = useTranslation();
    const lang = language ? language : i18n.language;
    const [ isMobile ] = useIsMobile();

    const switchModule = useCallback(() => {
        switch (moduleData?.data?.grpListType) {
            case "grpFaqs":
            case "grpFaqsThemesList":
                return <FaqList
                    lang={lang}
                    faqData={{
                        grpFaqType: moduleData.data.grpFaqType,
                        lstFaqs: moduleData.data.lstFaqs,
                        xtaxFaqCategories: moduleData.data.xtaxFaqCategories,
                        lstFaqsThemes: moduleData.data.lstFaqsThemes,
                        lblTitle: moduleData.data.lblTitle,
                        lblSubtitle: moduleData.data.lblSubtitle
                    }} />;
            case "grpNews":
            case "grpNewsThemesList":
                return <NewsList
                    lang={lang}
                    newsData={{
                        grpNewsType: moduleData.data.grpNewsType,
                        grpListType: moduleData.data.grpListType,
                        xtaxNewsCategory: moduleData.data.xtaxNewsCategory,
                        lstNews: moduleData.data.lstNews,
                        lstNewsThemesList: moduleData.data.lstNewsThemesList,
                        lblTitle: moduleData.data.lblTitle,
                        lblSubtitle: moduleData.data.lblSubtitle
                    }} />;
            case "grpListAccordion":
                return <AccordionList
                    lang={lang}
                    accordionData={{
                        lslAccordion: moduleData.data.lslAccordion,
                        lblTitle: moduleData.data.lblTitle,
                        lblSubtitle: moduleData.data.lblSubtitle
                    }} />;
            default: null
        }
    }, [moduleData, lang])

    return (
        <>
            { moduleData.data.lblTitle[lang] ?
                <div className={`px-6 mt-[4%] mb-[6%] max-w-cont-2 mx-auto w-full underlineLink bullet-style`}>
                    <div className={`text-black`}>
                        {switchModule()}
                    </div >
                </div > 
            : <></> }
        </>
    );
}

export default ModuleTwelveWrapper;
