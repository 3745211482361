import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { IShopDTO, IShopList, ITerminals } from "@/interfaces/IShopDTO";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

import TerminalFilterAtom from "@/atoms/TerminalFilterAtom";
import AreaFilterAtom from "@/atoms/AreaFilterAtom";
import CategoryFilterAtom from "@/atoms/CategoruFilterAtom";
import ShoppingListCard from "../Card/ShoppingListCard";
import router from "next/router";
import MoonLoader from "react-spinners/MoonLoader";


interface IRestourantListProps {
    category: string,
    area: string,
    terminalNum: string
}

const RestourantList: React.FC<IRestourantListProps> = ({category, area, terminalNum}) => {

    const { i18n } = useTranslation();
    const lang = i18n.language;
    const [restourantList, setRestourantList] = useState<IShopDTO>();
    const terminal = process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE;
    const areaState = useRecoilState(AreaFilterAtom);
    const categoryState = useRecoilState(CategoryFilterAtom);
    const terminalState = useRecoilState(TerminalFilterAtom);
    const [areaType, setAreaType] = useState("");
    const [terminalType, setTerminalType] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    let foundRestourants = false;

    async function getRestourants() {

        if (process.env.NEXT_PUBLIC_SHOP_KEY) {

            return await fetch (
                `${process.env.NEXT_PUBLIC_SHOP_PATH}/afm/${lang.toLocaleUpperCase()}/${terminal}/food/list`, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'keyId': process.env.NEXT_PUBLIC_SHOP_KEY
                })
            });
        }        
    }

    useEffect(() => {

        getRestourants()
        .then(response => response!.json())
        .then((response) => {

            setRestourantList(response);
        })
        .catch(error => {

            setIsLoading(false);
            console.error('[RestourantList] shop error -> ', error);
        })
        .finally(() => {
            setIsLoading(false);
        })
    }, [])

    useEffect(() => {

        foundRestourants = false;
        switch(areaState[0]){
            case "all" : return setAreaType("");
            case t('shop.beforeFilter') : return setAreaType("01");
            case t('shop.afterFilter') : return setAreaType("02");
            default : return setAreaType("");
        }
    }, [areaState[0]]);

    useEffect(() => {

        foundRestourants = false;
        switch(terminalState[0]){
            case "all" : return setTerminalType("") ;
            case "t1" : return setTerminalType("T1");
            case "t2" : return setTerminalType("T2");
            default : return setTerminalType("");
        }
    }, [terminalState[0]]);

    return (
        <div className="grid desk:grid-cols-4 mob:grid-cols-2 tab:grid-cols-3 desk:gap-6 mob:gap-2">
        {
            !isLoading && restourantList?.terminals.map((term : ITerminals)=>{
                if(term.terminal === terminalType || terminalType === ''){
                    return term.items.map((restourant : IShopList)=>{
                        if(restourant.accessType === areaType || areaType === '')
                           if(restourant.subcategoryDescr === categoryState[0] || categoryState[0] === 'all' || categoryState[0] === ''){
                            foundRestourants = true;
                            return <ShoppingListCard
                            key={restourant.id}
                            id={restourant.id}
                            title={restourant.name!}
                            titleColor={"black"}
                            onClick={() => router.push(restourant.boutiqueUrl)}
                            onClickPath={`/${lang}/${t("shop.inAirport")}/${t("shop.br")}/${t("shop.detail").toLocaleLowerCase()}?id=` + restourant.id}
                            subtitle={t("shop.detail")}
                            subtitleColor={"black"}
                            imageUrl={restourant.logo}
                            detailCtaText={'detailCtaText'}
                            ctaText={restourant.boutiqueUrl ? t("shop.book") : ""}
                            backgroundCta={"black"}
                            onClickDetailCta={() => { }}
                            icon={'icon'}
                            hint={t("shop.map")}
                            hintIcon={'hintIcon'}
                            language={lang}
                            onClickHint={() => { }} />
                           }
                    })}
            })
        }
        {
            isLoading && 
            <div className="relative desk:left-[155%] tab:left-[100%]">
                <MoonLoader className="m-auto" color="#6ABACE" />
            </div>

        }
        {
            !isLoading && !foundRestourants &&
            <div className="relative text-center desk:left-[155%] tab:left-[100%]">
                <h1 className="font-bold text-2xl">{t('shop.noShopsFound')}</h1>
            </div>
        }
        </div>
	);
}

export default RestourantList
