import { atom } from "recoil";
import { IFlightDetailsDTO } from "../interfaces/IFlightDetailsDTO";


const FlightDetailsAtom = atom<null | IFlightDetailsDTO>({
    default: null,
    key: 'flightDetails'
})

export default FlightDetailsAtom;
