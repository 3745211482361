import { useIsMobile, useModule } from "../../hooks";
import { configColor, getPathByLink } from "@/utils";
import { useTranslation } from "react-i18next";

const Chip = dynamic(() => import("../ChipList/Chip"), { ssr: false });
import DOMPurify from "isomorphic-dompurify";
import ModuleComponentProps from "../../interfaces/ModuleComponent";

import React from "react";
import dynamic from "next/dynamic";
import { IVisoreInfoApiResponse } from "@/interfaces/IVisoreInfoDTO";

const VisoreInfo: React.FC<ModuleComponentProps> = ({ data, language, hidden }: ModuleComponentProps) => {

    const moduleData = data?.data ? data : useModule<IVisoreInfoApiResponse>(data);
    const [isMobile, isTablet] = useIsMobile();
    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;
    const mediaserverpath = moduleData?.data?.upiImageInfo?.mediaserverpath;
    const resourcename = moduleData?.data?.upiImageInfo?.resourcename;
    const imagePath = mediaserverpath && resourcename ? mediaserverpath + '/' + resourcename : "";
    const linkPath =  moduleData?.data?.lblLink ? moduleData?.data?.lblLink[lang] : "";

    const bgModuleColor: string = moduleData?.data?.enuBackground ? configColor('bg', moduleData?.data?.enuBackground) : "bg-LightGrey"
    const bgCtaColor: string = moduleData?.data?.enuBackgroundCta ? configColor('bg', moduleData?.data?.enuBackgroundCta) : "bg-black"
    const textColor: string = moduleData?.data?.enuTitleColor ? configColor('text', moduleData?.data?.enuTitleColor) : "text-black"
    const lblCtaColor: string = moduleData?.data?.enuBackgroundLabel ? configColor('text', moduleData?.data?.enuBackgroundLabel) : "text-white"
    
    return (
        // <div className={`rounded-3xl m-6 desk:my-16 desk:mx-36 py-7 px-10 desk:p-12 ${moduleData?.data?.grpTemplateType === "grpTemplateLogo" ? "flex flex-col desk:flex-row justify-start gap-5 items-center" : ""} ${bgModuleColor}`}>
        <div className="max-w-cont-2 w-full px-6 desk:px-0 desk:mx-auto mt-8 desk:mb-12" hidden={hidden}>
            <div className={`rounded-3xl  ${moduleData?.data?.grpTemplateType === "grpTemplateLogo" ? "flex flex-col desk:flex-row desk:justify-start gap-5 items-center p-12 " + bgModuleColor : "py-7"}`}>

                {imagePath ?
                    <div className={`${isMobile || isTablet ? 'flex w-full justify-start' : ''}`}>
                        <img src={imagePath} className="desk:w-32 desk:mr-10" />
                    </div>
                    : null}

                <div className={moduleData?.data?.grpTemplateType === "grpTemplateLogo" ? "max-w-full" : "px-6 flex flex-col desk:flex-row justify-between items-center gap-5"}>
                    {moduleData?.data?.txbTitle && moduleData?.data?.txbTitle[lang] || moduleData?.data?.txbSubTitle && moduleData?.data?.txbSubTitle[lang] ?
                        <div className="flex flex-col gap-3">
                            <div className="flex items-center">
                                <h1 className={`font-primary font-semibold text-lg desk:text-[32px] desk:text-2xl ${textColor}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(moduleData?.data?.txbTitle && moduleData?.data?.txbTitle[lang] ? moduleData?.data?.txbTitle[lang] : '') }} />
                            </div>
                            <div className={`flex items-center ${textColor}`}>
                                <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(moduleData?.data?.txbSubTitle && moduleData?.data?.txbSubTitle[lang] ? moduleData?.data?.txbSubTitle[lang] : '') }} />
                            </div>
                        </div>
                        : null}

                    {moduleData?.data?.lblLabelCta && moduleData?.data?.lblLabelCta[lang] ?
                        
                        <div className="flex items-center mt-2">
                            <Chip
                                text={moduleData?.data?.lblLabelCta[lang]}
                                bgColor={bgCtaColor}
                                txtColor={lblCtaColor}
                                hrefPath={linkPath != '' ? linkPath : getPathByLink(moduleData?.data, lang)}
                                width="max-w-full !whitespace-normal"
                                onClick={() => {}}
                                target={moduleData?.data?.flgTarget ? "_blank" : "_self"}
                            />
                        </div>
                    : null}
                </div>
            </div>
        </div>
    );
}

export default VisoreInfo;
