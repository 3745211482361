import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { LoyaltyBalanceDataAtom } from "@/atoms";
import { useAuth } from "@/context/AuthContext";
import {Suspense} from 'react';
import { t } from "i18next";

import fidelityProgramImage from '../../assets/img/viamilanoProgram.png';
import CustomIcon from "../CustomIcon/CustomIcon";
import Image from "next/image";
import DOMPurify from "isomorphic-dompurify";

interface IFidelityCardSectionProps {
	lang: string
}

//TODO: Download Card?
const FidelityCardSection: React.FC<IFidelityCardSectionProps> = ({ lang }) => {

    const auth = useAuth();
    const [loyaltyNumber, setLoyaltyNumber] = useState("");
    const [balanceData, setBalanceData] = useRecoilState(LoyaltyBalanceDataAtom);
    const [m11Data, setM11Data] = useState();
    const [barCode, setBarCode] = useState<string>()

    async function getDataFromM11() {

        return await fetch(
            `${process.env.NEXT_PUBLIC_BE_BASE_PATH}/rest/v1/SEA-LinMxp/contents/m11/${process.env.NEXT_PUBLIC_M11_ID_PROFILE_FIDELITY}`, {
        })
        .then(response => response?.json())
        .then((response) => {
            setM11Data(response.data.lslParagraphs[0].txbSubtitle[lang]);
        })
        .catch(error => {
            console.error("[FidelityCardSection] getDataFromM11 -> ", error);
        })
    }

    const getBalance = () => {

        const params = new URLSearchParams({
            accountNumber: auth?.user?.codTessera || '',
            kOperator: '0'
        })

        let url: string = `${process.env.NEXT_PUBLIC_LOYALTY_PATH}/ols-loyalty-services/v1/SealCreditAdapter/getBalance?${params.toString()}`;

        fetch(url, {

            headers: {
                'keyId': process.env.NEXT_PUBLIC_LOYALTY_KEY as string
            },
        })
        .then(response => response.json())
        .then(response => {
            setBalanceData(response.getBalanceResponse.result);
        } , error => console.error("[FidelityCardSection] getBalance -> ", error));
    };

    useEffect(() => {
        getDataFromM11();
        if (auth && !balanceData) getBalance();

        if (auth?.user) {
            setLoyaltyNumber(auth?.user?.loyalityNumber);
            setBarCode(`${process.env.NEXT_PUBLIC_OLS_MIDDLEWARE_PATH + '/sea-ols-middleware/barcode/EAN13/' + auth?.user?.loyalityNumber!}`)
        }        

    }, [auth])

    const fetchCard = () => {

        fetch(`${process.env.NEXT_PUBLIC_CARD_PDF_PATH}/${lang}/pdf-card?userId=${auth?.user?.userId}&dl=1`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'keyId': process.env.NEXT_PUBLIC_CARD_PDF_KEY!
            }),
        })
        .then(response => {

            return response.blob();
        })
        .then(response => {

            const pdf = new Blob([response], { type: 'application/pdf' });
            window.open(URL.createObjectURL(pdf))
        })
        .catch(error => console.error(error));

    }

    return (
        <div>
            <div className="font-semibold text-lg">{t('loyalty.myFidelityProgram')}</div>
            <div className="flex flex-col desk:flex-row gap-8 desk:max-w-[815px]">
                <div className="desk:w-[350px] desk:h-[225px]  p-8 flex flex-col gap-6 items-center shadow-xl rounded-3xl">
                    <Image src={fidelityProgramImage.src} alt={'Fidelity program logo'} width={130} height={70} />
                    <img src={barCode}></img>
                </div>
                <div className="desk:hidden mt-2 desk:mt-7">
                
                    <a onClick={() => fetchCard()} target="_blank">
                        <div className="bg-white w-full justify-center rounded-3xl cursor-pointer text-black py-3 px-4 font-bold text-sm uppercase flex gap-2 items-center">
                            <CustomIcon iconName="download" className="fill-black" />{t('loyalty.downloadCard')}
                        </div>
                    </a>
                    
                </div>
                <div className="w-full desk:w-1/2 max-h-full flex flex-col gap-8">
                    <Balance numberOfPoints={balanceData ? balanceData.balance.toLocaleString(lang) : 0} />
                    { m11Data ? 
                        <span className="text-base" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(m11Data) }} />
                        : ""
                    }
                </div>
            </div>
            <div className="hidden desk:block mt-12 desk:mt-7">
                <a onClick={() => fetchCard()} target="_blank">
                    <div className="bg-black w-fit rounded-3xl cursor-pointer text-white py-3 px-4 font-bold text-sm uppercase flex gap-2 items-center"><CustomIcon iconName="download" color="white" />{t('loyalty.downloadCard')}</div>
                </a>
            </div>
        </div>
    )
}

interface IBalanceProps {
    numberOfPoints: number | string
}

const Balance: React.FC<IBalanceProps> = ({numberOfPoints}: IBalanceProps) => {
 
    return (
        <div className="flex flex-col">
            <div className="text-sm font-bold mb-4 desk:mb-2">{t('loyalty.pointsLabel')}</div>
            <div className="flex text-5xl gap-1 items-end leading-9">
                <div className=" text-lightblue leading-9 font-semibold">
                    {numberOfPoints}
                </div>
                <div className="text-2xl leading-9">
                    {t('loyalty.points')}
                </div>
            </div>
        </div>
    )
}

export default FidelityCardSection;
