import { useIsMobile, useModule } from "@/hooks";
import { useTranslation } from "react-i18next";
import { IImageApiResponse, IVideoPlayerApiResponse } from "@/interfaces/IVideoPlayerDTO";
import { configColor } from "../../utils";

import DOMPurify from "isomorphic-dompurify";
import dynamic from "next/dynamic";
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

interface IVideoPlayerProps {
    data: IVideoPlayerApiResponse,
    language: string
}

const VideoPlayer: React.FC<IVideoPlayerProps> = ({ data, language }: IVideoPlayerProps) => {

    const moduleData = data;
    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;

    const [isMobile] = useIsMobile();
    const isVimeo: boolean = moduleData && moduleData?.data?.grpVideoFiles === "grpVimeoVideo" ? true : false
    const thumbnail =  useModule<IImageApiResponse>(moduleData?.data?.xconImageRepository?.targeturl)

    const getVideoUrl = () => {
        switch (moduleData?.data?.grpVideoFiles) {
            case "grpYoutubeUrl":
                return moduleData?.data?.lblEmbedUrl[lang]
            case "grpVimeoVideo":
                return moduleData?.data?.lblEmbedVideoVimeoUrl[lang]
            case "grpUploadVideos":
                return moduleData?.data?.upfVideoFiles[lang]?.mediaserverpath + "/" + moduleData?.data?.upfVideoFiles[lang]?.resourcename
            default:
                return ""
        }
    }

    const getThumbnailUrl = () => {
        if(thumbnail) {
            return thumbnail?.data?.image?.mediaserverpath + "/" + thumbnail?.data?.image?.resourcename
        }
        else return false
    }

    return (
        <div className="max-w-cont-2 mx-auto px-6 py-5">
            <div className={`${moduleData?.data?.enuTitleColor ? configColor('text', moduleData?.data?.enuTitleColor) : "text-black"} mb-[25px]`}>
                {moduleData?.data?.lblTitle &&
                    <h2
                        className="text-2xl desk:text-3xl font-semibold font-secondary"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(moduleData?.data?.lblTitle[lang]) }}></h2>}
                {moduleData?.data?.lblSubtitle &&
                    <h3
                        className="desk:text-lg font-primary mt-[10px]"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(moduleData?.data?.lblSubtitle[lang]) }}></h3>}
            </div>
            {moduleData &&
                <div>
                    <ReactPlayer
                        light={getThumbnailUrl()}
                        className='react-player'
                        controls
                        width="100%"
                        height={`${isMobile ? (!isVimeo ? "20rem" : "") : "32rem"}`}
                        url={getVideoUrl()}
                    />
                </div>}
        </div>
    );
}

export default VideoPlayer
