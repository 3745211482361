import { useEffect, useState } from "react";


/* const useModule = (targetUrl: string) => {
    const [data, setData] = useState();
    const fetchData = () => fetch(targetUrl).then(response => response.json()).then(data => setData(data));

    useEffect(() => {
        fetchData();
    }, [])

    return data;
} */

function useModule<T>(targetUrl: string): T{
    const [data, setData] = useState();
    const fetchData = () => fetch(targetUrl)
        .then(response => response.json())
        .then(data => setData(data))
        .catch((error) => {
            console.error('useModule Error -> ', error);
        })

    useEffect(() => {
        if(targetUrl) fetchData();
    }, [targetUrl])

    return data as T;
}

export default useModule;