import { LanguageAtom } from "@/atoms";
import AirlineListAtom from "@/atoms/AirlineListAtom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";

const useAirlineList = () => {
    const [airlineList, setAirlineList] = useRecoilState(AirlineListAtom);
    const {i18n} = useTranslation();
    const lang = i18n.language;

    const fetchAirlines = () => {
        fetch(process.env.NEXT_PUBLIC_FLIGHTS_BASE_PATH + `/${lang}/anagraphic/airlines/lists?airportReferenceIata=${process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE}&active=y`, {
            headers: {
                'keyId': process.env.NEXT_PUBLIC_GATEWAY_KEY as string
            }
        }).then(response => response.json())
        .then(response => setAirlineList(response.data))
    }

    useEffect(() => {
        fetchAirlines()
    }, [lang])

    return [airlineList];
}

export default useAirlineList;
