import { Mousewheel } from 'swiper';
import { useMemo, useState } from 'react';
import { configColor, getPathByLink } from '@/utils';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { useDataCard, useIsMobile, useModule } from "../../hooks";
import { IEventLaunchApiResponse, INewsCardApiResponse } from '../../interfaces/IEventLaunchDTO';
import { NewsCard } from "../Card"
import { useTranslation } from 'react-i18next';
import { isWithinInterval } from 'date-fns';

import DOMPurify from 'isomorphic-dompurify';
import ProgressBar from '@ramonak/react-progress-bar';
import ModuleComponentProps from '../../interfaces/ModuleComponent';

import "swiper/css";
import "swiper/css/pagination";

const carouselAutoplayTime = process.env.NEXT_PUBLIC_CAROUSEL_AUTOPLAY_MILLISECONDS && !isNaN(Number(process.env.NEXT_PUBLIC_CAROUSEL_AUTOPLAY_MILLISECONDS)) ? Number(process.env.NEXT_PUBLIC_CAROUSEL_AUTOPLAY_MILLISECONDS) : 3000;

const EventLaunch: React.FC<ModuleComponentProps> = ({ data, language }: ModuleComponentProps) => {
    const moduleData = data as IEventLaunchApiResponse;
    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;

    // const lstLaunch = moduleData?.data?.lstLaunch?.filter((launch) => { return launch?.xtaxAirports?.ti[0]?.label === "" || launch?.xtaxAirports?.ti[0]?.label?.toLowerCase() === process.env.NEXT_PUBLIC_AIRPORT_REFERENCE?.toLowerCase() }) || []
    const lstLaunch = moduleData?.data?.lstLaunch?.filter((launch) => { 

        let dateStart = new Date(launch.dteStart + ' ' + launch.lblStartTime);
        let dateEnd = new Date(launch.dteEnd + ' ' + launch.lblEndTime);
        let now = new Date();

        return isWithinInterval(now, { start: dateStart, end: dateEnd }) && launch?.xtaxAirports?.ti.filter((ti) => {
            
            return ti.label === process.env.NEXT_PUBLIC_AIRPORT_REFERENCE
        }).length > 0;
    }) || []
    const url = process.env.NEXT_PUBLIC_BE_BASE_PATH + "/rest/v1/SEA-LinMxp/contents/news?query=enuPageCategory=%27News%27"
    const cardUrlList = useMemo(() => {
        return moduleData?.data.lstNews?.map((item) => {
            return item?.xconNews?.targeturl;
        }) || []
    }, [moduleData])
    var automatismoNewsData = useModule<any>(url)
    if (moduleData?.data?.enuTypeInfo === "automatismo news" && !moduleData?.data?.lstAutomatismNews[0].flgAllNews) {
        automatismoNewsData = automatismoNewsData?.data?.filter((news: any) => { return moduleData?.data?.lstAutomatismNews[0]?.xtaxNewsCategory?.ti[0]?.label === news?.xtaxNewsCategory?.ti[0]?.label })
    }

    const selezioneNewsData = useDataCard<INewsCardApiResponse[]>({ urls: cardUrlList });
    const newsData = moduleData?.data?.enuTypeInfo === "automatismo news" ? (moduleData?.data?.lstAutomatismNews[0].flgAllNews ? automatismoNewsData?.data : automatismoNewsData) : selezioneNewsData
    const textColor = moduleData?.data?.enuTitleColor ? configColor('text', moduleData?.data?.enuTitleColor) : "text-black";

    const [isMobile] = useIsMobile();

    const [_, setActiveIndex] = useState(0);

    return (
        <div className='px-6 max-w-cont-2 mt-8 mx-auto w-full py-8 desk:py-12 flex flex-col desk:flex-row desk:h-[35rem]'>
            <div className='rounded-2xl desk:cursor-pointer h-full desk:w-3/5 desk:mr-[10px]'>
                {lstLaunch?.length > 1 ?
                    <Swiper
                        onRealIndexChange={newInstance => setActiveIndex(newInstance.realIndex)}
                        slidesPerView={1}
                        spaceBetween={20}
                        className="w-full h-full"
                        autoplay={{ delay: carouselAutoplayTime }}
                        wrapperClass='h-[90%]'>
                        {lstLaunch?.map((launch, index) => {

                            const resourcename = isMobile ? launch?.upiImageMobile[lang]?.resourcename : launch?.upiImageDesktop[lang]?.resourcename;
                            const mediaserverpath = isMobile ? launch?.upiImageMobile[lang]?.mediaserverpath : launch?.upiImageDesktop[lang]?.mediaserverpath;
                            const imagePath = mediaserverpath + "/" + resourcename;
                            const lblLabelCta = launch.lblLabelCta[lang];
                            const colorEnuBackgroundCta = launch.enuBackgroundCta;

                            return <SwiperSlide className='!w-full' key={index}>
                                {/* <LaunchCard
                                    data={launch}
                                    isMobile={isMobile}
                                    lang={lang}
                                    onClick={() => navigateTo(launch?.lblLink[lang], launch?.flgTarget)} /> */}
                                <div className="w-full h-full">
                                    <a href={getPathByLink(launch, lang)} target={launch?.flgTarget ? "_blank" : "_self"}>
                                        <img className='rounded-2xl h-full w-full' src={imagePath} />
                                        <div className='absolute left-3 bottom-0'>
                                            <h1 className={`text-xl desk:text-xl font-semibold mb-2.5 font-secondary ${colorEnuBackgroundCta === 'nero' ? 'text-black' : 'text-white'}`}>{lblLabelCta}</h1>
                                        </div>
                                    </a>
                                </div>
                            </SwiperSlide>
                        }
                        )}

                        <div className='flex justify-center'>
                            <div className="w-[35%] mt-6 mb-5">
                                <Progress />
                            </div>
                        </div>
                    </Swiper>
                    : lstLaunch?.map((item, index) => {

                        const resourcename = isMobile ? item?.upiImageMobile[lang]?.resourcename : item?.upiImageDesktop[lang]?.resourcename;
                        const mediaserverpath = isMobile ? item?.upiImageMobile[lang]?.mediaserverpath : item?.upiImageDesktop[lang]?.mediaserverpath;
                        const imagePath = mediaserverpath + "/" + resourcename;
                        const lblLabelCta = item.lblLabelCta[lang];
                        const colorEnuBackgroundCta = item.enuBackgroundCta;

                        return (
                            <div key={index} className="w-full h-full relative desk:mb-0 mb-4">
                                <a href={getPathByLink(lstLaunch[0], lang)} target={lstLaunch[0]?.flgTarget ? "_blank" : "_self"}>
                                    <img className='rounded-2xl h-full w-full' src={imagePath} />
                                    <div className='absolute left-3 bottom-0'>
                                        <h1 className={`text-xl desk:text-xl font-semibold mb-2.5 font-secondary ${colorEnuBackgroundCta === 'nero' ? 'text-black' : 'text-white'}`}>{lblLabelCta}</h1>
                                    </div>
                                </a>
                                
                            </div>
                        )
                    })
                }
            </div>

            <div className={`h-full desk:w-2/5 ml-[10px] ${textColor}`}>
                <div className=' mb-4 h-1/6'>
                    <h1 className='mb-2 font-bold text-3xl font-secondary'
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(moduleData?.data?.lblTitle && moduleData?.data?.lblTitle[lang] ? moduleData?.data?.lblTitle[lang] : "") }}></h1>
                    <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(moduleData?.data?.lblSubtitle && moduleData?.data?.lblSubtitle[lang] ? moduleData?.data?.lblSubtitle[lang] : "") }}></h2>
                </div>

                <div className='desk:h-[80%] desk:mt-15'>
                    <Swiper
                        onRealIndexChange={newInstance => setActiveIndex(newInstance.realIndex)}
                        direction={isMobile ? 'horizontal' : 'vertical'}
                        modules={[Mousewheel]}
                        spaceBetween={20}
                        slidesPerView={1}
                        className="h-full"
                        mousewheel={true}>
                        {newsData?.map((news: any, index: number) => {
                            return (news?.data?.lblTitleNews[lang] ?
                                <SwiperSlide key={index} className='!h-full'>
                                    <NewsCard
                                        data={moduleData?.data?.enuTypeInfo === "automatismo news" ? news : news?.data}
                                        lang={lang}
                                        isMobile={isMobile}
                                    // onClick={() => navigateTo(news?.data?.lblUrlPath[lang], false)} 
                                    />
                                </SwiperSlide>
                                : null)
                        }
                        )}
                        {isMobile ?
                            <div className='flex justify-center'>
                                <div className="w-[50%] mt-10">
                                    <Progress />
                                </div>
                            </div>
                            : null}
                    </Swiper>
                </div>
            </div >
        </div >
    );
};


const Progress: React.FC = () => {
    const swiperRef = useSwiper();

    return (
        swiperRef.slides.length > 1 ?
            <ProgressBar completed={swiperRef.activeIndex + 1} maxCompleted={swiperRef.slides.length} bgColor={'#000000'} baseBgColor={'#D3D3D3'} isLabelVisible={false} height={'5px'} />
            : null
    )
}
export default EventLaunch;
