import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from "react";
import { IChipListApiResponse } from "../../interfaces/IChipListDTO";
import { useIsMobile } from "../../hooks";
import { configColor, getPathByLink } from '@/utils';
import { useTranslation } from 'react-i18next';

const Chip = dynamic(() => import("../ChipList/Chip"), { ssr: false });
import DOMPurify from 'isomorphic-dompurify';
import ModuleComponentProps from '../../interfaces/ModuleComponent';

import 'swiper/css';
import "swiper/css/free-mode";
import dynamic from 'next/dynamic';
//import Chip from './Chip';

// const navigateTo = (text: string, target: boolean) => { !!text ? target ? window.open(text, '_blank') : window.location.href = text : null };

const ChipList: React.FC<ModuleComponentProps> = ({ data, language }: ModuleComponentProps) => {
  
  const moduleData:IChipListApiResponse = data;
  const { i18n } = useTranslation();
  const lang = language? language : i18n.language;
  const [isMobile, isTablet] = useIsMobile()

  const labelColor: string = moduleData?.data?.enuTitleColor ? configColor('text', moduleData?.data.enuTitleColor) : "text-black";
  const [canShowCarousel, setCanShowCarousel] = useState(false);


  // isMobile ? setCanShowCarousel(true) : setCanShowCarousel(false)
  // useEffect(() => {
  //   if (typeof window !== "undefined" && window.innerWidth < 768) setCanShowCarousel(true)
  // }, [canShowCarousel])


  return (
    <div className="desk:flex px-6 desk:gap-5 items-center desk:py-12 py-8 max-w-cont-2 mx-auto w-full">
      <div className='mb-5 desk:mb-0 whitespace-nowrap'>
        <h3 className={`font-bold ${labelColor}`}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(moduleData?.data?.lblTitle && moduleData?.data?.lblTitle[lang] ? moduleData?.data?.lblTitle[lang] : '') }}></h3>
      </div>

      {isMobile || isTablet
        ?
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={15}
          slidesOffsetBefore={0}
          slidesOffsetAfter={0}
          freeMode={true}
          modules={[FreeMode]}
        >
          {moduleData?.data?.lstCta?.map((item) => {
            return <SwiperSlide key={item.id} className="w-auto flex">
                <Chip
                  key={item.id}
                  text={item.lblLabelCta[lang]}
                  bgColor={item.enuBackgroundCta ? configColor('bg', item.enuBackgroundCta) : "bg-lightgrey"}
                  txtColor={item.enuBackground ? configColor('text', item.enuBackground) : "text-black"}
                  onClick={() => {}}
                  hrefPath={getPathByLink(item, lang)}
                  target={item?.flgTarget ? "_blank" : "_self"}
                />
            </SwiperSlide>
          })}
        </Swiper>
        :
        <div className="flex w-auto desk:gap-4 text-center">
          {moduleData?.data?.lstCta?.map((item, index) => {
            return <Chip
              key={index}
              text={item.lblLabelCta[lang]}
              bgColor={item.enuBackgroundCta ? configColor('bg', item.enuBackgroundCta) : "bg-lightgrey"}
              txtColor={item.enuBackground ? configColor('text', item.enuBackground) : "text-black"}
              width='w-full'
              onClick={() => {}}
              hrefPath={getPathByLink(item, lang)}
              target={item?.flgTarget ? "_blank" : "_self"}
            />
          })}
        </div>
      }
    </div>
  );
};

export default ChipList;
