import { IInfoCardProps } from './ICard';
import { useTranslation } from 'react-i18next';

import DOMPurify from 'isomorphic-dompurify';
import React from 'react';

const InfoCard: React.FC<IInfoCardProps> = ({ title, description, imageUrl, infoItems, language, bgColor }: IInfoCardProps) => {

    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;
    const tit = DOMPurify.sanitize(title);

    return (
        <div className={`flex flex-col gap-4 items-center rounded-2xl ${bgColor} min-w-[17rem] max-w-[18rem] py-7 px-6 h-auto`}>
            <div style={{ minWidth: '27px', maxWidth: '35px', height: '35px' }}>
                <img className="w-full h-full" src={imageUrl} />
            </div>
            <h2 className='font-bold text-center mb-4' dangerouslySetInnerHTML={{ __html: tit }} />
            <div className='flex flex-col gap-2 justify-between items-center h-full'>
                {infoItems?.map(infoItem => {

                    const ItemId = infoItem.id
                    const lblSubtitleInfo = DOMPurify.sanitize(infoItem.lblSubtitleInfo[lang]);
                    const flgTargetCtaInfo = infoItem.flgTargetCtaInfo ? '_blank' : '_self'
                    const blLinkCtaInfo = infoItem.blLinkCtaInfo[lang]

                    return (
                        <React.Fragment key={ItemId}>
                            {blLinkCtaInfo ?
                                <a key={ItemId} target={flgTargetCtaInfo} href={blLinkCtaInfo}>
                                    <div className='cursor-pointer'>
                                        <h3 className='hover:underline hover:text-[lightblue] text-center text-sm' dangerouslySetInnerHTML={{ __html: lblSubtitleInfo }} />
                                    </div>
                                </a>
                                : <h3 className='text-center text-sm' dangerouslySetInnerHTML={{ __html: lblSubtitleInfo }} />
                                }
                        </React.Fragment>)
                })}
            </div>
        </div>
    )
}

export default InfoCard;
