import { IFlightItem } from "@/components/FlightRow/FlightRow";
import { isAfter } from "date-fns";

function getFlightStatus(flightItem: IFlightItem, lang: string): string {

  if (flightItem.statusPubblicDescription == null) {

    return (isAfter(new Date(flightItem.estimatedTime), new Date(flightItem.scheduledTime))) ? DELAY_STATUS[lang] : null;
  }

  return flightItem.statusPubblicDescription;
}

const DELAY_STATUS: any = {
  it: 'RITARDO',
  en: 'DELAY'
}


export default getFlightStatus;
