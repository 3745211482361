import { useIsMobile, useModule } from "../../hooks";
import { SetStateAction, useEffect, useState } from "react";
import { IContactUs } from "@/interfaces/IContactUs";
import { IForm } from "@/interfaces/IForm";
import { useTranslation } from "react-i18next";

import StepperDot from "../Stepper/StepperDot";
import FormWrapper from "../Form/FormWrapper";
import { compareAsc } from "date-fns";

interface IContactUsProps {
    data: {
        grpTypology: string
    },
    language: string
}

const ContactUs: React.FC<any> = ({ data, language }: IContactUsProps) => {

    const { i18n } = useTranslation();
    const lang = language ? language : i18n.language;
    const [isMobile] = useIsMobile();
    const [activeStep, setActiveStep] = useState(0);
    const [motivationState, setMotivations] = useState<any>();
    const [arg, setArg] = useState<any>();
    const [argState, setArgState] = useState("");
    const [levelThreeInfo, setlevelThreeInfo] = useState<any>();
    const [levelThreeState, setlevelThreeState] = useState("");
    const [solution, setSolution] = useState<IContactUs[]>();
    const [form, setForm] = useState<IForm>();
    const [reset, setReset] = useState("");
    const { t } = useTranslation();
    const MotivationList : Array<any> = [  ];
    const ArgList : Array<any> = [  ];
    const LvlThreeList : Array<any> = [  ];

    const targetUrl = process.env.NEXT_PUBLIC_BE_BASE_PATH + "/rest/v1/SEA-LinMxp/contents/motivationFaq?query=enuTreeLevel=%271%27"
    const motivation = useModule<any>(targetUrl);

    async function getArg() {
        if (motivationState != 0)
        return await fetch(`${process.env.NEXT_PUBLIC_BE_BASE_PATH}/rest/v1/SEA-LinMxp/contents/motivationFaq?query=enuTreeLevel=%272%27+AND+metainfo.parent.ti.id=%27${motivationState.replace(/ /g, '%20')}%27+AND+xtaxAirport.ti.label=%27${process.env.NEXT_PUBLIC_AIRPORT_REFERENCE}%27`)
    }

    async function getLvlThree() {
        if (argState != "")
        return await fetch(`${process.env.NEXT_PUBLIC_BE_BASE_PATH}/rest/v1/SEA-LinMxp/contents/motivationFaq?query=enuTreeLevel=%273%27+AND+metainfo.parent.ti.id=%27${argState.replace(/ /g, '%20')}%27+AND+xtaxAirport.ti.label=%27${process.env.NEXT_PUBLIC_AIRPORT_REFERENCE}%27`)
    }

    async function getSolution() {
        if (levelThreeState != "")
        return await fetch(`${process.env.NEXT_PUBLIC_BE_BASE_PATH}/rest/v1/SEA-LinMxp/contents/faq?query=xtaxMotivation.ti.id=%27${levelThreeState.replace(/ /g, '%20')}%27+AND+xtaxAirports.ti.label=%27${process.env.NEXT_PUBLIC_AIRPORT_REFERENCE}%27`)
    }

    async function getForm() {
        if (levelThreeState != "")
        return await fetch(`${process.env.NEXT_PUBLIC_BE_BASE_PATH}/rest/v1/SEA-LinMxp/contents/widgetForm?query=xtaxMotivation.ti.id=%27${levelThreeState.replace(/ /g, '%20')}%27+AND+xtaxAirports.ti.label=%27${process.env.NEXT_PUBLIC_AIRPORT_REFERENCE}%27`)
    }

    useEffect(()=> {

        if (motivationState != 0) {

            getArg()
            .then(response => response?.json())
            .then((response) => {
                setArg(response?.data);
            })
            .catch(error => {
                console.error('[ContactUs] arg error -> ', error);
            })
        }
        else (setArgState(""))

        if (argState != "") {

            getLvlThree()
            .then(response => response?.json())
            .then((response) => {
                setlevelThreeInfo(response?.data);
            })
            .catch(error => {
                console.error('[ContactUs] levelThree error -> ', error);
            })
        }

        if (levelThreeState != "") {

            getSolution()
            .then(response => response?.json())
            .then((response) => {
                setSolution(response?.data);
            })
            .catch(error => {
                console.error('[ContactUs] solution error -> ', error);
            })
        }

        getForm()
        .then(response => response?.json())
        .then((response) => {
            setForm(response?.data[0]);
        })
        .catch(error => {
            console.error('[ContactUs] form error -> ', error);
        })
    }, [motivationState, argState, levelThreeState])

    return (
        <div className="desk:mt-32 desk:mx-32 desk:pb-14 desk:px-14 p-6 mt-16">
            <StepperDot 
                isMobile={isMobile}
                isTracking={false} 
                content={
                <div>
                    <h1 className="min-h-[66px] text-xl desk:text-3xl text-base/1 text-[#333333] font-bold font-secondary">{t("form.motivation")}</h1>
                    <select className={`bg-lightgrey desk:px-15 desk:py-4 rounded-xl ${isMobile ? "h-12 w-60 text-sm -translate-x-6" : "select"} mb-8 text-center`} style={activeStep >= 1 ? { color: "black"} : { color: "#999999"}} onChange={(e)=>{e.target.value === "default" ? setActiveStep(0) : setActiveStep(1);setMotivations(e.target.value)}}>     
                        <option value={"default"} id={"0"} selected>{t("form.selectMotivation")}</option>
                        {motivation?.data?.map((item: IContactUs)=>{
                                MotivationList.push(item);
                                MotivationList.sort((p1, p2) => (p1.txtMotivation[lang] > p2.txtMotivation[lang]) ? 1 : (p1.txtMotivation[lang] < p2.txtMotivation[lang]) ? -1 : 0);
                        })}
                        {MotivationList.map((item: any, index)=>{
                            return <option key={index} className={`text-center ${isMobile ? "h-12 w-50 text-sm " : ""}`} style={{ color: "black"}} value={item.metainfo.id}>{item.txtMotivation[lang]}</option>
                        })}
                    </select>
                </div>
                }
                isActive={activeStep >= 0 ? true : false}
                step={1}
                bgColor={"[#6ABACE]"}
            />
            <StepperDot 
                isMobile={isMobile}
                isTracking={false}  
                content={
                <>
                    <h1 className={`min-h-[66px] text-xl desk:text-3xl text-base/1 ${activeStep>=1 ? "text-black" : "text-[#C4C4C4]"} font-bold font-secondary`}>{t("form.argument")}</h1>
                    <div className="flex flex-col items-start min-w-full">
                        <select hidden={activeStep<1 ? true: false} className={`bg-lightgrey desk:px-15 desk:py-4 rounded-xl ${isMobile ? "h-12 w-60 mb-4 text-sm -translate-x-6" : "select mb-8"} text-center`} style={activeStep >= 2 ? { color: "black"} : { color: "#999999"}} value={activeStep<=1 ? reset : undefined} onChange={(e)=>{e.target.value === "default"? setActiveStep(1) : setActiveStep(2); setArgState(e.target.value)}} >
                            <option value={"default"} selected>{t("form.selectArgument")}</option>
                            {arg?.map((item: IContactUs)=>{
                               ArgList.push(item);
                               ArgList.sort((p1, p2) => (p1.txtMotivation[lang] > p2.txtMotivation[lang]) ? 1 : (p1.txtMotivation[lang] < p2.txtMotivation[lang]) ? -1 : 0);
                            })}
                            {ArgList.map((item: any, index)=>{
                                return <option key={index} className={`text-center ${isMobile ? "h-12 w-52 text-sm" : ""}`} id={item.id} style={{ color: "black"}} value={item.metainfo.id}>{item.txtMotivation[lang]}</option>
                            })}
                        </select>
                        <select hidden={activeStep<2 ? true: false} className={`bg-lightgrey desk:px-15 desk:py-4 rounded-xl ${isMobile ? "h-12 w-60 text-sm -translate-x-6" : "select"} mb-8 text-center text-clip`} style={activeStep >= 3 ? { color: "black"} : { color: "#999999"}} value={activeStep<=2 ? reset : undefined} onChange={(e)=>{e.target.value === "default"? setActiveStep(2) : setActiveStep(3); setlevelThreeState(e.target.value)}}>
                            <option className="text-center" value={"default"} selected>{t("form.selectOption")}</option>
                            {levelThreeInfo?.map((item: IContactUs)=>{
                                LvlThreeList.push(item);
                                LvlThreeList.sort((p1, p2) => (p1.txtMotivation[lang] > p2.txtMotivation[lang]) ? 1 : (p1.txtMotivation[lang] < p2.txtMotivation[lang]) ? -1 : 0);
                            })}
                            {LvlThreeList?.map((item: any, index)=>{
                                return <option key={index} className={`text-center ${isMobile ? "h-12 w-40 text-sm" : ""}`} style={{ color: "black"}} value={item.metainfo.id}>{item.txtMotivation[lang]}</option>
                            })}
                        </select>
                    </div>
                </>
                }
                isActive={activeStep >= 1 ? true : false}
                step={2}
                bgColor={"[#6ABACE]"}
            />
            <StepperDot 
                isMobile={isMobile}
                isTracking={false}  
                content={
                    <div>
                        <h1 className={`min-h-[66px] text-xl desk:text-3xl text-base/1 ${activeStep>=3 ? "text-black" : "text-[#C4C4C4]"} font-bold font-secondary`}>{t("form.solution")}</h1>
                        <div className={`${isMobile ? "-translate-x-16 w-[120%]" : ""} leading-4`} hidden={activeStep<3 ? true: false}>
                        {solution?.map((item: IContactUs, index)=>{
                            return  <div key={index} className={` font-primary w-full ${isMobile ? "text-[13px]" : ""}`}>
                                        <h3 dangerouslySetInnerHTML={{ __html: item.txbSubtitle[lang] }} />
                                    </div>
                        })}
                        </div>
                    </div>
                }
                isActive={activeStep >= 3 ? true : false}
                step={3}
                bgColor={"[#6ABACE]"}
            />
            <FormWrapper language={lang} isMobile={isMobile} form={form!} bannerHidden={activeStep >= 3 ? false : true} hidden={activeStep >= 3 ? false : true} sendText={t('form.sendEmailNow')} />
        </div>
    )
}

export default ContactUs;
