import { FlightDetailsAtom } from "@/atoms";
import { useRecoilValue } from 'recoil';
import { IStepperItem } from "@/interfaces/IStepper";
import { useTranslation } from "react-i18next";

interface IStepperProps {
    isMobile:boolean,
    content: IStepperItem,
    language: string
}

const DepartureCheckIn: React.FC<IStepperProps> = ({ isMobile, content, language }: IStepperProps) => {

    // TODO: Controllare logica destinationUe e destinationSchengen che arrivano da BE uguali

    const{ t } = useTranslation();
    const flightDetail = useRecoilValue(FlightDetailsAtom);
    const lang = language;

    return (
        <>
            <h1 className="min-h-[66px] text-lg desk:text-2xl text-base/1 text-[#333333] font-bold">{content.lblTitle[lang]}</h1>
            {/* {flightDetail?.destinationUe != null && flightDetail?.destinationSchengen != null ?
                flightDetail?.destinationUe === "S" || flightDetail?.destinationUe === "N"? 
                    <h1 className="text-[#333333]">{t('flights.departuresCheckIn1')}</h1>
                : flightDetail?.destinationSchengen == "S" ?
                    <h1 className="min-h-[66px] text-lg desk:text-2xl text-base/1 text-[#333333]">{t('flights.departuresCheckIn2')}</h1>
                : <></>
                : <></>
            } */}
            <h2>{content?.txbSubtitle[lang]}</h2>
        </>
    );
}

export default DepartureCheckIn;
