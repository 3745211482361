import { useMemo } from "react";
import { ICTAListApiResponse, ICTAListDTO } from "@/interfaces/ICTAListDTO";
import { configColor, getPathByLink } from "@/utils";
import { useTranslation } from "react-i18next";

import gridCol from "@/utils/gridCol";
import DOMPurify from "isomorphic-dompurify";
import CustomIcon from "../CustomIcon/CustomIcon";
import { useIsMobile } from "@/hooks";
import uuid from "react-uuid";

interface ICTAListProps {
  data: ICTAListApiResponse,
  language: string
}

const CTAList: React.FC<ICTAListProps> = ({ data, language }: ICTAListProps) => {

  const moduleData = data;

  const { i18n } = useTranslation();
  const lang = language? language : i18n.language;
  const [ isMobile ] = useIsMobile(); 

  let isMinimal: boolean = moduleData?.data && moduleData?.data?.enuTypeTemplate?.toLowerCase() == "minimale" ? true : false;

  const lenghtListCTA: number = moduleData?.data?.lstCta?.length || 0;
  const maxColsMob: number = isMinimal ? 3 : 1;
  const maxColsTab: number = isMinimal ? 6 : 3;
  const maxColsDesk: number = isMinimal ? 9 : 4;
  const backgroundColorMinimal = 4

  const lblTitle: string = DOMPurify.sanitize(moduleData?.data?.lblTitle && moduleData?.data?.lblTitle[lang] ? moduleData?.data?.lblTitle[lang] : moduleData.lblTitle ? moduleData?.lblTitle[lang]! : '');

  const itemList = useMemo(() => {

    return moduleData?.data && moduleData?.data?.lstCta ? moduleData?.data?.lstCta?.map((item) => {

      let itemId: number = item.id
      let upiLogoCtaDesktop: string = item.upiLogoCtaDesktop.mediaserverpath + "/" + item.upiLogoCtaDesktop.resourcename
      const lblLabelCta: string = DOMPurify.sanitize(item.lblLabelCta[lang]);
      const enuBackgroundCta = item.enuBackgroundCta ? configColor('bg', item.enuBackgroundCta) : 'bg-black'
      const enuBackgroundLabel = item.enuBackgroundLabel ? configColor('text', item.enuBackgroundLabel) : 'bg-black'
      const colorIcon = item.enuBackgroundLabel ? configColor('fill', item.enuBackgroundLabel) : 'fill-black'

      return isMinimal ? (
       (getPathByLink(item, lang) != '') ?

          //NOTE: QUICK LINK (Minimal)
          <div key={itemId} className="flex flex-col items-center">
            <a href={getPathByLink(item, lang)} target={item.flgTarget ? "_blank" : "_self"} className={`${enuBackgroundCta} rounded-3xl p-3 w-fit cursor-pointer flex justify-center items-center`}>
              <img src={upiLogoCtaDesktop} alt="Logo" width={60} height={60} />
            </a>
            <div className="w-[135px]">
              <p dangerouslySetInnerHTML={{ __html: lblLabelCta }} className={`${enuBackgroundLabel} text-center w-fit m-auto`} />
            </div>
          </div>
        :
          <div key={itemId} className="flex flex-col items-center">
            <div className={`${enuBackgroundCta} rounded-3xl p-3 w-fit flex justify-center items-center`}>
              <img src={upiLogoCtaDesktop} alt="Logo" width={60} height={60} />
            </div>
            <div className="w-[135px]">
              <p dangerouslySetInnerHTML={{ __html: lblLabelCta }} className={`${enuBackgroundLabel} text-center w-fit m-auto`} />
            </div>
          </div>

      ) : (

        (getPathByLink(item, lang) != '') ?

        //NOTE: FAST LINK
        <a key={itemId} href={getPathByLink(item, lang)} target={item.flgTarget ? "_blank" : "_self"} className={`${enuBackgroundCta} rounded-3xl p-3 px-5 min-w-min cursor-pointer w-full h-full flex justify-between font-primary`}>
          <div className="flex items-center desk:gap-3 -translate-x-4 desk:translate-x-0">
            <img src={upiLogoCtaDesktop} alt="Logo" width={80} height={80} />
            <p dangerouslySetInnerHTML={{ __html: lblLabelCta }} className={`${enuBackgroundLabel} text-start tab:text-sm tab:font-medium desk:text-lg`} />
          </div>
          <div className="flex px-2">
            <CustomIcon color="" iconName="arrow" className={`${colorIcon} w-2`} />
          </div>
        </a>
        :
          <div key={itemId} className={`${enuBackgroundCta} rounded-3xl p-3 px-5 min-w-min w-full h-full flex justify-between font-primary`}>
            <div className="flex items-center gap-3">
              <img src={upiLogoCtaDesktop} alt="Logo" width={80} height={80} />
              <p dangerouslySetInnerHTML={{ __html: lblLabelCta }} className={`${enuBackgroundLabel} text-start tab:text-sm tab:font-medium desk:text-lg`} />
            </div>
            <div className="flex px-2">
              <CustomIcon color="" iconName="arrow" className={`${colorIcon} w-2`} />
            </div>
          </div>

      );
    }) : moduleData?.lstCta?.map((item: { id: number; upiLogoCtaDesktop: { mediaserverpath: string; resourcename: string; }; lblLabelCta: { [x: string]: string | Node; }; enuBackgroundCta: string; enuBackgroundLabel: string; flgTarget: any; }) => {

      let itemId: number = item.id
      let upiLogoCtaDesktop: string = item.upiLogoCtaDesktop.mediaserverpath + "/" + item.upiLogoCtaDesktop.resourcename
      const lblLabelCta: string = DOMPurify.sanitize(item.lblLabelCta[lang]);
      const enuBackgroundCta = item.enuBackgroundCta ? configColor('bg', item.enuBackgroundCta) : 'bg-black'
      const enuBackgroundLabel = item.enuBackgroundLabel ? configColor('text', item.enuBackgroundLabel) : 'bg-black'
      const colorIcon = item.enuBackgroundLabel ? configColor('fill', item.enuBackgroundLabel) : 'fill-black'

      return isMinimal ? (

       (getPathByLink(item, lang) != '') ?

          //NOTE: QUICK LINK (Minimal)
          <div key={itemId} className="flex flex-col items-center">
            <a href={getPathByLink(item, lang)} target={item.flgTarget ? "_blank" : "_self"} className={`${enuBackgroundCta} rounded-3xl p-3 w-fit cursor-pointer flex justify-center items-center`}>
              <img src={upiLogoCtaDesktop} alt="Logo" width={60} height={60} />
            </a>
            <div className="w-[135px]">
              <p dangerouslySetInnerHTML={{ __html: lblLabelCta }} className={`${enuBackgroundLabel} text-center w-fit m-auto`} />
            </div>
          </div>
        :
          <div key={itemId} className="flex flex-col items-center">
            <div className={`${enuBackgroundCta} rounded-3xl p-3 w-fit flex justify-center items-center`}>
              <img src={upiLogoCtaDesktop} alt="Logo" width={60} height={60} />
            </div>
            <div className="w-[135px]">
              <p dangerouslySetInnerHTML={{ __html: lblLabelCta }} className={`${enuBackgroundLabel} text-center w-fit m-auto`} />
            </div>
          </div>

      ) : (
        (getPathByLink(item, lang) != '') ?

        //NOTE: FAST LINK
        <div key={itemId} className={`${enuBackgroundCta} rounded-3xl p-1 min-w-min w-full h-full flex justify-between font-primary`}>
          <div className="flex items-center gap-3">
            <img src={upiLogoCtaDesktop} alt="Logo" width={80} height={80} />
            <p dangerouslySetInnerHTML={{ __html: lblLabelCta }} className={`${enuBackgroundLabel} text-start tab:text-sm font-bold desk:text-base font-bold -translate-y-4 min-w-[120%]`} />
          </div>
          <div className="flex absolute w-fit translate-y-10 translate-x-24 gap-1">
            <CustomIcon iconName="location-reward" className={`w-4 -translate-y-1`} />
            <a href={getPathByLink(item, lang)} target={item.flgTarget ? "_blank" : "_self"}>
              <p className="underline cursor-pointer">Ottieni indicazioni stradali</p>
            </a>
          </div>
        </div>
        :
          <div key={itemId} className={`${enuBackgroundCta} rounded-3xl p-3 px-5 min-w-min w-full h-full flex justify-between font-primary`}>
            <div className="flex items-center gap-3">
              <img src={upiLogoCtaDesktop} alt="Logo" width={80} height={80} />
              <p dangerouslySetInnerHTML={{ __html: lblLabelCta }} className={`${enuBackgroundLabel} text-start tab:text-sm tab:font-medium desk:text-lg`} />
            </div>
            <div className="flex px-2">
              <CustomIcon color="" iconName="arrow" className={`${colorIcon} w-2`} />
            </div>
          </div>
      );
    })
  }, [moduleData, lang])

  return (
    <div className={`${isMinimal ? 'py-3' : `py-4 mx-auto desk:px-6 px-4 ${moduleData.lblNotes === "Parking Map" ? "" : "desk:pb-14" } max-w-cont-2`}`}>

      <h2 dangerouslySetInnerHTML={{ __html: lblTitle }} className={`${isMinimal ? "hidden" : "block"} text-[#000000] uppercase text-2xl mb-3 tab:normal-case tab:text-xl desk:w-full ${moduleData?.data ? "" : "font-bold"}`} />

      <div className={`
        ${moduleData.lblNotes === "Parking Map" ? "" : `
        ${!isMinimal ? gridCol(lenghtListCTA, maxColsMob) : ""} 
        ${!isMinimal ? gridCol(lenghtListCTA, maxColsTab, "tab") : ""} 
        ${!isMinimal ? gridCol(lenghtListCTA, maxColsDesk, "desk") : ""} 
        ${!isMinimal ? !isMobile ? "grid grid-cols-3 desk:gap-6" : "gap-4 grid text-center aspect-[1280/326,89]" : isMobile ? "grid grid-cols-3" : "flex gap-4 flex-wrap w-fit m-auto py-4 justify-center"} 
        `}
      `}
      >
        {itemList}
      </div>
    </div>
  );
};

export default CTAList;
