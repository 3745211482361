import useModule from "./useModule";
import useOutsideClick from "./useOutsideClick";
import useDataCard from "./useDataCard";
import useMobileDetect from "./useMobileDetect";
import useIsMobile from "./useIsMobile";
import useCategory from "./useCategory";
import useCardManager from "./useCardManager";
import useAirlineList from "./useAirlineList";
import useIsApple from "./useIsApple";

export {
  useModule,
  useOutsideClick,
  useDataCard,
  useMobileDetect,
  useIsMobile,
  useCategory,
  useCardManager,
  useAirlineList,
  useIsApple
};
