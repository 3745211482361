import { Breadcrumbs } from "@/components";
import { configColor } from "@/utils";
import { useTranslation } from "react-i18next";
import { useIsMobile, useModule } from "../../hooks";

import DOMPurify from 'isomorphic-dompurify';

interface INewsDetailsProps {
    data: any,
    language: string
}

const NewsDetails: React.FC<INewsDetailsProps> = ({data, language}) => {

    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;
    const isMobile = useIsMobile();


    const getCategory = () => {
        let categoryData: any = useModule(data?.xtaxNewsCategory?.ti[0].targeturl);
        return categoryData?.data?.lblNameCategory[lang];
    }

    const lblTitleNews = DOMPurify.sanitize(data?.lblTitleNews[lang])
    const lblSubtitleNews = DOMPurify.sanitize(data?.lblSubtitleNews[lang])
    const dteNews = data?.dteNews
    const upiImageDesk = data?.lslParagraphs[0]?.upiImageDesktop[lang].mediaserverpath + '/' + data?.lslParagraphs[0]?.upiImageDesktop[lang].resourcename
    const upiImageMob = data?.lslParagraphs[0]?.upiImageMobile[lang].mediaserverpath + '/' + data?.lslParagraphs[0]?.upiImageMobile[lang].resourcename

    return (
        <>
            <div className="max-w-cont-1 mx-auto w-full">
                <div className="pt-4">
                    {!data?.flgHideNavigationéath ? <Breadcrumbs language={lang} /> : null}
                </div>


                <div className="p-EdgeMob tab:p-EdgeTab desk:p-EdgeDesk" >
                    <p className={`${configColor('text', 'azzurro')} font-bold`}>{getCategory()}</p>

                    <h1 className='text-4xl py-3 font-semibold font-secondary' dangerouslySetInnerHTML={{ __html: lblTitleNews }} />
                    <h2 className="py-2 font-semibold" dangerouslySetInnerHTML={{ __html: lblSubtitleNews }} />
                    <p className="py-5">{dteNews}</p>
                    { data?.lslParagraphs[0]?.upiImageDesktop[lang].mediaserverpath || data?.lslParagraphs[0]?.upiImageMobile[lang].mediaserverpath ?
                        <img src={isMobile ? upiImageMob : upiImageDesk} className="w-screen"></img>
                        : <></>
                    }
                    {data?.lslParagraphs.map((paragraph: any) => {

                        const enuSubtitleColor = configColor('text', paragraph.enuSubtitleColor)
                        const lblTitle = DOMPurify.sanitize(paragraph.lblTitle[lang])
                        const txbDescription = DOMPurify.sanitize(paragraph.txbDescription[lang])

                        return <div key={paragraph.id} className={`${enuSubtitleColor}`} >

                            <div className="pb-12 pt-8 underlineLink">
                                <h2 className={`text-xl font-bold text-red`} dangerouslySetInnerHTML={{ __html: lblTitle }} />
                                <h3 className={`py-5`} dangerouslySetInnerHTML={{ __html: txbDescription }} />
                            </div>


                        </div>
                    }
                    )
                    }
                </div>
            </div>
        </>
    )
};

export default NewsDetails;
