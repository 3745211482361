import { t } from "i18next";
import CustomIcon from "../CustomIcon/CustomIcon";
import { IFlightItem } from "../FlightRow/FlightRow";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { LanguageAtom } from "@/atoms";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../hooks";

interface IDestinationFlightRowProps {
    flightItem: IFlightItem,
    actionColumn?: boolean,
    actionLabel?: string,
    actionLink?: string,
    targetBlank?: boolean
}

const getDaysFromFrequency: (frequency: string, lang?: string) => string = (frequency: string, lang = 'en') => {
    if (!frequency) { console.log('Missing frequency'); return '' };
    if (frequency && frequency.length < 7) { console.log('No sufficient digit for frequency calc'); return '' }
    let result = '';
    const cleanArray = frequency.replace(" ", "").split("");

    let occCount: number = 0;
    cleanArray.forEach((digit) => {
        const currentNum: number = Number(digit);
        if (currentNum !== 0) occCount++
    })
    cleanArray.forEach((item, index) => {
        let currentNumber: number = Number(item)
        currentNumber !== 0 ? result += `${(result) && (occCount > 0) ? ' |' : ''} ${getDayFromIndex(index, lang)}` : ''
    })
    /* const scheduleString = frequency.replace(" ", "").split("").reduce((accumulator, newElement, index) => {
        return accumulator += (getDayFromIndex(index) + " | ")
        return !!newElement ? accumulator += (getDayFromIndex(index) + " | ") : accumulator
    }) */

    return result as string;
}

const getDayFromIndex = (index: number, lang = 'en') => {
    let result;
    switch (index) {
        case 0: result = lang === 'en' ? 'MON' : 'LUN';
            break;
        case 1: result = lang === 'en' ? 'TUE' : 'MAR';
            break;
        case 2: result = lang === 'en' ? 'WED' : 'MER';
            break;
        case 3: result = lang === 'en' ? 'THU' : 'GIO';
            break;
        case 4: result = lang === 'en' ? 'FRI' : 'VEN';
            break;
        case 5: result = lang === 'en' ? 'SAT' : 'SAB';
            break;
        case 6: result = lang === 'en' ? 'SUN' : 'DOM';
            break;
    }
    return result;
}

const DestinationFlightRow: React.FC<IDestinationFlightRowProps> = ({ actionLink = '', actionColumn = false, flightItem, actionLabel = '', targetBlank = false }: IDestinationFlightRowProps) => {
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const [isMobile] = useIsMobile();
    const freqString = useMemo(() => getDaysFromFrequency(flightItem.frequency, lang), [flightItem, lang]);

    return (
        <div className={"w-full relative flex border-b-2 pb-14 desk:pb-7 border-b-lightgrey items-center flex-wrap " + (!actionColumn && !isMobile ? 'justify-center' : 'justify-around')}>
            <div className="w-1/3 desk:w-1/6 text-lg desk:pl-12">{flightItem.flightNumber} <div className="desk:hidden">{flightItem.airlineDescription}</div></div>
            <div className="hidden desk:block w-1/6 text-lg text-center">{flightItem.airlineDescription}</div>
            <div className="w-1/3 desk:w-2/6 text-lg desk:text-center desk:whitespace-nowrap">{t("flights.from")} {new Date(flightItem.dateFrom).toLocaleDateString().replaceAll("/", ".")} {t("flights.to")} {new Date(flightItem.dateTo).toLocaleDateString().replaceAll("/", ".")}</div>
            <div className="w-1/3 desk:w-1/6 text-lg text-center">{`${flightItem.stx}${isMobile ? "" : " | "}${freqString}`}</div>
            {actionColumn ?

                <div className="desk:relative w-full mt-6 desk:mt-0 desk:w-1/6 text-base cursor-pointer font-bold uppercase flex gap-2 items-center">
                    <a target={targetBlank ? '_blank' : '_self'} className="w-full flex justify-end" href={actionLink}>
                        <div className="flex items-center gap-2">
                            <div>{actionLabel}</div>
                            <div>
                                <CustomIcon iconName="chevron-r" />
                            </div>
                        </div>
                    </a>
                </div>
                : <div className="desk:relative w-full mt-6 desk:mt-0 desk:w-1/6 text-base cursor-pointer font-bold uppercase flex gap-2 items-center hidden">a</div>
            }
        </div>
    )
}

export default DestinationFlightRow;