import { useEffect, useState } from "react";
import { APPLE_DEVICES } from "../config/appleDevicesConfig";

const useIsApple = () => {

    const [isApple, setIsApple] = useState(false);

    const detectDevice = () => {

        setIsApple(APPLE_DEVICES.includes(navigator.platform));
    }

    useEffect(() => {

        detectDevice();
    }, [navigator]);

    return isApple;
}

export default useIsApple