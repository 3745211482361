import { useRecoilValue } from "recoil";
import { LanguageAtom } from "@/atoms";

interface IProductProps {
    lblSubtitleShopping: string,
    lblCrossedOutPrice: string, 
    lblLabelCtaShopping: string, 
    lblPriceShopping: string, 
    lblTitleShopping: string,
    upiImageShoppingDesktop: string
}

interface IImage {
    contenttype: string,
    digest: string,
    filename: string
    mediaserverpath: string,
    resourcename: string,
    rheight: string,
    rwidth: string,
    size: string,
    lblSubtitleShopping: { [key: string]: string },
    lblCrossedOutPrice: { [key: string]: string }, 
    lblLabelCtaShopping: { [key: string]: string }, 
    lblPriceShopping: { [key: string]: string }, 
    lblTitleShopping: { [key: string]: string }, 
}

const Product: React.FC<IProductProps> = ({lblSubtitleShopping, lblCrossedOutPrice, lblLabelCtaShopping, lblPriceShopping, lblTitleShopping, upiImageShoppingDesktop}) => {
  const lang = useRecoilValue(LanguageAtom);

  return (
    <div className="rounded-3xl bg-[#F2F2F2] relative h-fit desk:w-72 mob:w-60 mb-4">
        <div  style={{ backgroundImage: `url(${upiImageShoppingDesktop})` }} className="bg-no-repeat bg-center bg-cover h-60 bg-cyan-500 rounded-t-3xl"></div>

        <div className="h-40 p-3 pb-7 desk:pb-12 mb-2">
                <div>
                    <h2 className={`font-bold text-lg desk:text-xl`}>{lblTitleShopping}</h2>
                </div>
                <div className="flex gap-2">
                    <a className={`text-sm`}>{lblSubtitleShopping}</a>
                </div>
                <div className="flex flex-col gap-2 mt-2">
                    {   lblCrossedOutPrice
                        ?   <h1 className="line-through">€{lblCrossedOutPrice}</h1>
                        :   <></>
                    }
                    <h1 className="font-bold">€{lblPriceShopping}</h1>
                </div>
            {lblLabelCtaShopping ? <a href="#" className={`absolute bottom-0 right-0 bg-[#6ABACE] text-[#FFFFFF] py-3 px-6 rounded-tl-3xl rounded-br-3xl text-sm font-bold`}>{lblLabelCtaShopping}</a> : null}
        </div>
    </div>
  );
}

export default Product;