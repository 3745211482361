import type { AppProps } from "next/app";

import { RecoilRoot } from "recoil";
import { IFooterApiResponse } from "@/interfaces/IFooterDTO";
import { Alert, ErrorBoundary, Footer } from "@/components";
import { AuthProvider } from "@/context/AuthContext";
import { Toaster } from 'react-hot-toast';

import NavigationMenu from "@/components/NavigationMenu/NavigationMenu";
import "../i18n";
import "@/styles/globals.css";
import Script from "next/script";

interface ICustomAppProps extends AppProps {
  pageProps: {
    lang: string;
    data: any;
    footerData: IFooterApiResponse;
    navigationData: any;
  };
}

export default function App({ Component, pageProps }: ICustomAppProps) {
  const { lang, data, footerData, navigationData } = pageProps;

  return (
    
    <>
      {process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE === 'mxp' ?
        <>
          <noscript
            dangerouslySetInnerHTML={{ __html: `<iframe src=https://www.googletagmanager.com/ns.html?id=GTM-PHDZC8M
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`}}
          ></noscript>
          <Script strategy="afterInteractive">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-PHDZC8M');`}
          </Script>
          
        </> : 
        <>
          <noscript 
            dangerouslySetInnerHTML={{ __html: `<iframe src=https://www.googletagmanager.com/ns.html?id=GTM-5C2QWLM
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`}}
          ></noscript>
          <Script 
            dangerouslySetInnerHTML={{ __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5C2QWLM');`}}
          ></Script>
          
        </>
      }
      <RecoilRoot>
        <AuthProvider>

        <div className='font-primary font-normal'>

          <Toaster position="bottom-right" />

          <ErrorBoundary customMessage="Alert is broken">
            <Alert language={lang} targetUrl={process.env.NEXT_PUBLIC_BE_BASE_PATH + "/rest/v1/SEA-LinMxp/contents/alert"} />
          </ErrorBoundary>

          { navigationData ?
            <ErrorBoundary customMessage="Navigation menu is broken">
              <NavigationMenu language={lang} navigationData={navigationData} hostPageData={data ? data.data[0] : null}/>
            </ErrorBoundary>
          : null }

            <Component {...pageProps} />

          { footerData ?
            <ErrorBoundary customMessage="Footer is broken">
              <Footer dataFooter={footerData.data} lang={lang} />
            </ErrorBoundary>
          : null }

        </div>

        </AuthProvider>
      </RecoilRoot>
    </>
  );
}
