import { useCallback } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "@/hooks";

import Button from "../Button/Button";
import DOMPurify from "isomorphic-dompurify";

interface ITrainPanelProps {
    data: any
}

const TrainPanel: React.FC<ITrainPanelProps> = ({ data }) => {

    const { i18n } = useTranslation();
	const lang = i18n.language;
    const [ isMobile ] = useIsMobile();

    const searchButton = useCallback(() => {

        return (

            <a href={process.env.NEXT_PUBLIC_AIRPORT_SHOP + `/${lang}${t('trains.linkTrain')}`} target="_blank">
                <Button className="active:bg-lightblue" text={`${data?.lblButton[lang]}`} />
            </a>
        )
    }
        , [lang]
    )

    const txbSubtitle: string = DOMPurify.sanitize(data?.txbSubtitle[lang]);

    return <>
        <div className="text-black flex w-full px-3 py-5 desk:pl-12 desk:pr-8 desk:py-5 flex-col items-start gap-4 desk:gap-0 desk:flex-row desk:items-center justify-between">
            <h2 dangerouslySetInnerHTML={{ __html: txbSubtitle }} />
            <div className={`${isMobile ? "flex justify-center w-[100%]" : "" }`}>
                {searchButton()}
            </div>
        </div>
    </>
}

export default TrainPanel