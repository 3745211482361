import { ITaxonomyDTO } from "@/interfaces/IPageDTO"
import { LanguageAtom } from "../../atoms"
import { useRecoilValue } from "recoil"
import { useMemo, useState } from "react"
import { useCategory, useDataCard } from "../../hooks"
import { IFaqApiResponse, IFaqThemeDTO, xconFaq } from "../../interfaces/IAccordionListDTO"
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { FAQ_PAGE_URLS } from "../../config/faqPages"

const Chip = dynamic(() => import("../ChipList/Chip"), { ssr: false });
import BoxFaq from "./BoxFaq"
import Button from "../Button/Button"
import TitleSubtitle from "./TitleSubtitle"
import dynamic from "next/dynamic"

interface IFaqListProps {
    lang: string,
    faqData: {
        grpFaqType: string,
        lstFaqs: xconFaq[],
        xtaxFaqCategories: ITaxonomyDTO
        lstFaqsThemes: IFaqThemeDTO[],
        lblTitle: { [key: string]: string },
        lblSubtitle: { [key: string]: string },
    }
}


const FaqList: React.FC<IFaqListProps> = ({ lang, faqData }: IFaqListProps) => {
    const router = useRouter();
    const { t } = useTranslation();

    const switchCase = () => {
        switch (faqData?.grpFaqType) {
            case "grpAutomatic":
                return (<div>
                    <AutomaticFaqs lang={lang} faqData={faqData} />
                    {/* <div className="flex w-full justify-center mt-8">
                        <Button
                            text={t('faqs.seeAllTheFaqs')}
                            onClick={() => router.push(FAQ_PAGE_URLS[lang])} />
                    </div> */}
                </div>)
            case "grpManual":
            case "":
                if (faqData?.lstFaqsThemes.length) return <ThemeFaqs lang={lang} faqData={faqData} />;
                else return (<div>
                    <ManualFaqs lang={lang} faqData={faqData} />
                    {/* <div className="flex w-full justify-center mt-8">
                        <Button
                            text={t('faqs.seeAllTheFaqs')}
                            onClick={() => router.push(FAQ_PAGE_URLS[lang])} />
                    </div> */}
                </div>)
        }
    }
    return (
        <div className={`${faqData?.grpFaqType === "grpManual" ? "flex flex-col" : ""}`}>
            <div> <TitleSubtitle
                title={faqData?.lblTitle && faqData?.lblTitle[lang] ? faqData?.lblTitle[lang] : ""}
                subTitle={faqData?.lblSubtitle && faqData?.lblSubtitle[lang] ? faqData?.lblSubtitle[lang] : ""} /> </div>
            {switchCase()}
        </div>
    )
}

const AutomaticFaqs: React.FC<IFaqListProps> = ({ faqData, lang }) => {
    
    const rowData = useCategory<any>('faqs', faqData?.xtaxFaqCategories?.ti[0]?.label);

    const data = useMemo(() => {
        return rowData[0]?.data?.map((el: any) => { return { ...el, id: el.metainfo.id, title: el?.lblTitle, subTitle: el?.txbSubtitle } })
    }, [rowData])

    return (
        <>
            {faqData.lstFaqsThemes ?
                <div>
                    {faqData?.lstFaqsThemes?.map((chip) => (
                        <Chip
                            key={chip?.id}
                            text={chip?.lblFaqThemeTag[lang]}
                            bgColor="lightblue"
                            txtColor="white"
                            hrefPath=""
                            onClick={() => {}} />))
                    }
                </div >
                : null}
            <BoxFaq
                data={data || []}
                lang={lang} />
        </>
    )
}

const ManualFaqs: React.FC<IFaqListProps> = ({ faqData, lang }) => {
    const cardList = useMemo(() => {
        return faqData?.lstFaqs?.map((faq: any) => {
            return faq?.xconFaq?.targeturl
        })
    }, [faqData])

    const rowData = useDataCard<IFaqApiResponse[]>({ urls: cardList })
    
    const data = useMemo(() => {
        return rowData?.map((el: any) => { return { ...el?.data, id: el?.metainfo?.id, title: el?.data?.lblTitle, subTitle: el?.data?.txbSubtitle } })
    }, [rowData])

    return (
        <div className="desk:flex-[3] flex-none">
            <BoxFaq
                data={data || []}
                lang={lang} />
        </div>
    )
}

const ThemeFaqs: React.FC<IFaqListProps> = ({ faqData, lang }) => {
    
    const [selectedId, setSelectedId] = useState(0)
    const categoryArray: [IFaqApiResponse, ((arg: string) => void)] = useCategory<[IFaqApiResponse, ((arg: string) => void)]>('faqs', faqData?.lstFaqsThemes[selectedId]?.xtaxFaqCategoriesTheme?.ti[0]?.label)
    const data = useMemo(() => {
        return categoryArray[0]?.data?.map((el: any) => { return { ...el, id: el.metainfo.id, title: el?.lblTitle, subTitle: el?.txbSubtitle } })
    }, [categoryArray[0], selectedId])

    const seleziona = (id: any, category: string) => {
        setSelectedId(id);
        categoryArray[1](category)
    }

    return (
        <>
            {faqData?.lstFaqsThemes ?
                <div className="flex gap-2 desk:mt-5 mt-2 mb-8 flex-wrap">
                    {faqData?.lstFaqsThemes?.map((chip) => (
                        <div className="">
                            <Chip
                                key={chip?.id}
                                text={chip?.lblFaqThemeTag[lang].toUpperCase()}
                                bgColor={selectedId === chip?.id ? "bg-lightblue" : "bg-lightgrey"}
                                txtColor={selectedId === chip?.id ? "text-white" : "text-lightblue"}
                                hrefPath=""
                                onClick={() => { seleziona(chip?.id, chip?.xtaxFaqCategoriesTheme?.ti[0]?.label) }} />
                        </div>))
                    }
                </div >
                : null}
            <BoxFaq
                data={data || []}
                lang={lang} />
        </>
    )
}

export default FaqList