import { configColor } from "@/utils";
import { LanguageAtom } from "@/atoms";
import { useRecoilValue } from "recoil";
import { ILaunchCardProps } from "./ICard";
import { useIsMobile } from "@/hooks";

import CustomIcon from "../CustomIcon/CustomIcon";

const LaunchCard: React.FC<ILaunchCardProps> = ({ title, subTitle, onClick, imageUrl, textSize = 'lg', isLaunch = true, item = [], isSquare = false, txtColor, isMenu = false }: ILaunchCardProps) => {

    const lang = useRecoilValue(LanguageAtom)
    const [isMobile] = useIsMobile()
    // const height = isLaunch ? (isSquare ? `${!isMobile ? 'w-[22vw] max-w-[20rem]' : ''} h-[19rem]` : "h-[10rem]") : "h-[18rem]";

    return (
        <>
            <div className={`relative select-none rounded-3xl overflow-hidden flex flex-col justify-end cursor-pointer ${isSquare && !isMenu ? 'w-[300px] desk:w-[415px] h-full' : 'w-full h-full'}`} onClick={onClick}>
                {/* <div style={{ backgroundImage: `url(${imageUrl})` }} className={`bg-blue-700 bg-no-repeat bg-center bg-cover hover:scale-110 transition-all duration-500`}></div> */}

                <img src={imageUrl} alt="immagine" className={`hover:scale-110 transition-all duration-500 h-full w-full object-cover`} />
                {isLaunch ?
                    (<div className="absolute py-5 px-6 desk:w-full">
                        <div className={`${!!title ? 'desk:flex desk:justify-between desk:w-full' : 'hidden'} flex gap-5 items-center`}>
                            <h2 className={`font-bold ${txtColor} ${textSize === 'lg' ? 'text-lg' : 'text-normal'}`}>
                                {title}
                            </h2>
                            {isSquare && !isMenu && !isMobile ?
                                <div className="group">
                                <div className="bg-white w-[44px] h-[44px] rounded-3xl flex items-center justify-center group-hover:bg-[#6ABACE] transition duration-300">
                                    <CustomIcon color="" iconName="arrow" className={`h-4 group-hover:fill-[#FFFFFF] fill-[#FFFFFF]"}`} />
                                </div>
                                </div>
                                : !isMobile ?
                                    <CustomIcon color="" iconName="arrow" className={`h-4 ${txtColor === "text-[#FFFFFF]" ? "fill-[#FFFFFF]" : "fill-[#000000]"}`} />
                                    : null}
                        </div>
                    </div>)
                    :
                    (<div className="absolute flex flex-col text-white gap-5 pl-12 pb-10">
                        <h1 className="font-bold text-5xl">
                            {title.toUpperCase()}
                        </h1>
                        <h4 className="text-2xl">
                            {subTitle}
                        </h4>
                    </div>)}
            </div>



            {
                !isLaunch ?
                    <div className={`${isMobile ? ' grid grid-cols-1' : ' grid grid-cols-2'}`}>
                        {item?.data?.lstTexts?.map((itemlst: any) => {

                            const enuTitleColorLaunch = configColor('text', itemlst.enuTitleColorLaunch)
                            const lblTitleLaunch = itemlst.lblTitleLaunch[lang]
                            const txbDescription = itemlst.txbDescription[lang]

                            return (
                                <div key={itemlst.id} className={`${isMobile ? 'p-4' : 'p-5'} col-span-1 flex ${enuTitleColorLaunch} pt-8`}>
                                    <div className="w-fit flex flex-col justify-start mr-2">
                                        <CustomIcon iconName={"check"} className="w-5 fill-[#6ABACE]" />
                                    </div>
                                    <div>
                                        <h2 className="font-bold">{lblTitleLaunch}</h2>
                                        <h3>{txbDescription}</h3>
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </div>
                    : null
            }
        </>
    )
}

export default LaunchCard;
