import { IModuleThreeApiResponse, IModuleThreeDTO } from "@/interfaces/IModuleThreeDTO";
import { useIsMobile, useModule } from "@/hooks";

import ContactUs from "../ModuleThreeWrapper/ContactUs";
import FormWrapper from "./FormWrapper";
import { useEffect, useState } from "react";
import { IForm } from "@/interfaces/IForm";
import { useTranslation } from "react-i18next";

interface IFormSelectorProps {
    type: IModuleThreeDTO,
    formId: number,
    language: string
}

const FormSelector: React.FC<IFormSelectorProps> = ({ type, formId, language }: IFormSelectorProps) => {

    const moduleData = useModule<IModuleThreeApiResponse>(type.xconWidgetForm.targeturl);
    const [isMobile] = useIsMobile();
    const name = moduleData?.data?.enuTypeForm;
    const [form, setForm] = useState<IForm>();
    const { t } = useTranslation();

    async function getForm(){
        return await fetch(`${process.env.NEXT_PUBLIC_BE_BASE_PATH}/rest/v1/SEA-LinMxp/contents/widgetForm/${formId}`)
    }

    useEffect(()=>{
        getForm()
        .then(response => response?.json())
        .then((response) => {
            setForm(response?.data);
        })
        .catch(error => {
            console.error('[FormSelector] form error -> ', error);
        })
    },[])

    switch (name) {
        case 'CONTATTACI': return <ContactUs language={language}/>
        case 'RECLAMI': case 'OGGETTI_SMARRITI': case 'SUGGERIMENTI' : case 'INFORMAZIONI':  case 'PRM_AUTISMO': case 'PRM_ACCESS': case 'PRM_ACCESS_01': case 'PRM_SURVEY': return <FormWrapper language={language} form={form!} bannerHidden={true} hidden={false} isMobile={isMobile} sendText={t('form.sendEmailNow')}/>
        default: return <></>
    }
}

export default FormSelector;
