import { IDestinationCardProps } from "./ICard";

import CustomIcon from "../CustomIcon/CustomIcon";

const DestinationCard: React.FC<IDestinationCardProps> = ({ title, onClick, imageUrl, hint, onClickHint }: IDestinationCardProps) => {

    return (
        <div className="destinationCard flex flex-col cursor-pointer rounded-2xl w-full m-auto hover:shadow-lg" onClick={(e) => onClick(e)}>
            <div className="w-full">
                <img style={{ height: '150px' }} className="rounded-t-2xl w-full" src={imageUrl} />
            </div>
            <div className="infoSection bg-lightgrey rounded-b-2xl p-3" >
                <div className="flex gap-2 items-center cursor-pointer" onClick={(e) => onClickHint(e)}>
                    <CustomIcon iconName="location-destination" />
                    <div className="text-sm">{hint || ''}</div>
                </div>
                <h2 className="text-xl font-semibold">{title}</h2>
            </div>
        </div>
    )
}

export default DestinationCard;
