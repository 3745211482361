import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { IShopDTO, IShopList, ITerminals } from "@/interfaces/IShopDTO";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

import TerminalFilterAtom from "@/atoms/TerminalFilterAtom";
import AreaFilterAtom from "@/atoms/AreaFilterAtom";
import CategoryFilterAtom from "@/atoms/CategoruFilterAtom";
import ShoppingListCard from "../Card/ShoppingListCard";
import router from "next/router";
import { MoonLoader } from "react-spinners";


interface IShopListProps {
    category: string,
    area: string,
    terminalNum: string
}

const ShopList: React.FC<IShopListProps> = ({category, area, terminalNum}) => {

    const { i18n, t } = useTranslation();
    const lang = i18n.language;
    const [shopList, setShopList] = useState<IShopDTO>();
    const terminal = process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE;
    const areaState = useRecoilState(AreaFilterAtom);
    const categoryState = useRecoilState(CategoryFilterAtom);
    const terminalState = useRecoilState(TerminalFilterAtom);
    const [areaType, setAreaType] = useState("");
    const [terminalType, setTerminalType] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    let foundShops = false;

    async function getShops() {

        if (process.env.NEXT_PUBLIC_SHOP_KEY) {

            return await fetch (
                `${process.env.NEXT_PUBLIC_SHOP_PATH}/afm/${lang.toLocaleUpperCase()}/${terminal}/shop/list`, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'keyId': process.env.NEXT_PUBLIC_SHOP_KEY
                })
            });
        }        
    }

    useEffect(() => {

        getShops()
        .then(response => response!.json())
        .then((response) => {

            setShopList(response);
        })
        .catch(error => {

            setIsLoading(false);
            console.error('[ShopList] shop error -> ', error);
        })
        .finally(() => {
            setIsLoading(false);
        })
    }, [])

    useEffect(() => {

        foundShops = false;
        switch(areaState[0]){
            case "all" : return setAreaType("");
            case t('shop.beforeFilter') : return setAreaType("01");
            case t('shop.afterFilter') : return setAreaType("02");
            default : return setAreaType("");
        }
    }, [areaState[0]]);

    useEffect(() => {

        foundShops = false;
        switch(terminalState[0]){
            case "all" : return setTerminalType("") ;
            case "t1" : return setTerminalType("T1");
            case "t2" : return setTerminalType("T2");
            default : return setTerminalType("");
        }
    }, [terminalState[0]]);

    return (
        <div className="grid desk:grid-cols-4 mob:grid-cols-2 tab:grid-cols-3 desk:gap-6 mob:gap-2">
        {
            !isLoading && shopList?.terminals.map((term : ITerminals)=>{
                if(term.terminal === terminalType || terminalType === ''){
                    return term.items.map((shop : IShopList)=>{
                        if(shop.accessType === areaType || areaType === '')
                           if(shop.subcategoryDescr === categoryState[0] || categoryState[0] === 'all' || categoryState[0] === ''){
                            foundShops = true;
                            return <ShoppingListCard
                            key={shop.id}
                            id={shop.id}
                            title={shop.name!}
                            titleColor={"black"}
                            onClick={() => router.push(shop.boutiqueUrl)}
                            onClickPath={`/${lang}/${t("shop.inAirport")}/${t("shop.shops")}/${t("shop.detail").toLocaleLowerCase()}?id=` + shop.id}
                            subtitle={t("shop.detail")}
                            subtitleColor={"black"}
                            imageUrl={shop.logo.replace('/Assets/afm', '')}
                            detailCtaText={'detailCtaText'}
                            ctaText={shop.boutiqueUrl ? t("shop.book") : ""}
                            backgroundCta={"black"}
                            onClickDetailCta={() => { }}
                            icon={'icon'}
                            hint={t("shop.map")}
                            hintIcon={'hintIcon'}
                            language={lang}
                            onClickHint={() => { }} />
                           }
                    })}
            })
        }
        {
            isLoading && 
            <div className="relative desk:left-[155%] tab:left-[100%]">
                <MoonLoader className="m-auto" color="#6ABACE" />
            </div>

        }
        {
            !isLoading && !foundShops &&
                 <div className="relative text-center desk:left-[155%] tab:left-[100%]">
                    <h1 className="font-bold text-2xl">{t('shop.noShopsFound')}</h1>
                </div>
        }
        </div>
	);
}

export default ShopList
