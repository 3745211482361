import { IShopDetail } from "@/interfaces/IShopDTO";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { InfoAccordionCard, ProductCard } from "../Card";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { FreeMode, Mousewheel } from 'swiper';
import { useIsMobile } from "@/hooks";
import { Swiper, SwiperSlide } from 'swiper/react';

import StaticInfoCard from "../Card/StaticInfoCard";
import CustomIcon from "../CustomIcon/CustomIcon";
import map2 from '../../assets/img/map2.png';
import clock from '../../assets/img/clock.png';
import phone from '../../assets/img/phone.png';
import React from "react";
import { MoonLoader } from "react-spinners";

interface IShopLayoutPageProps {
    language: string
}

interface IInfoCardProps {
    enuSubtitleColor: string;
    id: string;
    lblSubtitleInfo: {
        [key: string]: string;
    };
    flgTargetCtaInfo: boolean;
    blLinkCtaInfo: {
        [key: string]: string;
    };
}[]

interface ICardType {
    name: string,
    id: number,
    description: string,
    price: number,
    is_visible: boolean,
    inventory_level: number,
    custom_url: { url : string }
}

interface IProductListType {
    widget_configuration : { product : IProductType }
}

interface IProductType {
    value : [{ 
        productId: string,
        productName: string,
        thumbnailUrl: string 
    }]
}

interface IProductValueType {
    productId: string,
    productName: string,
    thumbnailUrl: string
}

const ShopLayoutPage: React.FC<IShopLayoutPageProps> = ({ language }) => {

    const { i18n } = useTranslation();
    const lang = language ? language : i18n.language;
    const infoCard : IInfoCardProps[] =[ { enuSubtitleColor: "black", id: "0", lblSubtitleInfo: {en: "", it: "info"}, flgTargetCtaInfo: true, blLinkCtaInfo:{en: "", it: ""}}]
    const router = useRouter();
    const pageId = router.asPath.substring( router.asPath.indexOf("=") + 1, router.asPath.length);
    const [shopData, setShopData] = useState<IShopDetail>();
    const [widgetList, setWidgetList] = useState<any>();
    const [widgetDetail, setWidgetDetail] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isWidgetLoading, setIsWidgetLoading] = useState(true);
    const [openingDays, setOpeningDays] = useState("");
    const [logo, setLogo] = useState("");
    const [banner, setBanner] = useState("");
    const [isMobile, isTablet] = useIsMobile();
    const [isWidgetVisible, setIsWidgetVisible] = useState(false);
    const [hash, setHash] = useState<string>();
    // const [filteredList, setFilteredList] = useState(new Array());

    let widthCarousel = isMobile ? "!w-auto" : "";
    const notVisibleInEng = ["ETRO", "GIORGIO ARMANI", "MONTBLANC"];
    
    async function getShopData() {

        if (process.env.NEXT_PUBLIC_SHOP_KEY) {

            return await fetch (
                `${process.env.NEXT_PUBLIC_SHOP_PATH}/afm/${lang.toLocaleUpperCase()}/${pageId}`, {
                    method: 'GET',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'keyId': process.env.NEXT_PUBLIC_SHOP_KEY
                    })
                
            });
        }
    }

    const getLogo = async () => {
        const res = await fetch(`${process.env.NEXT_PUBLIC_IMAGE_PATH}/afm/s/200/127${shopData?.shopDetails?.logo!.replace('/Assets/afm', '')}` , { headers: {'keyId': process.env.NEXT_PUBLIC_IMAGE_KEY as string } });
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setLogo(imageObjectURL);
    };

    const getBanner = async () => {

        let img_url = isMobile ? shopData?.shopDetails?.images.find(item => item.media == 'Mobile')?.image : shopData?.shopDetails?.images.find(item => item.media == 'Desktop')?.image;
        let img_url_trimmed = img_url?.replace('/Assets/afm', '');

        const res = await fetch(`${process.env.NEXT_PUBLIC_IMAGE_PATH}/afm/s/800/450${img_url_trimmed}` , { headers: {'keyId': process.env.NEXT_PUBLIC_IMAGE_KEY as string } });
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setBanner(imageObjectURL);
    };

    async function getHasher() {
        return await fetch (
            `${process.env.NEXT_PUBLIC_BIG_COMMERCE_HASHER}${process.env.NEXT_PUBLIC_BIG_COMMERCE_HASHER_UUID}`);
    }

    async function getWidgetList(hash: string, widgetId: string) {
                
        return await fetch(

            `${process.env.NEXT_PUBLIC_BIG_COMMERCE_BASE_PATH}/widget/${widgetId}`, {
                method: 'GET',
                headers: ({
                    'x-auth-hash': hash,
                    'x-auth-instance-id': process.env.NEXT_PUBLIC_BIG_COMMERCE_HASHER_UUID!
                })
            }
        )               
    }

    async function getWidgetDetail(hash: string, id: string, ) {

        return await fetch(
            `${process.env.NEXT_PUBLIC_BIG_COMMERCE_BASE_PATH}/product/${id}`, {
                method: 'GET',
                headers: ({
                    'x-auth-hash': hash,
                    'x-auth-instance-id': process.env.NEXT_PUBLIC_BIG_COMMERCE_HASHER_UUID!
                })
            }
        )
    }

    useEffect(() => {

        getShopData()
            .then(response => response?.json())
            .then((response) => {
                
                notVisibleInEng.includes(response.shopDetails.shortdescr) && lang == 'en' ? 
                    setIsWidgetVisible(false) : setIsWidgetVisible(true);

                setShopData(response);
            })
            .catch(error => {

                console.error('[ShopLayoutPage] shopData error -> ', error);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, []);

    useEffect(() => {

        if (shopData) {

            getHasher()
                .then(response => response?.json())
                .then((response) => {
                    
                    setHash(response.hash);

                    getWidgetList(response.hash!, shopData.shopDetails.widgetId)
                        .then(response => response.json())
                        .then((response) => {
                            
                            setWidgetList(response.widget_configuration?.product?.value);
                        })
                        .catch(error => {

                            console.error('[ShopLayoutPage] getWidgetList -> ', error);
                        })
                })
                .catch(error => {

                    console.error('[ShopLayoutPage] getHasher error -> ', error);
                })
        }

    }, [shopData])

    useEffect(() => {

        getLogo()
        .catch(error => {

            console.error('[ShopLayoutPage] logo error -> ', error);
        });

        getBanner()
        .catch(error => {

            console.error('[ShopLayoutPage] banner error -> ', error);
        });

        if (shopData != undefined) {

            let data: any = [];

            data = shopData?.shopDetails?.openingDays.map((item) => {
                return (item.days + " " + item.open + " - " + item.close + "\r\n").replaceAll(',', ', ');
            })

            setOpeningDays(data);
        }
    }, [shopData]);

    useEffect(() => {

        if (widgetList?.length > 0 ) {

            let promisesList: any = [];

            widgetList?.map((widget: any) => {

                const url = getWidgetDetail(hash!, widget.productId);
                promisesList = [...promisesList, url];
            })

            Promise.all(promisesList)
                .then(response =>  

                    Promise.all(response.map(r => r.json())))
                        .then((response) => {
                            
                            setWidgetDetail(response);
                        })
                        .finally(() => {

                            setIsWidgetLoading(false);
                        })
                        .catch(error => {

                            setIsWidgetLoading(false);
                            console.error('[ShopLayoutPage] getWidgetDetail error -> ', error);
                        })

            

        }

    }, [widgetList])

    return (
        <>
        {
            !isLoading 
            ?   <>
                    <div className="relative w-full">
                        <img src={banner} className="w-full max-h-[305px] object-fill"></img>
                        {/* <h1 className="absolute font-bold text-5xl text-white top-10 desk:left-32 left-10">{shopData?.shopDetails?.shortdescr!}</h1>  */}
                    </div> 
                    <div className="flex items-start my-12 gap-8 items-center justify-center">
                        <div className="flex flex-col desk:flex-row desk:max-w-cont-2 gap-6">
                            <img src={logo}/>
                            <h1 className="px-6 desk:px-0">{shopData?.shopDetails?.longdescr!}</h1>
                        </div>
                    </div>
                    {(isWidgetVisible && !isWidgetLoading) && 
                        <div className={"py-8 px-6 desk:py-12"}>
                            <div className={`max-w-cont-2 mx-auto w-full`}>
                                <div className={`mb-2`}>
                                    <h1 className="font-bold text-3xl mt-8 mb-8">{t('shop.chooseForYou')}</h1>
                                    <div className={`${!isMobile ? 'grid desk:grid-cols-4 mob:grid-cols-2 tab:grid-cols-3 desk:gap-x-24 desk:gap-y-4 mob:gap-2' : ''}`}>
                                        {
                                            isMobile || isTablet ?
                                            <React.Fragment>
                                                {widgetList?.length > 0 && widgetDetail?.length > 0 ?   
                                                        
                                                        <Swiper
                                                            direction={isMobile ? "horizontal" : "vertical"}
                                                            freeMode={true}
                                                            modules={[isMobile ? FreeMode : FreeMode, Mousewheel]}
                                                            slidesPerView={"auto"}
                                                            spaceBetween={20}
                                                            mousewheel={isMobile ? false : true}
                                                            slidesOffsetBefore={isMobile ? 26 : 29}
                                                            slidesOffsetAfter={isMobile ? 26 : 49}
                                                        >
                                                            {widgetList?.map((widgetMain: IProductValueType, index: number) => {
                                                                return (
                                                                    (widgetDetail[index].is_visible || widgetDetail[index].inventory_level != 0) &&
                                                                    <SwiperSlide key={index} className={`rounded-2xl ${widthCarousel} shadow-lg my-4`}>
                                                                        <ProductCard
                                                                            title={widgetMain.productName}
                                                                            titleColor="black"
                                                                            onClick={() => window.open(lang == 'it' ? 'https://milanomalpensaboutique.com' + widgetDetail[index].custom_url.url : 'https://milanomalpensaboutique.com/en' + widgetDetail[index].custom_url.url)}
                                                                            imgUrl={widgetMain.thumbnailUrl}
                                                                            subTitle={widgetDetail[index].brand_name}
                                                                            subTitleColor="black"
                                                                            price={widgetDetail[index].tax_class_id === 0 ? '€' + (widgetDetail[index].price / 1.22).toFixed(2) : widgetDetail[index].sale_price === 0 ? '€' + widgetDetail[index].price : (widgetDetail[index].sale_price/ 1.22).toFixed(2)}
                                                                            oldPrice={widgetDetail[index].tax_class_id === 0 ? '€' + widgetDetail[index].price.toString() : widgetDetail[index].sale_price === 0 ? '' : '€' +(widgetDetail[index].price / 1.22).toFixed(2)}
                                                                            labelCta={t('shop.book')}
                                                                            bgCta="FFFFFF"
                                                                        />
                                                                    </SwiperSlide>
                                                                )                                                                                
                                                            })}
                                                        </Swiper>
                                                                                               
                                                    : <h1>{t('shop.noProductsForShop')}</h1>
                                                }
                                            </React.Fragment> 
                                            :
                                            <React.Fragment>
                                            {widgetList?.length > 0 && widgetDetail?.length > 0 ?

                                               widgetList?.map((widgetMain: IProductValueType, index: number) => {
                                                    return (
                                                        <ProductCard 
                                                            title={widgetMain.productName}
                                                            titleColor="black"
                                                            onClick={() => window.open(lang == 'it' ? 'https://milanomalpensaboutique.com' + widgetDetail[index].custom_url.url : 'https://milanomalpensaboutique.com/en' + widgetDetail[index].custom_url.url)}
                                                            imgUrl={widgetMain.thumbnailUrl}
                                                            subTitle={widgetDetail[index].brand_name}
                                                            subTitleColor="black"
                                                            price={widgetDetail[index].tax_class_id === 0 ? '€' + (widgetDetail[index].price / 1.22).toFixed(2) : widgetDetail[index].sale_price === 0 ? '€' + widgetDetail[index].price : (widgetDetail[index].sale_price/ 1.22).toFixed(2)}
                                                            oldPrice={widgetDetail[index].tax_class_id === 0 ? '€' + widgetDetail[index].price.toString() : widgetDetail[index].sale_price === 0 ? '' : '€' +(widgetDetail[index].price / 1.22).toFixed(2)}
                                                            labelCta={t('shop.book')}
                                                            bgCta="FFFFFF"
                                                        />
                                                    )
                                                })
                                            : <h1>{t('shop.noProductsForShop')}</h1>
                                            }
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className={"py-8 px-6 desk:py-12 bg-lightgrey"}>
                        <div className={`max-w-cont-2 mx-auto w-full`}>
                            <div className={`mb-11`}>
                                <h1 className="font-bold text-3xl mt-8 mb-8">{t('shop.infoShop')}</h1>
                                { !isMobile ?
                                    <div className="grid desk:grid-cols-5 mob:grid-cols-2 tab:grid-cols-3 desk:gap-6 gap-4 justify-items-center items-center">
                                        <StaticInfoCard language={lang} bgColor={'bg-[white]'} imageUrl={clock.src} key={0} title={t('shop.openingShop')} description={openingDays} />
                                        <div className={`flex items-center ${isMobile ? "justify-center rotate-90 my-4" : ""}`}>
                                            <CustomIcon iconName="dots" className="w-4" />
                                        </div>
                                        <StaticInfoCard language={lang} bgColor={'bg-white'} imageUrl={map2.src} key={1} title={t('shop.whereShop')} description={shopData?.shopDetails?.access?.descr ? shopData?.shopDetails?.floordescr! + "\r\n" + shopData?.shopDetails?.access?.descr : shopData?.shopDetails?.floordescr!} />
                                        <div className={`flex items-center ${isMobile ? "justify-center rotate-90 my-4" : ""}`}>
                                            <CustomIcon iconName="dots" className="w-4" />
                                        </div>
                                        <StaticInfoCard language={lang} bgColor={'bg-white'} imageUrl={phone.src} key={2} title={t('shop.helpShop')} description={t('shop.contacts') + (shopData?.shopDetails?.contact! ? shopData?.shopDetails?.contact! : "")} />
                                    </div>
                                    :
                                    <div>
                                        <InfoAccordionCard language={lang} bgColor={'bg-white'} imageUrl={clock.src} key={0} title={t('shop.openingShop')} description={openingDays}/>
                                        <div className={`flex items-center ${isMobile ? "justify-center rotate-90 my-8" : ""}`}>
                                            <CustomIcon iconName="dots" className="w-4" />
                                        </div>
                                        <InfoAccordionCard language={lang} bgColor={'bg-white'} imageUrl={map2.src} key={1} title={t('shop.whereShop')} description={shopData?.shopDetails?.access?.descr ? shopData?.shopDetails?.floordescr! + "\r\n" + shopData?.shopDetails?.access?.descr : shopData?.shopDetails?.floordescr!} />
                                        <div className={`flex items-center ${isMobile ? "justify-center rotate-90 my-8" : ""}`}>
                                            <CustomIcon iconName="dots" className="w-4" />
                                        </div>
                                        <InfoAccordionCard language={lang} bgColor={'bg-white'} imageUrl={phone.src} key={2} title={t('shop.helpShop')} description={t('shop.contacts') + (shopData?.shopDetails?.contact! ? shopData?.shopDetails?.contact! : "")} />
                                    </div> 
                                }
                            </div>
                        </div>
                    </div>
                </>
            :   <MoonLoader className="m-auto mt-16" color="#6ABACE" />
        }
        </>
        
    )
}

export default ShopLayoutPage;
