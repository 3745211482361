
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { t } from "i18next";
import { IsSearchingAtom } from "@/atoms";
import LaunchList from "../LaunchList/LaunchList";
import SeeOtherBtn from "../SeeOtherBtn/SeeOtherBtn";
import AirlineItem from "../AirlineItem/AirlineItem";
import filteredAirlineListSelector from "@/selectors/FilteredAirlinesListSelector";
import { useTranslation } from "react-i18next";
import { MoonLoader } from "react-spinners";
import React from "react";

interface IAirlineListProps {
    children?: ReactElement<any, any>,
    dividerTargetUrl?: string,
    language: string
}

const AirlineList: React.FC<IAirlineListProps> = ({ children, dividerTargetUrl = '', language }: IAirlineListProps) => {

    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;
    const filteredList = useRecoilValue(filteredAirlineListSelector);
    const [isSearching, setIsSearching] = useRecoilState(IsSearchingAtom);
    const [currentIndex, setCurrentIndex] = useState(0);

    const cardList = useMemo(() => {
        return filteredList.map((airlineItem, index) => {
            return index === 6 && currentIndex === 0 ? 
                <React.Fragment>
                    <LaunchList data={null} language={lang} key={index} dividerMode targetUrl={dividerTargetUrl} />
                    <AirlineItem key={airlineItem.airlineId} airlineItem={airlineItem} />
                </React.Fragment> : <AirlineItem key={airlineItem.airlineId} airlineItem={airlineItem} />
        })
    }, [filteredList])

    const currentFlights = useMemo(() => {
        return cardList?.slice(0, currentIndex + 12)
    }, [currentIndex, cardList, lang])

    useEffect(() => {
        filteredList.length > 0 ? setIsSearching(false) : setIsSearching(true)
    }, [filteredList])

    return (
        <div className="w-full max-w-cont-2 mx-auto py-12 px-6">
            {isSearching ? <MoonLoader className="m-auto" color="#6ABACE" />
                : <div>
                    <div className="flex flex-col gap-8">
                        <div className="flex gap-2 text-center">
                            <div className="w-1/3 desk:w-1/5 text-lg font-bold text-lightblue">
                                {t('airlines.airlines')}
                            </div>
                            <div className="hidden desk:block desk:w-1/5 text-lg font-bold text-lightblue"></div>
                            <div className="w-1/3 desk:w-1/5 text-lg font-bold text-lightblue">{t('airlines.code')}</div>
                            <div className="w-1/3 desk:w-1/5 text-lg font-bold text-lightblue">Terminal</div>
                            <div className="hidden  desk:w-1/5 text-lg font-bold text-lightblue desk:flex items-center gap-2"></div>
                        </div>
                        {currentFlights}
                    </div>
                    {!(currentIndex + 12 >= filteredList.length) ?
                        <SeeOtherBtn onClick={() => setCurrentIndex(currentIndex + 12)} className="m-auto mt-8" text={t('flights.showMore')} iconType="next" />
                        : null}
                </div>}
            {children}
        </div>
    )
}

export default AirlineList;