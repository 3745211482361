import { expirationTime } from "@/auth/auth-config";
import Cookies from "js-cookie";

export interface LoginRequest {
  username: string;
  password: string;
}



export interface LoginResponse {
  user: {
    userId: string;
    email: string;
    codTessera: string;
  };
  codTessera : string;
  token: string;
  tokenEshop: string;
  start: number;
  signToken: string;
}

export interface IToken {
  // Token
  token: string;
  /**
   * Token for E-commerce
   */
  tokenEshop: string;
  /**
   * describe time when token has been created
   */
  start: number;
  username: string;
  userId : string;
  /**This token will be used for update profile registration */
  signToken: string;
  codTessera: string;
  /** Could be used for loyality API */
  loyalityNumber : string;
}

export const login = async (body: LoginRequest) => {
  const AUTH_URL = `${process.env.NEXT_PUBLIC_AUTH}/auth/login`;

  const request: RequestInit = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "keyId": process.env.NEXT_PUBLIC_AUTH_KEYID as string
    },
  };

  const result = await fetch(AUTH_URL, request);
  const user : LoginResponse = await result.json();

  var startDate = new Date();
  startDate.setHours(startDate.getHours() + expirationTime);

  if (user.token) {
    const tokenInfo: IToken = {
      token: user.token,
      signToken: user.signToken,
      tokenEshop: user.tokenEshop,
      start: startDate.getTime(),
      username: user.user.email,
      userId: user.user.userId,
      codTessera: user.user.codTessera,
      loyalityNumber: user.user.codTessera
    };

    Cookies.set("token", JSON.stringify(tokenInfo));
    return tokenInfo;
  }
  throw new Error ("Login error: " + result);
};

export const logout = async (token: string) => {

  const AUTH_URL = `${process.env.NEXT_PUBLIC_AUTH}/auth/logout`;

  const request: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "keyId": process.env.NEXT_PUBLIC_AUTH_KEYID as string
    }
  };

  await fetch(AUTH_URL, request)
    .then((response) => {
    
      Cookies.remove("token");
    })
    .finally(() => {

      Cookies.remove("token");
    })
    .catch((error) => {

      console.error('[Logout] error -> ', error);
    })
};
