import { MutableRefObject, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { IsDialogLoginOpenAtom, LanguageAtom } from "@/atoms";
import { IFlightItem } from "../FlightRow/FlightRow";
import { IFlightDetailsDTO } from "@/interfaces/IFlightDetailsDTO";
import { useAuth } from "@/context/AuthContext";
import { MoonLoader } from "react-spinners";
import { useIsMobile, useIsApple, useOutsideClick } from "../../hooks";

import iMessage from "../../assets/img/iMessage.png";
import whatsapp from "../../assets/img/whatsapp.png";
import mail from "../../assets/img/mail.png";
import Image from "next/image";
import trackingResponseCode from "@/utils/trackingResponseCode";
import FacebookMessenger from "./FacebookMessenger";
import Button from "../Button/Button";
import CustomIcon from "../CustomIcon/CustomIcon";

//TODO: gestire i tre casi delle notifiche
//TODO: gestire caso annullato, manca descrizione statusPublic

interface ITrackingButtonPanelProps {
    flightDetails: IFlightItem | IFlightDetailsDTO | null
}

const TrackingButton: React.FC<ITrackingButtonPanelProps> = ({ flightDetails }: ITrackingButtonPanelProps) => {

    const [isDialogLoginOpen, setIsLoginDialogOpen] = useRecoilState(IsDialogLoginOpenAtom);

    const [isTrackingModalOpen, setIsTrackingModalOpen] = useState(false);
    const [isNotTrackableModalOpen, setIsNotTrackableModalOpen] = useState(false);
    const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
    const [isAlreadySubscribedModalOpen, setAlreadySubscribedModalOpen] = useState(false);
    const [isMaxFlightTrackingSubscribedModalOpen, setIsMaxFlightTrackingSubscribedModalOpen] = useState(false);
    const [isUserNotLoggedModalOpen, setIsUserNotLoggedModalOpen] = useState(false);
    const [isGenericErrorModalOpen, setIsGenericErrorModalOpen] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [isTrackingDisabledModalOpen, setIsTrackingDisabled] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const auth = useAuth();
    const [isMobile] = useIsMobile();
    const isApple = useIsApple();

    const checkIfModalOpen = () => {
        if (isTrackingModalOpen || isNotTrackableModalOpen || isSuccessModalOpen || isAlreadySubscribedModalOpen || isMaxFlightTrackingSubscribedModalOpen || isUserNotLoggedModalOpen || isGenericErrorModalOpen || isTrackingDisabledModalOpen)
            return true
        else return false
    }

    const handleTrackingModalOpen = () => {
        
        setIsUserNotLoggedModalOpen(false)
        setSuccessModalOpen(false);
        setIsMaxFlightTrackingSubscribedModalOpen(false);
        setAlreadySubscribedModalOpen(false);
        setIsNotTrackableModalOpen(false);
        setIsGenericErrorModalOpen(false);
        setIsTrackingDisabled(false);
        setIsTrackingModalOpen(true);
    }

    const handleTrackingModalClosed = () => {

        setIsTrackingModalOpen(false);
    }

    const handleNotTrackablegModalOpen = () => {

        setIsTrackingModalOpen(false);
        setSuccessModalOpen(false);
        setIsMaxFlightTrackingSubscribedModalOpen(false);
        setAlreadySubscribedModalOpen(false);
        setIsGenericErrorModalOpen(false);
        setIsTrackingDisabled(false);
        setIsNotTrackableModalOpen(true);
    }

    const handleNotTrackablegModalClosed = () => {

        setIsNotTrackableModalOpen(false);
    }

    const handleSuccessModalOpen = () => {

        setIsTrackingModalOpen(false);
        setIsNotTrackableModalOpen(false);
        setIsMaxFlightTrackingSubscribedModalOpen(false);
        setAlreadySubscribedModalOpen(false);
        setIsGenericErrorModalOpen(false);
        setIsTrackingDisabled(false);
        setSuccessModalOpen(true);
    }

    const handleSuccessModalClosed = () => {

        setSuccessModalOpen(false);
    }

    const handleAlreadySubscribedModalOpen = () => {

        setIsTrackingModalOpen(false);
        setIsNotTrackableModalOpen(false);
        setSuccessModalOpen(false);
        setIsMaxFlightTrackingSubscribedModalOpen(false);
        setIsGenericErrorModalOpen(false);
        setIsTrackingDisabled(false);
        setAlreadySubscribedModalOpen(true);
    }

    const handleAlreadySubscribedModalClosed = () => {

        setAlreadySubscribedModalOpen(false);
    }

    const handleMaxFlightTrackingSubscribedModalOpen = () => {

        setIsTrackingModalOpen(false);
        setIsNotTrackableModalOpen(false);
        setSuccessModalOpen(false);
        setAlreadySubscribedModalOpen(false);
        setIsGenericErrorModalOpen(false);
        setIsTrackingDisabled(false);
        setIsMaxFlightTrackingSubscribedModalOpen(true);
    }

    const handleMaxFlightTrackingSubscribedModalClosed = () => {

        setIsMaxFlightTrackingSubscribedModalOpen(false);
    }

    const handleGenericErrorModalOpen = () => {

        setIsTrackingModalOpen(false);
        setIsNotTrackableModalOpen(false);
        setSuccessModalOpen(false);
        setIsUserNotLoggedModalOpen(true);
        setAlreadySubscribedModalOpen(false);
        setIsMaxFlightTrackingSubscribedModalOpen(false);
        setIsGenericErrorModalOpen(false);
        setIsUserNotLoggedModalOpen(false)
        setIsGenericErrorModalOpen(true);
    }

    const handleGenericErrorModalClosed = () => {

        setIsGenericErrorModalOpen(false);
    }

    const handleUserNotLoggedModalOpen = () => {

        setIsTrackingModalOpen(false);
        setIsNotTrackableModalOpen(false);
        setSuccessModalOpen(false);
        setIsUserNotLoggedModalOpen(true);
        setAlreadySubscribedModalOpen(false);
        setIsMaxFlightTrackingSubscribedModalOpen(false);
        setIsGenericErrorModalOpen(false);
        setIsTrackingDisabled(false);
        setIsUserNotLoggedModalOpen(true);
    }

    const handleUserNotLoggedModalClosed = () => {

        setIsUserNotLoggedModalOpen(false);
    }

    const handleTrackingDisabledModalOpen = () => {

        setIsTrackingModalOpen(false);
        setIsNotTrackableModalOpen(false);
        setSuccessModalOpen(false);
        setIsUserNotLoggedModalOpen(true);
        setAlreadySubscribedModalOpen(false);
        setIsMaxFlightTrackingSubscribedModalOpen(false);
        setIsGenericErrorModalOpen(false);
        setIsUserNotLoggedModalOpen(false)
        setIsTrackingDisabled(true);
    }

    const handleTrackingDisabledModalClosed = () => {

        setIsTrackingDisabled(false);
    }

    async function enableTracking(type: string) {

        if (auth?.user?.token && process.env.NEXT_PUBLIC_FLIGHTS_TRACKING_KEY) {

            return await
                fetch(
                    `${process.env.NEXT_PUBLIC_FLIGHTS_TRACKING_PATH}/${lang}/trackings/subscribe`, {
                    method: 'POST',
                    body: JSON.stringify({
                        "flightId": flightDetails?.flightId,
                        "origin": "siteA",
                        "recipients": [{
                            "receiver": auth.user.username,
                            "type": type
                        }]
                    }),
                    headers: new Headers({
                        'Authorization': auth?.user?.token,
                        'Content-Type': 'application/json',
                        'keyId': process.env.NEXT_PUBLIC_FLIGHTS_TRACKING_KEY
                    }),
                })
                    .then(response => response.json())
                    .then(response => {

                        setisLoading(false);
                        //CASO: VOLO NON TRACCIABILE
                        if ((response.status == trackingResponseCode['CODE_500'] && response.message == trackingResponseCode['MESSAGE_CODE_500_FLIGHT_NOT_TRACEABLE']) ||
                            flightDetails?.statusPublic == "APP" || flightDetails?.statusPublic == "LAN") {

                            handleNotTrackablegModalOpen();

                            //CASO: VOLO GIA SUBSCRIBED
                        } else if (response.status == trackingResponseCode['CODE_500'] && response.message == trackingResponseCode['MESSAGE_CODE_500_FLIGHT_ALREADY_SUBSCRIBED']) {

                            handleAlreadySubscribedModalOpen();

                            //CASO: MAX FLIGHTS SUBSCRIBED 3
                        } else if (response.status == trackingResponseCode['CODE_500'] && response.message == trackingResponseCode['MESSAGE_CODE_500_MAX_FLIGHTS_SUBSCRIBED']) {

                            handleMaxFlightTrackingSubscribedModalOpen();

                            //ATTESA MODIFICA RISPOSTA, ATTUALMENTE NON RESTITUISCE 200
                        } else if (response.status == trackingResponseCode['CODE_200']) {

                            handleSuccessModalOpen();
                        } else {

                            handleGenericErrorModalOpen();
                        }
                    }
                    ).catch(error => {
                        console.error('[TrackingButton] enableTracking -> ', error);
                    })

        } else {

            setisLoading(false);
            handleUserNotLoggedModalOpen();
        }
    }

    async function disableTracking(flightId: string, type: string) {

        //setisLoading(true);

        if (auth?.user?.token && process.env.NEXT_PUBLIC_FLIGHTS_TRACKING_KEY) {

            return await fetch(
                `${process.env.NEXT_PUBLIC_FLIGHTS_TRACKING_PATH}/${lang}/trackings/suspend`, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': auth?.user?.token,
                    'Content-Type': 'application/json',
                    'keyId': process.env.NEXT_PUBLIC_FLIGHTS_TRACKING_KEY
                }),
                body: JSON.stringify({
                    "flightId": flightId,
                    "origin": "sitoA",
                    "recipients": [{
                        "receiver": auth?.user?.username,
                        "type": 'MAIL' // NOTA: il campo viene recuperato direttamente dal db, non ha importanza cosa gli viene passato
                    }]
                }),
            })
            .then(response => response.json())
            .then(response => {
                if (response.status == trackingResponseCode['CODE_200']) {

                    handleTrackingDisabledModalOpen();
                } else {

                    handleGenericErrorModalOpen();
                }
            }).catch(error => {
                console.error('[TrackingButton] disableTracking -> ', error);
            })
        }
    }

    useOutsideClick(dropdownRef as MutableRefObject<HTMLDivElement>, () => { setIsTrackingModalOpen(false); });

    return (
        <>
            {flightDetails ?
                <div className='relative'>

                    <div onClick={()=>handleTrackingModalOpen()} className={`${checkIfModalOpen() ? "invert" : "border border-black cursor-pointer"} flightTrackingBtn w-fit flex flex-col items-center justify-center px-3 py-2 gap-1 rounded-2xl bg-[white] ${isMobile && "w-[50px] h-[50px]"}`}>
                        <CustomIcon iconName='airplane' className="min-h-[20px]" />
                        <div className='font-semibold text-xs text-black'>
                            {t('flights.follow')}
                        </div>
                    </div>

                    {isTrackingModalOpen && (
                        <div ref={dropdownRef} className='rounded-3xl absolute p-4 top-[-10px] right-[74px] w-[240px] desk:w-[282px] bg-[#F2F2F2] shadow-[0px_5px_10px_rgba(0,0,0,0.15)] z-[21]'>
                            <div className="absolute w-0 h-0 top-[24px] right-[-11px] c-[#F2F2F2]
                                border-t-[8px] border-t-transparent
                                border-l-[12px] border-[#F2F2F2]
                                border-b-[8px] border-b-transparent
                                "></div>
                            <div onClick={handleTrackingModalClosed} className='absolute top-4 right-4 cursor-pointer'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth={1.5}
                                    stroke='black'
                                    className='w-6 h-6'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        d='M6 18L18 6M6 6l12 12'
                                    />
                                </svg>
                            </div>
                            <h4 className='text-center text-base font-bold text-black mb-4 uppercase'>{t("flights.trackingMode")}</h4>
                            <div className='flex gap-2'>
                            <div className='flex flex-1'>
                                    <a className='flex flex-col flex-1 items-center cursor-pointer' href={"https://api.whatsapp.com/send/?phone=3902232323&text=" + t('flights.whatsappText') +  flightDetails.flightNumber + "&type=phone_number&app_absent=0'"} target='_blank'>
                                        {<Image alt='' src={whatsapp} className="w-8 h-8" />}
                                        <div className='text-black text-sm mt-2 font-normal'>WhatsApp</div>
                                    </a>
                                </div>
                                {
                                    isApple &&                                       
                                    <div className='flex flex-col flex-1 items-center cursor-pointer apple-business-chat-message-container' 
                                    data-apple-business-id={process.env.NEXT_PUBLIC_APPLE_BUSINESS_ID}
                                    data-apple-business-body={t('flights.imessageText') + flightDetails.flightNumber}
                                    data-apple-business-intent-id={`QUERY_FLIGHTS. ${flightDetails.movementType} ${flightDetails.flightNumber} ${flightDetails.originFlightDate}`}
                                    data-apple-icon-background-color="#ffffff">
                                        <a className='flex flex-col flex-1 items-center cursor-pointer apple-business-chat-message-container' href={"https://bcrw.apple.com/urn:biz:e039ec15-5521-4777-b51a-baa5b19f2851?biz-intent-id=QUERY_FLIGHTS&amp;body=" + t('flights.imessageText') +  flightDetails.flightNumber } title=" " target='_blank'>
                                            {<Image alt='' src={iMessage} className="w-8 h-8" />}
                                            <div className='text-black text-sm mt-2 font-normal'>iMessage</div>
                                        </a>
                                    </div>
                                }
                                {/* <FacebookMessenger movementType={flightDetails?.movementType} flightNumber={flightDetails?.flightNumber} originalFlightDate={flightDetails?.originFlightDate} /> */}
                                <div className='flex flex-col flex-1 items-center cursor-pointer' onClick={() => enableTracking('MAIL')}>
                                    <Image alt='' src={mail} className="w-8 h-8" />
                                    <p className='text-black text-sm mt-2 font-normal'>Email</p>
                                </div>
                            </div>
                        </div>
                    )}

                    {isNotTrackableModalOpen && (
                        !isLoading ?
                            <div ref={dropdownRef} className='rounded-3xl absolute pt-3 pb-6 pl-2 pr-2 top-[-10px] right-[74px] w-[240px] desk:w-[282px] bg-[#F2F2F2] shadow-[0px_5px_10px_rgba(0,0,0,0.15)] text-black text-center z-[21]'>
                                <div className="absolute w-0 h-0 top-[24px] right-[-11px] c-[#F2F2F2]
                                border-t-[8px] border-t-transparent
                                border-l-[12px] border-[#F2F2F2]
                                border-b-[8px] border-b-transparent
                                "></div>
                                {/* <div className="absolute top-4 right-60 pl-2">
                                    <CustomIcon iconName="airplane-solid"></CustomIcon>
                                </div> */}
                                <div onClick={handleNotTrackablegModalClosed} className='absolute top-3 right-3 cursor-pointer'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        strokeWidth={1.5}
                                        stroke='black'
                                        className='w-6 h-6'
                                    >
                                        <path
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            d='M6 18L18 6M6 6l12 12'
                                        />
                                    </svg>
                                </div>
                                <h1 className='font-bold font-primary font-normal text-base mb-2'>{t('flights.flightTracking')}</h1>
                                <span className='text-sm font-primary font-normal'>{t('flights.flightNotTrackable')}</span>
                            </div>
                            : <MoonLoader className="m-auto" color="#6ABACE" />
                    )}

                    {isSuccessModalOpen && (
                        !isLoading ?
                            <div ref={dropdownRef} className='rounded-3xl absolute pt-3 pb-6 pl-2 pr-2 top-[-10px] right-[74px] w-[240px] desk:w-[282px] bg-[#F2F2F2] shadow-[0px_5px_10px_rgba(0,0,0,0.15)] text-black text-center z-[21]'>
                                <div className="absolute w-0 h-0 top-[24px] right-[-11px] c-[#F2F2F2]
                                border-t-[8px] border-t-transparent
                                border-l-[12px] border-[#F2F2F2]
                                border-b-[8px] border-b-transparent
                                "></div>
                                {/* <div className="absolute top-4 right-60 pl-2">
                                    <CustomIcon iconName="airplane-solid"></CustomIcon>
                                </div> */}
                                <div onClick={handleSuccessModalClosed} className='absolute  top-3 right-3 cursor-pointer'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        strokeWidth={1.5}
                                        stroke='black'
                                        className='w-6 h-6'
                                    >
                                        <path
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            d='M6 18L18 6M6 6l12 12'
                                        />
                                    </svg>
                                </div>
                                <h1 className='font-bold mb-2 font-primary font-normal text-base'>{t('flights.flightTracking')}</h1>
                                <span className='text-sm font-primary font-normal'>{t('flights.flightTrackingSuccess')}</span>
                            </div>
                            : <MoonLoader className="m-auto" color="#6ABACE" />
                    )}

                    {isAlreadySubscribedModalOpen && (
                        <div ref={dropdownRef} className="rounded-3xl absolute pt-3 pb-6 pl-2 pr-2 top-[-10px] right-[74px] w-[240px] desk:w-[282px] bg-[#F2F2F2] shadow-[0px_5px_10px_rgba(0,0,0,0.15)] text-black text-center justify-center z-[21]">
                            <div className="absolute w-0 h-0 top-[24px] right-[-11px] c-[#F2F2F2]
                                border-t-[8px] border-t-transparent
                                border-l-[12px] border-[#F2F2F2]
                                border-b-[8px] border-b-transparent
                                "></div>
                            {/* <div className="absolute top-4 right-60 pl-2">
                                <CustomIcon iconName="airplane-solid"></CustomIcon>
                            </div> */}
                            <div onClick={handleAlreadySubscribedModalClosed} className='absolute top-3 right-3 cursor-pointer'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth={1.5}
                                    stroke='black'
                                    className='w-6 h-6'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        d='M6 18L18 6M6 6l12 12'
                                    />
                                </svg>
                            </div>
                            <h1 className='font-bold text-base font-primary mb-4'>{t('flights.flightTracking')}</h1>
                            <h1 className='text-sm font-primary font-normal mb-4'>{t('flights.flightTrackingAlreadySubscribed')}</h1>
                            <div className="flex justify-center bg-black rounded-2xl cursor-pointer p-2 m-2 hover:bg-[#6abace] transition duration-300" onClick={() => disableTracking(flightDetails.flightId, 'M')}>
                                <span className='text-sm font-primary font-normal text-lightgrey'>{t('flights.flightTrackingDisable')}</span>
                            </div>
                        </div>
                    )}

                    {isMaxFlightTrackingSubscribedModalOpen && (
                        !isLoading ?
                            <div ref={dropdownRef} className="rounded-3xl absolute pt-3 pb-6 pl-2 pr-2 top-[-10px] right-[74px] w-[240px] desk:w-[282px] bg-[#F2F2F2] shadow-[0px_5px_10px_rgba(0,0,0,0.15)] text-black text-center z-[21]">
                                <div className="absolute w-0 h-0 top-[24px] right-[-11px] c-[#F2F2F2]
                                border-t-[8px] border-t-transparent
                                border-l-[12px] border-[#F2F2F2]
                                border-b-[8px] border-b-transparent
                                "></div>
                                {/* <div className="absolute top-4 right-60 pl-2">
                                    <CustomIcon iconName="airplane-solid"></CustomIcon>
                                </div> */}
                                <div onClick={handleMaxFlightTrackingSubscribedModalClosed} className='absolute top-3 right-3 cursor-pointer'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        strokeWidth={1.5}
                                        stroke='black'
                                        className='w-6 h-6'
                                    >
                                        <path
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            d='M6 18L18 6M6 6l12 12'
                                        />
                                    </svg>
                                </div>
                                <h1 className='font-bold mb-2 font-primary font-normal text-base'>{t('flights.flightTracking')}</h1>
                                <span className='text-sm font-primary font-normal'>{t('flights.flightTrackingMaxSubscription')}</span>
                            </div>
                            : <MoonLoader className="m-auto" color="#6ABACE" />
                    )}

                    {isGenericErrorModalOpen && (
                        !isLoading ?
                            <div ref={dropdownRef} className="rounded-3xl absolute pt-3 pb-6 pl-2 pr-2 top-[-10px] right-[74px] w-[240px] desk:w-[282px] bg-[#F2F2F2] shadow-[0px_5px_10px_rgba(0,0,0,0.15)] text-black text-center z-[21]">
                                <div className="absolute w-0 h-0 top-[24px] right-[-11px] c-[#F2F2F2]
                                border-t-[8px] border-t-transparent
                                border-l-[12px] border-[#F2F2F2]
                                border-b-[8px] border-b-transparent
                                "></div>
                                {/* <div className="absolute top-4 right-60 pl-2">
                                    <CustomIcon iconName="airplane-solid"></CustomIcon>
                                </div> */}
                                <div onClick={handleGenericErrorModalClosed} className='absolute top-3 right-3 cursor-pointer'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        strokeWidth={1.5}
                                        stroke='black'
                                        className='w-6 h-6'
                                    >
                                        <path
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            d='M6 18L18 6M6 6l12 12'
                                        />
                                    </svg>
                                </div>
                                <h1 className='font-bold mb-2 font-primary font-normal text-base'>{t('flights.flightTracking')}</h1>
                                <span className='text-sm font-primary font-normal'>{t('flights.flightGenericError')}</span>
                            </div>
                            : <MoonLoader className="m-auto" color="#6ABACE" />
                    )}

                    {isUserNotLoggedModalOpen && (
                        !isLoading ?
                            <div ref={dropdownRef} className="rounded-3xl absolute pt-3 pb-6 pl-2 pr-2 top-[-10px] right-[74px] w-[240px] desk:w-[282px] bg-[#F2F2F2] shadow-[0px_5px_10px_rgba(0,0,0,0.15)] text-black text-center z-[21]">
                                <div className="absolute w-0 h-0 top-[24px] right-[-11px] c-[#F2F2F2]
                                border-t-[8px] border-t-transparent
                                border-l-[12px] border-[#F2F2F2]
                                border-b-[8px] border-b-transparent
                                "></div>
                                {/* <div className="absolute top-4 right-60 pl-2">
                                    <CustomIcon iconName="airplane-solid"></CustomIcon>
                                </div> */}
                                <div onClick={handleUserNotLoggedModalClosed} className='absolute top-3 right-3 cursor-pointer'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        strokeWidth={1.5}
                                        stroke='black'
                                        className='w-6 h-6'
                                    >
                                        <path
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            d='M6 18L18 6M6 6l12 12'
                                        />
                                    </svg>
                                </div>
                                <h1 className='font-bold mb-2 font-primary font-normal text-base'>{t('flights.flightTracking')}</h1>
                                <span className='text-sm font-primary font-normal'>{t('flights.flightTrackingNotLogged')}</span>
                                <div className="flex justify-center mt-2 font-primary font-normal text-base"><Button text={t("login.loginButton")} className="py-2 mt-2" onClick={()=>setIsLoginDialogOpen(true)}/></div>
                            </div>
                            : <MoonLoader className="m-auto" color="#6ABACE" />
                    )}

                    {isTrackingDisabledModalOpen && (
                        !isLoading ?
                            <div ref={dropdownRef} className="rounded-3xl absolute pt-3 pb-6 pl-2 pr-2 top-[-10px] right-[74px] w-[240px] desk:w-[282px] bg-[#F2F2F2] shadow-[0px_5px_10px_rgba(0,0,0,0.15)] text-black text-center z-[21]">
                                <div className="absolute w-0 h-0 top-[24px] right-[-11px] c-[#F2F2F2]
                                border-t-[8px] border-t-transparent
                                border-l-[12px] border-[#F2F2F2]
                                border-b-[8px] border-b-transparent
                                "></div>
                                {/* <div className="absolute top-4 right-60 pl-2">
                                    <CustomIcon iconName="airplane-solid"></CustomIcon>
                                </div> */}
                                <div onClick={handleTrackingDisabledModalClosed} className='absolute top-3 right-3 cursor-pointer'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        strokeWidth={1.5}
                                        stroke='black'
                                        className='w-6 h-6'
                                    >
                                        <path
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            d='M6 18L18 6M6 6l12 12'
                                        />
                                    </svg>
                                </div>
                                <h1 className='font-bold text-base font-primary font-normal mb-2'>{t('flights.flightTracking')}</h1>
                                <span className='text-sm font-primary font-normal'>{t('flights.flightTrackingSuspend')}</span>
                            </div>
                            : <MoonLoader className="m-auto" color="#6ABACE" />
                    )}

                </div>
                : <MoonLoader className="m-auto" color="#6ABACE" />
            }
        </>
    );
}

export default TrackingButton
