import { IAccess, IBase, IForm, action } from "@/interfaces/IForm";
import { useState, useEffect, createRef, ChangeEvent, useRef } from "react";
import { useRouter } from "next/router";
import { add } from "date-fns";
import { useAuth } from "@/context/AuthContext";
import { useTranslation } from "react-i18next";
import { registerLocale } from "react-datepicker";

import CustomIcon from "../CustomIcon/CustomIcon";
import MultiListConverter from "./MultiListConverter";
import React from "react";
import ListConverter from "./ListConverter";
import ReactDatePicker from "react-datepicker";
import ReCAPTCHA from "react-google-recaptcha";
import VisoreInfo from "../VisoreInfo/VisoreInfo";
import it from 'date-fns/locale/it';
import prefix from 'src/prefix.json';
import nations from 'src/nations.json';
import { useIsMobile } from "@/hooks";

interface FormWrapperProps {
    form: IForm,
    bannerHidden: boolean,
    hidden: boolean,
    sendText: string,
    isMobile: boolean,
    language: string
}

const FormWrapper: React.FC<FormWrapperProps> = ({ form, bannerHidden, hidden, isMobile, language }: FormWrapperProps) => {

    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const lang = i18n.language;
    lang === "it" && registerLocale(lang, it)
    const [formFields, setFormFields] = useState([{id: "", value: ""}]);
    const [isChecked, setIsChecked] = useState(false);
    const [status, setStatus] = useState(0);
    const router = useRouter();
    const [sexValue, setSexValue] = useState("");
    const [birthDate, setBirthDate] = useState({id: "", value: "", date: "" as unknown as Date });
    const [birthDateES1, setBirthDateES1] = useState({id: "", value: "", date: "" as unknown as Date });
    const [birthDateES2, setBirthDateES2] = useState({id: "", value: "", date: "" as unknown as Date });
    const [formDate, setFormDate] = useState({id: "", value: "", date: "" as unknown as Date });
    const [formTravelDate, setFormTravelDate] = useState({id: "", value: "", date: "" as unknown as Date });
    const recaptchaRefSV = createRef<ReCAPTCHA>();
    const recaptchaRefLAF = createRef<ReCAPTCHA>();
    const recaptchaRefPRM = createRef<ReCAPTCHA>();
    const recaptchaRefINFO = createRef<ReCAPTCHA>();
    const inputRef = useRef<any>(null);
    const [enuType, setEnuType] = useState("");
    const [path, setPath] = useState("");
    const [isMinimized, setIsMinimized] = useState(false);
    const [notification, setNotification] = useState<string>('');
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const ref = useRef<HTMLTextAreaElement>(null);
    const [pref, setPref] = useState(prefix);
    const [phoneES1, setPhoneES1] = useState('');
    const [phoneES2, setPhoneES2] = useState('');
    const [selectedPrefES1, setSelectedPrefES1] = useState('+39');
    const [selectedPrefES2, setSelectedPrefES2] = useState('+39');
    const entries = Object.entries(pref);
    const [countries, setCountries] = useState(nations);
    const [selectedCountry, setSelectedCountry] = useState("IT");
    const auth = useAuth();
    const [file, setFile] = useState<any>();
    const [fileName, setFileName] = useState('');
    const [isTablet] = useIsMobile();
      
    useEffect(() => {
        // fetch(
        // "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
        // )
        // .then((response) => response.json())
        // .then((data) => {
        //     setCountries(data.countries);
        // })
  
        toggleCaptchaBadge(true);
        return () => toggleCaptchaBadge(true);
    }, []);

    useEffect(() => {
        toggleCaptchaBadge(true);
        return () => toggleCaptchaBadge(true);
    }, [form])
    
    const terminal = (form?.xtaxAirports?.ti[0]?.label.toLowerCase() === "malpensa" ? "mxp" : "lin");

    function findValue (idToFind: string) {
        const found = formFields.find(item => item.value === idToFind);
        return found?.value;
    }

    function validateEmail (value: string){
        const check = /\S+@\S+\.\S+/.test(value);
        if(check){
            setNotification("");
            setIsEmailValid(true);
            setEmail(value);
        } else {
            setNotification(`${t('form.validEmail')}`);
            setIsEmailValid(false);
            setEmail(value);
        }
    }

    function setValue (id: string, value: string) {
        const i = formFields.findIndex(e => e.id === id);
        if (i > -1) {
            let newArr = [...formFields];
            newArr[i] = {
                id: id,
                value: value
            };
            setFormFields(newArr)
        } 
        else
        formFields.push({
            id: id,
            value: value
        })
    }

    const handeFileSelection = (e: React.ChangeEvent<HTMLInputElement>)=> {
        if (e.target.files) {
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);
        }
    }

    const handleSubmit = (event:any, apiUrl: string, enuType: string) => {

        event.preventDefault();
        
        if (!isEmailValid) return;
        
        setEnuType(enuType);
        setPath(apiUrl);
        switch (enuType){
            case 'OGGETTI_SMARRITI': return recaptchaRefLAF?.current?.execute();
            case 'INFORMAZIONI': return recaptchaRefINFO?.current?.execute();
            case 'PRM_SURVEY': return recaptchaRefSV?.current?.execute();
            case 'PRM_AUTISMO': case 'PRM_ACCESS': case 'PRM_ACCESS_01': return recaptchaRefPRM?.current?.execute();
            default: return 
        }
    };

    const onReCAPTCHAChange = async (captchaCode: any) => {

        if(!captchaCode) {
            console.error('[FormWrapper] captcha error');
            return;
        }
        if(enuType === 'INFORMAZIONI')
        {
        try {
            let form = document.getElementById("helpForm") as HTMLFormElement;
            let data = new FormData(form);
            let payload = new FormData();
            const obj: any = {};

            data.forEach((value, key) => (obj[key] = value));
            Object.assign(obj, {'g-recaptcha-response' : captchaCode});
            Object.assign(obj, {'localita' : selectedCountry});

            payload.append("json", JSON.stringify(
                {obj}.obj
                ))
            payload.append("attachment", file)

            const response = await fetch(path, {
                method: "POST",
                body: payload,
                headers: {
                "accept" : "*/*",
                'keyId': process.env.NEXT_PUBLIC_FORM_KEY!
                },
            });
            if (response.ok) {
                setStatus(200);
            } else {
                setStatus(404);
                throw new Error(response.statusText);
            }
            } catch (error) {
                setStatus(404);
                console.error('[FormWrapper] submit error -> ', error);
            } finally {
                recaptchaRefLAF?.current?.reset();
                recaptchaRefPRM?.current?.reset();
                recaptchaRefSV?.current?.reset();
                recaptchaRefINFO?.current?.reset();
            }
        } else {
            try {
                let form = document.getElementById("helpForm") as HTMLFormElement;
                let data = new FormData(form);
                let payload = new FormData();
                const obj: any = {};
    
                data.forEach((value, key) => (obj[key] = value));
                Object.assign(obj, {'g-recaptcha-response' : captchaCode});
    
                if(enuType === 'OGGETTI_SMARRITI')
                    Object.assign(obj, {'dataSmarrimento' : formDate.value});
                if(enuType === 'PRM_SURVEY')
                    Object.assign(obj, {'dataViaggio' : formDate.value});
                if(enuType === 'PRM_ACCESS')
                    Object.assign(obj, {'dataPassaggio' : formDate.value});
                    Object.assign(obj, {'dataNascita' : birthDate.value});
                    Object.assign(obj, {'dataNascitaEscort1' : birthDateES1.value});
                    Object.assign(obj, {'dataNascitaEscort2' : birthDateES2.value});
                if(enuType === 'PRM_AUTISMO') {
                    Object.assign(obj, {'dataVolo' : formTravelDate.value});
                    Object.assign(obj, {'dataVisita' : formDate.value});
                    Object.assign(obj, {'telefonoEscort1' : selectedPrefES1+phoneES1});
                    Object.assign(obj, {'telefonoEscort2' : selectedPrefES2+phoneES2});
                }

                for(const entry in Object.fromEntries(data))
                    payload.append(entry, data.get(entry) as string);
            
                payload.append("json", JSON.stringify(
                    {obj}.obj
                    ))

                const response = await fetch(path, {
                    method: "POST",
                    body: JSON.stringify(
                            {obj}.obj
                          ),
                    headers: {
                    "accept" : "*/*",
                    'Content-Type': 'application/json',
                    'keyId': process.env.NEXT_PUBLIC_FORM_KEY!
                    },
                });
                if (response.ok) {
                    setStatus(200);
                } else {
                    setStatus(404);
                    throw new Error(response.statusText);
                }
                } catch (error) {
                    setStatus(404);
                    console.error('[FormWrapper] submit error -> ', error);
                } finally {
                    recaptchaRefLAF?.current?.reset();
                    recaptchaRefPRM?.current?.reset();
                    recaptchaRefSV?.current?.reset();
                    recaptchaRefINFO?.current?.reset();
            }
        }
    }

    function setDate(id: string, date: Date){
        let month = "0"
        switch(date.getMonth()){
            case 0: month = "01";
                    break;
            case 1: month = "02";
                    break;
            case 2: month = "03";
                    break;
            case 3: month = "04";
                    break;
            case 4: month = "05";
                    break;
            case 5: month = "06";
                    break;
            case 6: month = "07";
                    break;
            case 7: month = "08";
                    break;
            case 8: month = "09";
                    break;
            case 9: month = "10";
                    break;
            case 10: month = "11";
                    break;
            case 11: month = "12";
                    break;
        }

        if(id === "dataNascita"){
            setBirthDate({
                id: id,
                value: date.getFullYear() + '-' + month + '-' + (date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()),
                date: date
            })
            return;
        }
        else if(id === "dataNascitaEscort1"){
            setBirthDateES1({
                id: id,
                value: date.getFullYear() + '-' +  month + '-' + (date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()),
                date: date
            })
            return;
        }
        else if(id === "dataNascitaEscort2"){
            setBirthDateES2({
                id: id,
                value: date.getFullYear() + '-' + month + '-' + (date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()),
                date: date
            })
            return;
        }
        else if(id === "dataVolo"){
            setFormTravelDate({
                id: id,
                value: date.getFullYear() + '-' + month + '-' + (date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()),
                date: date
            })
            return;
        }
        else {
            setFormDate({
                id: id,
                value: date.getFullYear() + '-' + month + '-' + (date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()),
                date: date
            })  
            return;
        }
    };
    
    const handleInput = (e: ChangeEvent<HTMLTextAreaElement>, id: string) => {
        if (ref.current) {
          ref.current.style.height = "auto";
          ref.current.style.height = `${e.target.scrollHeight - 16}px`;
          setValue(id, e.target.value)
        }
    };

    useEffect(()=>{
        if(status === 200){
            router.push( lang === "it" ? "/it/assistenza/scrivici/grazie" : "/en/assistance/write-us/thanks");
        } else {
            if(status != 0)
                router.push( lang === "it" ? "/it/assistenza/scrivici/errore" : "/en/assistance/write-us/error");
        }
    },[status]);
    
    useEffect(() => {
        window.addEventListener('popstate', () => {
            window.location.reload();
        });
    }, []);

    const handleValidity = (e: any) =>{
        if(e.target.validity.valueMissing)
            e.target.setCustomValidity(lang === "it" ? "Campo necessario" : "Mandatory field");
        else
            e.preventDefault();
    }

    return(
        <div className="desk:px-16 desk:pb-20 mx-auto">
        <VisoreInfo data={`${process.env.NEXT_PUBLIC_BE_BASE_PATH}/rest/v1/SEA-LinMxp/contents/m17/77`} hidden={bannerHidden || hidden} language={lang}/>
        {/* NOTE: CHIESTA RIMOZIONE TEMPORANEA CHATBOT BANNER */}
        {/* <VisoreInfo data={`${process.env.NEXT_PUBLIC_BE_BASE_PATH}/rest/v1/SEA-LinMxp/contents/m17/76`} hidden={bannerHidden || hidden} language={lang}/> */}
        <div className={`${isMinimized ? "h-[160px]" : ""} bg-lightgrey rounded-xl desk:p-14 p-8 shadow-lg mt-8`} hidden={hidden}>
            <div className={`desk:absolute desk:ml-44 ${isMobile ? "w-14 -translate-y-2" : ""}`}>
                <h1 className={`desk:mb-6 mt-3 text-2xl desk:font-bold ${isMobile ? "-translate-y-1 font-semibold" : ""}`}>{form?.lblTitle[lang]}</h1>
            </div>
            <div className={`flex justify-between`}>
                <CustomIcon iconName="Mail" className={`${isMobile ? "w-14 h-10" : ""}`}/>
                <div className={`flex items-center translate-x-4 cursor-pointer ${isMobile ? "-translate-y-2" : ""}`} onClick={() => setIsMinimized(!isMinimized)}>
                    <div className={`flex items-center`}>
                    <CustomIcon iconName="black-square"/>
                    <CustomIcon iconName="arrow" className={`h-4 -rotate-90 -translate-x-10 -translate-y-1`} color="white"/>
                    </div>
                </div>
            </div>
            <div className="mt-4" hidden={isMinimized}>
            { form?.enuTypeForm === "PRM_AUTISMO" || form?.enuTypeForm === "PRM_ACCESS" || form?.enuTypeForm === "PRM_ACCESS_01" ?
                <>
                {form?.lstAccess?.map((item: IAccess)=>{
                    return (
                        <>
                        <span className="font-bold mt-2">{item.lblTitleAccess[lang]}</span>
                        <form className="desk:grid tab:grid grid-cols-2 mt- desk:mb-14 mb-4 gap-3 align-start mt-2" id="helpForm" method="POST"
                        onSubmit={
                            (e) => {
                            <>
                            {form?.enuTypeForm === "PRM_AUTISMO"
                            ?   handleSubmit(e,`${process.env.NEXT_PUBLIC_FORM_PATH}/${lang}/${terminal}/insert-autism`,"PRM_AUTISMO")
                            :   handleSubmit(e,`${process.env.NEXT_PUBLIC_FORM_PATH}/${lang}/${terminal}/insert-prm`,"PRM_ACCESS")
                            }
                            </>}
                        }    
                        >        
                        { form?.enuTypeForm === "PRM_AUTISMO" ?
                        <input type="hidden" name="type" value="PRM_AUTISMO"/>
                        : <></>
                        }
                        { form?.enuTypeForm === "PRM_ACCESS" ?
                        <input type="hidden" name="type" value="PRM_ACCESS"/>
                        : <></>
                        }
                        { form?.enuTypeForm === "PRM_ACCESS_01" ?
                        <input type="hidden" name="type" value="PRM_ACCESS_01"/>
                        : <></>
                        }
                        <input type="hidden" name="airport" value={form.xtaxAirports.ti[0].label}/>
                        <input type="hidden" name="logged" value="true"/>
                        <input type="hidden" name="code" value=""/>
                        <input type="hidden" name="success" value={`${terminal}/${lang}/assistenza-clienti/scrivici/grazie`}/>
                        <input type="hidden" name="error" value={`${terminal}/${lang}/assistenza-clienti/scrivici/errore`}/>
                        <input type="hidden" name="informativa" value="1"/>
                        <input type="hidden" name="userId" value={auth?.user?.userId ? auth?.user?.userId! : ""}/>
                        {
                            item.lstPRMPassenger?.map((child: action)=>{
                                
                                return <>  
                                {
                                    (child.enuTypePassenger != "dataVisita" && child.enuTypePassenger != "email" && child.enuTypePassenger != "telCasa" && child.enuTypePassenger != "dataNascita" && child.enuTypePassenger != "dataVolo" && child.enuTypePassenger != "terminal" && child.enuTypePassenger != "dataPassaggio")
                                    ?   <input ref={inputRef} className={`p-3 rounded-xl my-4 ${isMobile ? "w-full text-xs" : "max-w-full text-sm"}`} name={child.enuTypePassenger} placeholder={child.lblTitlePRMPassenger[lang]} required={child.flgRequiredPRMPassenger} value={findValue(child.enuType)} onChange={(e) => (setValue(child.enuType,e.target.value))} onInvalid={e => handleValidity(e)} form="helpForm"></input>
                                    :   <></>
                                }
                                {
                                    (child.enuTypePassenger === "telCasa")
                                    ?   <div className="relative">
                                            <input ref={inputRef} className={`p-3 pl-[138px] rounded-xl my-4 w-full ${isMobile ? " text-xs" : " text-sm"}`} name={child.enuTypePassenger} placeholder={child.lblTitlePRMPassenger[lang]} required={child.flgRequiredPRMPassenger} value={findValue(child.enuType)} onChange={(e) => (setValue(child.enuType,e.target.value))} onInvalid={e => handleValidity(e)} form="helpForm"></input>
                                            <select ref={inputRef} onInvalid={(e : any) => handleValidity(e)} className={`absolute border-2 border-lightgrey max-w-fit text-center left-1 p-1 rounded-xl w-full ${isMobile ? "text-xs top-0" : "text-sm top-3.5"} ${isTablet ? "text-sm top-3.5" : ""} mt-2 mb-2`} name="prefix" form="helpForm">
                                                {
                                                    entries.map((pref: any) => {
                                                        return <option value={pref[1]} selected={pref[1] === "+39" ? true : false} placeholder={pref[1]}>{pref[0]} : {pref[1]}</option>
                                                    })
                                                }
                                            </select> 
                                        </div>
                                    :   <></>
                                }  
                                {
                                    (child.enuTypePassenger === "terminal")
                                    ?   <MultiListConverter isMobile={isMobile} item={child} title={""} name={"aeroporto"}/>
                                    :   <></>
                                }
                                {
                                    (child.enuTypePassenger === "email") ?
                                        <input
                                            ref={inputRef}
                                            className={`p-3 rounded-xl my-4 ${isMobile ? "w-full text-xs" : "max-w-full text-sm"} ${!isEmailValid && email != "" ? "border-2 border-red" : ""}`}
                                            name={child.enuTypePassenger}
                                            placeholder={child.lblTitlePRMPassenger[lang]}
                                            required={child.flgRequiredPRMPassenger}
                                            value={email}
                                            onChange={(e) => (validateEmail(e.target.value))}
                                            onInvalid={e => console.log(e)}
                                            form="helpForm">
                                        </input>
                                    :   <></>
                                } 
                                {
                                    (child.enuBlockType === "email")
                                    ?   <input ref={inputRef} className={`p-3 rounded-xl my-4 ${isMobile ? "w-full text-xs" : "max-w-full text-sm"}`} name={child.enuTypePassenger} placeholder={child.lblTitlePRMPassenger[lang]} required={child.flgRequiredPRMPassenger} value={email} onChange={(e) => (validateEmail(e.target.value))} onInvalid={e => handleValidity(e)} form="helpForm"></input>
                                    :   <></>
                                }  
                                {
                                    (child.enuTypePassenger === "dataVisita" || child.enuTypePassenger === "dataPassaggio")
                                    ?   <div className="customPickerWrapper w-full desk:flex desk:flex-1 relative items-center">
                                            <ReactDatePicker className={`h-[40px] text-sm my-4`} dateFormat="dd/MM/yyyy" locale={lang === 'it' ? "it" : ""} placeholderText={formDate.value != "" ? formDate.value : child.lblTitlePRMPassenger[lang]} minDate={add(new Date(), { years: -10 })} maxDate={add(new Date(), { years: 1 })} wrapperClassName="customPicker" selected={formDate.date} onChange={(date) => setDate(child.enuBlockType, date!)} />
                                            <CustomIcon iconName="chevron-r" className={` ${isMobile ? "-translate-y-10" : ""} w-2 absolute right-1 transition-transform origin-center duration-300 scale-75`} />
                                        </div>
                                    :   <></>
                                }
                                {
                                    (child.enuTypePassenger === "dataVolo")
                                    ?   <div className="customPickerWrapper w-full desk:flex desk:flex-1 relative items-center">
                                            <ReactDatePicker className={`h-[40px] text-sm my-4`} showYearPicker={false} dateFormat="dd/MM/yyyy" locale={lang === 'it' ? "it" : ""} placeholderText={formTravelDate.value != "" ? formTravelDate.value : child.lblTitlePRMPassenger[lang]} minDate={add(new Date(), { years: -10 })} maxDate={add(new Date(), { years: 1 })} wrapperClassName="customPicker" selected={formTravelDate.date} onChange={(date) => setDate("dataVolo", date!)} />
                                            <CustomIcon iconName="chevron-r" className={` ${isMobile ? "-translate-y-10" : ""} w-2 absolute right-1 transition-transform origin-center duration-300 scale-75`} />
                                        </div>
                                    :   <></>
                                }
                                {
                                    (child.enuTypePassenger === "dataNascita")
                                    ?   <div className="customPickerWrapper w-full desk:flex desk:flex-1 relative items-center">
                                            <ReactDatePicker className={`h-[40px] text-sm my-4`} showYearDropdown dateFormat="dd/MM/yyyy" locale={lang === 'it' ? 'it' : 'en'} placeholderText={birthDate.value != "" ? birthDate.value : child.lblTitlePRMPassenger[lang]} minDate={add(new Date(), { years: -100 })} maxDate={new Date()} wrapperClassName="customPicker" selected={birthDate.date} onChange={(date) => setDate("dataNascita", date!)} />
                                            <CustomIcon iconName="chevron-r" className={` ${isMobile ? "-translate-y-10" : ""} w-2 absolute right-1 transition-transform origin-center duration-300 scale-75`} />
                                        </div>
                                    :   <></>
                                }
                                </>                         
                            })
                        }
                        {
                            item.lstPRMEscort1?.map((child: action)=>{
                                return <>
                                { child.enuTypeEscort1 != "dataNascitaEscort1" && child.enuTypeEscort1 != "telefonoEscort1" && child.enuTypeEscort1 != "emailEscort1" ?
                                    <input ref={inputRef} className={`p-3 rounded-xl my-4 ${isMobile ? "w-full text-xs" : "max-w-full text-sm"}`} name={child.enuTypeEscort1} placeholder={child.lblTitlePRMEscort1[lang]} required={child.flgRequiredPRMEscort1} value={findValue(child.enuTypeEscort1)} onChange={(e) => (setValue(child.enuTypeEscort1,e.target.value))} onInvalid={e => handleValidity(e)} form="helpForm"></input>
                                    : <></>
                                }
                                { child.enuTypeEscort1 === "emailEscort1" ?
                                    <input 
                                        ref={inputRef}
                                        className={`p-3 rounded-xl my-4 ${isMobile ? "w-full text-xs" : "max-w-full text-sm"}`}
                                        name={child.enuTypeEscort1}
                                        placeholder={child.lblTitlePRMEscort1[lang]}
                                        required={child.flgRequiredPRMEscort1}
                                        value={findValue(child.enuTypeEscort1)}
                                        onChange={(e) => (validateEmail(e.target.value))}
                                        onInvalid={e => handleValidity(e)}
                                        form="helpForm">
                                    </input>
                                    : <></>
                                }
                                {
                                    (child.enuTypeEscort1 === "dataNascitaEscort1")
                                    ?   <div className="customPickerWrapper w-full desk:flex desk:flex-1 relative items-center">
                                            <ReactDatePicker className={`h-[40px] text-sm my-4`} showYearDropdown dateFormat="dd/MM/yyyy" locale={lang === 'it' ? 'it' : 'en'} placeholderText={birthDateES1.value != "" ? birthDateES1.value : child.lblTitlePRMEscort1[lang]} minDate={add(new Date(), { years: -100 })} maxDate={new Date()} wrapperClassName="customPicker" selected={birthDateES1.date} onChange={(date) => setDate("dataNascitaEscort1", date!)} />
                                            <CustomIcon iconName="chevron-r" className={` ${isMobile ? "-translate-y-10" : ""} w-2 absolute right-1 transition-transform origin-center duration-300 scale-75`} />
                                        </div>
                                    :   <></>
                                }
                                {
                                    (child.enuTypeEscort1 === "telefonoEscort1")
                                    ?   <div className="relative">
                                            <input ref={inputRef} className={`p-3 text-center rounded-xl my-4 w-full ${isMobile ? " text-xs" : " text-sm"}`} placeholder={child.lblTitlePRMEscort1[lang]} required={child.flgRequiredPRMEscort1} value={findValue(child.enuTypeEscort1)} onChange={(e) => (setPhoneES1(e.target.value))} onInvalid={e => handleValidity(e)}></input>
                                            <select ref={inputRef} className={`absolute border-2 border-lightgrey max-w-fit text-center left-1 p-1 rounded-xl w-full ${isMobile ? "text-xs h-[40px] top-0" : "text-sm h-[38px] top-3.5"} my-4 mb-2`} onChange={(e) => (setSelectedPrefES1(e.target.value))} onInvalid={e => handleValidity(e)}>
                                                {
                                                    entries.map((pref: any) => {
                                                        return <option value={pref[1]} selected={pref[1] === "+39" ? true : false} placeholder={pref[1]}>{pref[0]} : {pref[1]}</option>
                                                    })
                                                }
                                            </select> 
                                        </div>
                                    :   <></>
                                } 
                                </>                         
                            })
                        }
                        {
                            item.lstPRMEscort2?.map((child: action)=>{
                                return <>  
                                { child.enuTypeEscort2 != "dataNascitaEscort2" && child.enuTypeEscort2 != "telefonoEscort2" && child.enuTypeEscort2 != "emailEscort2" ?
                                    <input ref={inputRef} className={`p-3 rounded-xl my-4 ${isMobile ? "w-full text-xs" : "max-w-full text-sm"}`} name={child.enuTypeEscort2} placeholder={child.lblTitlePRMEscort2[lang]} required={child.flgRequiredPRMEscort2} value={findValue(child.enuTypeEscort2)} onChange={(e) => (setValue(child.enuTypeEscort2,e.target.value))} onInvalid={e => handleValidity(e)} form="helpForm"></input>
                                    : <></>
                                }
                                { child.enuTypeEscort2 === "emailEscort2" ?
                                    <input 
                                        ref={inputRef}
                                        className={`p-3 rounded-xl my-4 ${isMobile ? "w-full text-xs" : "max-w-full text-sm"}`}
                                        name={child.enuTypeEscort2}
                                        placeholder={child.lblTitlePRMEscort2[lang]}
                                        required={child.flgRequiredPRMEscort2}
                                        value={findValue(child.enuTypeEscort2)}
                                        onChange={(e) => (validateEmail(e.target.value))}
                                        onInvalid={e => handleValidity(e)}
                                        form="helpForm">
                                    </input>
                                    : <></>
                                }
                                {
                                    (child.enuTypeEscort2 === "dataNascitaEscort2")
                                    ?   <div className="customPickerWrapper w-full desk:flex desk:flex-1 relative items-center">
                                            <ReactDatePicker className={`${isMobile ? "h-[40px]" : "h-[38px]"} text-sm my-4`} showYearDropdown dateFormat="dd/MM/yyyy" locale={lang === 'it' ? 'it' : 'en'} placeholderText={birthDateES2.value != "" ? birthDateES2.value : child.lblTitlePRMEscort2[lang]} minDate={add(new Date(), { years: -100 })} maxDate={new Date()} wrapperClassName="customPicker" selected={birthDateES2.date} onChange={(date) => setDate("dataNascitaEscort2", date!)} />
                                            <CustomIcon iconName="chevron-r" className={` ${isMobile ? "-translate-y-10" : ""} w-2 absolute right-1 transition-transform origin-center duration-300 scale-75`} />
                                        </div>
                                    :   <></>
                                }
                                {
                                    (child.enuTypeEscort2 === "telefonoEscort2")
                                    ?   <div className="relative">
                                            <input ref={inputRef} className={`p-2 text-center rounded-xl my-4 w-full h-[40px] ${isMobile ? " text-xs" : " text-sm"}`} name={child.enuTypeEscort2} placeholder={child.lblTitlePRMEscort2[lang]} required={child.flgRequiredPRMEscort2} value={findValue(child.enuTypeEscort2)} onChange={(e) => (setPhoneES2(e.target.value))} onInvalid={e => handleValidity(e)} form="helpForm"></input>
                                            <select ref={inputRef} className={`absolute border-2 border-lightgrey max-w-fit text-center left-1 p-1 rounded-xl w-full ${isMobile ? "text-xs top-0" : "text-sm top-3.5"} ${isTablet ? "text-sm top-3.5" : ""} mt-2 mb-2`} onChange={(e) => (setSelectedPrefES2(e.target.value))} onInvalid={e => handleValidity(e)}>
                                                {
                                                    entries.map((pref: any) => {
                                                        return <option value={pref[1]} selected={pref[1] === "+39" ? true : false} placeholder={pref[1]}>{pref[0]} : {pref[1]}</option>
                                                    })
                                                }
                                            </select> 
                                        </div>
                                    :   <></>
                                } 
                                </>                         
                            })
                        }
                        </form>
                        </>
                    )
                })}
                </>
                : <></>
            }
            { form?.enuTypeForm === "PRM_SURVEY" ?
                form?.lstSurvey?.map((item: IAccess)=>{
                    return (
                        <>
                        <span className="flex font-bold mt-6">{item.lblBlock[lang]}</span>
                        <form className="desk:grid tab:grid grid-cols-2 mt-2 desk:mb-14 gap-3 align-start" id="helpForm" method="POST"
                        onSubmit={(e) => handleSubmit(e,`${process.env.NEXT_PUBLIC_FORM_PATH}/${lang}/${terminal}/insert-prm-survey`,"PRM_SURVEY")
                        }
                        >
                        <input type="hidden" name="aeroporto" value={form.xtaxAirports.ti[0].label}/>
                        <input type="hidden" name="type" value="PRM_SURVEY"/>
                        <input type="hidden" name="userId" value={auth?.user?.userId ? auth?.user?.userId! : ""}/>
                        <input type="hidden" name="logged" value="true"/>
                        <input type="hidden" name="code" value=""/>
                        <input type="hidden" name="success" value="malpensa/it/assistenza-clienti/scrivici/grazie"/>
                        <input type="hidden" name="error" value="malpensa/it/assistenza-clienti/scrivici/errore"/>
                        <input type="hidden" name="cortesiaLabel" value=""/>
                        <input type="hidden" name="competenzaLabel" value=""/>
                        <input type="hidden" name="adeguatezzaLabel" value=""/>
                        <input type="hidden" name="accessibilitaLabel" value=""/>
                        <input type="hidden" name="disponibilitaLabel" value=""/>
                        <input type="hidden" name="bagniLabel" value=""/>
                        <input type="hidden" name="mezziLabel" value=""/>
                        <input type="hidden" name="salaLabel" value=""/>
                        <input type="hidden" name="servizioLabel" value=""/>
                        {
                            item.lstBlock?.map((child: action)=>{
                                return <>  
                                {
                                    (child.xconListFormBlock.label != "" && child.enuBlockType != "terminal")
                                    ?   <>
                                        <div className="flex flex-col">
                                            <label className="text-sm">{child.lblBlockList[lang]}</label>
                                            <MultiListConverter isMobile={isMobile} item={child} title={""} name={child.lblBlockList[lang]}/> 
                                        </div>
                                        </>
                                    :   <></>
                                }  
                                {
                                    (child.enuBlockType != "obbligatorieta" && child.enuBlockType != "email" && child.enuBlockType != "dataViaggio" && child.enuBlockType != "suggerimenti" && child.enuBlockType != "servizio" && child.xconListFormBlock.label === "")
                                    ?   <input ref={inputRef} className={`p-3 rounded-xl my-4 ${isMobile ? "w-full text-xs" : "max-w-full text-sm"}`} form="helpForm" name={child.enuBlockType} placeholder={child.lblBlockList[lang]} required={child.flgRequiredPRMPassenger || child.flgRequiredBlock} value={findValue(child.enuTypePassenger)} onChange={(e) => (setValue(child.enuBlockType,e.target.value))} onInvalid={e => handleValidity(e)}></input>
                                    :   <></>
                                }
                                {
                                    (child.enuBlockType === "email")
                                    ?   <input ref={inputRef} className={`p-3 rounded-xl ${isMobile ? "w-full text-xs" : "max-w-full text-sm"} ${!isEmailValid && email != "" ? "border-2 border-red" : ""} mt-2 mb-2`} form="helpForm" name={child.enuType} placeholder={child.lblBlockList[lang]} required={child.flgRequiredPRMPassenger || child.flgRequiredBlock} value={email} onChange={(e) => (validateEmail(e.target.value))} onInvalid={e => handleValidity(e)}></input>
                                    :   <></>
                                }
                                {
                                    (child.enuBlockType === "terminal")
                                    ?   <MultiListConverter isMobile={isMobile} item={child} title={""} name={child.lblBlockList[lang]}/>
                                    :   <></>
                                }
                                {
                                    (child.enuBlockType === "obbligatorieta" || child.enuBlockType === "servizio")
                                    ?   <>
                                        <div className={`flex ${isMobile ? "flex-col" : ""} items-center mt-5 mb-2`}>
                                            <span className={`"text-sm ${isMobile ? "mb-4 text-sm" : ""}`}>{child.lblBlockList[lang]}</span>
                                            <div className={` ${isMobile ? "flex flex-row mb-2" : ""}`}> 
                                                <input className={`ml-4 w-4 h-4 ${isMobile ? "translate-y-1" : ""}`} type="radio" value="SI" name={child.enuBlockType} form="helpForm"></input>
                                                <span className="ml-4 text-sm">{t('account.yes')}</span>
                                                <input className={`ml-4 w-4 h-4 ${isMobile ? "translate-y-1" : ""}`} type="radio" value="NO" name={child.enuBlockType} form="helpForm"></input>
                                                <span className="ml-4 text-sm">NO</span>
                                            </div>
                                        </div>
                                        </>
                                    :   <></>
                                }
                                {
                                    (child.enuBlockType === "suggerimenti")
                                    ?   <textarea ref={ref} className={`p-2 rounded-xl min-h-full  w-full my-4 ${isMobile ? "text-xs" : "text-sm"}`} onInvalid={(e : any) => handleValidity(e)} form="helpForm" placeholder={child.lblBlockList[lang]} required={child.flgRequiredPRMPassenger || child.flgRequiredBlock} name={child.enuBlockType} value={findValue(child.enuType)} onChange={(e) => setValue(child.enuBlockType,e.target.value)} onInput={(e : ChangeEvent<HTMLTextAreaElement>) => handleInput(e, child.enuBlockType)} style={{resize: "none", scrollMargin: 0}}></textarea>
                                    :   <></>
                                }
                                {   (child.enuBlockType === "dataViaggio")
                                    ? <div className="customPickerWrapper w-full desk:flex desk:flex-1 relative items-center">
                                        <ReactDatePicker className={`${isMobile ? "h-[30px]" : "h-[38px]"} text-sm my-4`} dateFormat="dd/MM/yyyy" locale={lang === 'it' ? 'it' : 'en'} placeholderText={formDate.value != "" ? formDate.value : child.lblBlockList[lang]} minDate={add(new Date(), { years: -10 })} maxDate={new Date()} wrapperClassName="customPicker" selected={formDate.date} onChange={(date) => setDate(child.enuBlockType, date!)} />
                                        <CustomIcon iconName="chevron-r" className={` ${isMobile ? "-translate-y-10" : ""} w-2 absolute right-1 transition-transform origin-center duration-300 scale-75`} />
                                      </div>
                                    : <></>
                                } 
                                </>                         
                            })
                        }
                        </form>
                        </>
                    )
                })
                : <></>
            }
            { form?.enuTypeForm === "RECLAMI" || form?.enuTypeForm === "INFORMAZIONI" || form?.enuTypeForm === "SUGGERIMENTI" ?
                <div className="desk:grid tab:grid grid-cols-2 mt-2 mb-14 gap-3 align-start" hidden={isMinimized}>
                    { form?.lstBase?.map((item: IBase, index)=>{
                        return (
                            <React.Fragment key={index}>
                                <form className={""} id="helpForm" method="POST"
                                onSubmit={(e) => handleSubmit(e,`${process.env.NEXT_PUBLIC_FORM_PATH}/${lang}/${terminal}/insert`,"INFORMAZIONI")
                                }
                                >
                                    <input type="hidden" name="aeroporto" value={form?.xtaxAirports?.ti[0]?.label}/>
                                    <input type="hidden" name="terminal" value={form?.xtaxAirports?.ti[0]?.label}/>
                                    <input type="hidden" name="type" value={form?.enuTypeForm!}/>
                                    <input type="hidden" name="logged" value="true"/>
                                    {/* <input type="hidden" name="telCasa" value="0000000000"/>
                                    <input type="hidden" name="telWork" value="0000000000"/>
                                    <input type="hidden" name="via" value="xxx"/>
                                    <input type="hidden" name="civico" value="1"/>
                                    <input type="hidden" name="provincia" value="xxx"/> */}
                                    <input type="hidden" name="titoloStudio" value="xxx"/>
                                    <input type="hidden" name="professione" value="xxx"/>
                                    <input type="hidden" name="oggetto" value="xxx"/>
                                    <input type="hidden" name="area" value={0}/>
                                    <input type="hidden" name="code" value=""/>
                                    <input type="hidden" name="testo" value="xxx"/>
                                    <input type="hidden" name="success" value="malpensa/it/assistenza-clienti/scrivici/grazie"/>
                                    <input type="hidden" name="error" value="malpensa/it/assistenza-clienti/scrivici/errore"/>
                                    <input type="hidden" name="informativa" value="1"/>
                                    {(item.enuTypeBase != "testo" && item.enuTypeBase != "telCell" && item.enuTypeBase != "email" && item.xconListForm.targeturl === undefined && item.enuTypeBase != "sesso" && item.enuTypeBase != "attachment" && item.enuTypeBase != "localita" && item.enuTypeBase != "terminal")
                                        ? <input ref={inputRef} className={`p-3 rounded-xl w-full text-sm mt-2 mb-2`} name={item.enuTypeBase} form="helpForm" placeholder={item.lblTitleBase[lang]} required={item.flgRequired} value={findValue(item.enuTypeBase)} onChange={(e) => (setValue(item.enuTypeBase,e.target.value))} onInvalid={e => handleValidity(e)}></input>
                                        : <></>
                                    }
                                    {(item.enuTypeBase === "email" && item.xconListForm.targeturl === undefined)
                                        ? <input ref={inputRef} className={`p-3 rounded-xl w-full text-sm ${!isEmailValid && email != "" ? "border-2 border-red" : ""} mt-2 mb-2`} form="helpForm" name={item.enuTypeBase} placeholder={item.lblTitleBase[lang]} required={item.flgRequired || item.flgRequiredBlock} value={email} onChange={(e) => (validateEmail(e.target.value))} onInvalid={e => handleValidity(e)}></input>
                                        : <></>
                                    }
                                    {
                                        (item.enuTypeBase === "telCell")
                                        ?   <div className="relative">
                                                <input ref={inputRef} className={`p-3 text-center rounded-xl my-4 w-full ${isMobile ? " text-xs" : " text-sm"}`} name={item.enuTypeBase} placeholder={item.lblTitleBase[lang]} required={item.flgRequired} value={findValue(item.enuTypeBase)} onChange={(e) => (setValue(item.enuTypeBase,e.target.value))} onInvalid={e => handleValidity(e)} form="helpForm"></input>
                                                <select ref={inputRef} onInvalid={(e : any) => handleValidity(e)} className={`absolute max-w-fit left-0 p-1 rounded-xl w-full ${isMobile ? "text-xs top-0" : "text-sm top-3.5"} ${isTablet ? "text-sm top-3.5" : ""} mt-2 mb-2`} name="prefix" form="helpForm">
                                                    {
                                                        entries.map((pref: any, index) => {
                                                            return <option key={index} value={pref[1]} selected={pref[1] === "+39" ? true : false}>{pref[0]} : {pref[1]}</option>
                                                        })
                                                    }
                                                </select> 
                                            </div>
                                        :   <></>
                                    }  
                                    {(item.enuTypeBase != "sesso" && item.xconListForm.targeturl != undefined && item.enuTypeBase != "testo" && item.enuTypeBase != "attachment" && item.enuTypeBase != "terminal" && item.enuTypeBase != "localita")
                                        ? <>
                                            <span className="mr-4 ml-1">{item.lblTitleBase[lang]}</span>
                                            <MultiListConverter isMobile={isMobile} item={item} title={""} name={item.enuTypeBase}/>
                                        </>
                                        : <></>
                                    }
                                    {(item.enuTypeBase === "testo" && item.xconListForm.targeturl === undefined)
                                        ? <textarea ref={ref} className={`p-3 rounded-xl min-h-full  w-full mt-2 mb-2 ${isMobile ? "text-xs" : "text-sm"}`} onInvalid={(e : any) => handleValidity(e)} form="helpForm" placeholder={item.lblTitleBase[lang]} required={item.flgRequired} name={item.enuTypeBase} value={findValue(item.enuTypeBase)} onChange={(e) => setValue(item.enuTypeBase,e.target.value)} onInput={(e : ChangeEvent<HTMLTextAreaElement>) => handleInput(e, item.enuTypeBase)} style={{resize: "none", scrollMargin: 0}}></textarea>
                                        : <></>
                                    }
                                    {(item.enuTypeBase === "localita")
                                        ?   <>
                                                <span className="mr-4 ml-1">{item.lblTitleBase[lang]}</span>
                                                <select ref={inputRef} className={`p-3 rounded-xl w-full ${isMobile ? "text-xs" : "text-sm"} mt-2 mb-2`} name="localita"  onChange={(e) => {setSelectedCountry(e.target.value)}} onInvalid={e => handleValidity(e)}>
                                                    <option value="NULL" selected>{t('form.none')}</option>
                                                    {
                                                        countries.countries.map((country: any, index) => {
                                                            return <option key={index} value={country.value}>{lang === "it" ? country.label : country.label_en}</option>
                                                        })
                                                    }
                                                </select> 
                                            </>
                                        : <></>
                                    }
                                    {(item.enuTypeBase === "terminal")
                                        ? <MultiListConverter isMobile={isMobile} item={item} title={""} name={item.enuTypeBase}/>
                                        : <></>
                                    }
                                    {(item.enuTypeBase === "attachment" && item.xconListForm.targeturl === undefined)
                                        ? <>
                                            <span className="mr-4 ml-1">{item.lblTitleBase[lang]}</span>
                                            <input type="hidden" name="file" value={fileName} form="helpForm"/>
                                            <input type="button" ref={inputRef} onInvalid={(e : any) => handleValidity(e)} required={item.flgRequired} className={`bg-black py-3 px-4 flex text-white hover:bg-lightblue transition duration-300 uppercase text-xs rounded-2xl cursor-pointer font-bold`} value={t('form.selectFile') as string} onClick={() => (document.getElementById('file')!.click())} />
                                            {
                                                fileName ?
                                                <h1>{fileName}</h1>
                                                : 
                                                <h1>No File</h1>
                                            }
                                            <input type="file" id="file" hidden className={`p-1 w-full text-sm mt-2 mb-2`} form="helpForm" required={item.flgRequired} onChange={(e) => handeFileSelection(e)} placeholder="No file"></input>
                                        </>
                                        : <></>
                                    }
                                    {(item.enuTypeBase === "sesso")
                                        ?   <div className={`flex ${isMobile ? "flex-col" : ""} items-center mt-5 mb-2`}>
                                                <div className={` ${isMobile ? "flex flex-row mb-2" : ""}`}> 
                                                    <span className="mr-4">{item.lblTitleBase[lang]}</span>
                                                    <input className={`w-4 h-4 translate-y-1`} type="radio" value="M" name={"sesso"} form="helpForm" onChange={() => setSexValue("M")} defaultChecked={sexValue === "M" ? true : false}></input>
                                                    <span className="ml-4 text-sm">M</span>
                                                    <input className={`ml-4 w-4 h-4 translate-y-1`} type="radio" value="F" name={"sesso"} form="helpForm" onChange={() => setSexValue("F")} defaultChecked={sexValue === "F" ? true : false}></input>
                                                    <span className="ml-4 text-sm">F</span>
                                                </div>
                                            </div>
                                        :   <></>
                                    }
                                </form>
                            </React.Fragment>
                        )
                    })
                    }
                </div>
                : <></>
            }
            <ReCAPTCHA
                id={"recaptchaRefLAF"}
                ref={recaptchaRefLAF}
                size="invisible"
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
                onChange={onReCAPTCHAChange}
            />
            <ReCAPTCHA
                id={"recaptchaRefPRM"}
                ref={recaptchaRefPRM}
                size="invisible"
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
                onChange={onReCAPTCHAChange}
            />
            <ReCAPTCHA
                id={"recaptchaRefINFO"}
                ref={recaptchaRefINFO}
                size="invisible"
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
                onChange={onReCAPTCHAChange}
            />
            <ReCAPTCHA
                id={"recaptchaRefSV"}
                ref={recaptchaRefSV}
                size="invisible"
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
                onChange={onReCAPTCHAChange}
            />
            
            { form?.enuTypeForm === "OGGETTI_SMARRITI" ?
                <div>
                    <form id="helpForm" 
                    className={`desk:grid tab:grid grid-cols-2 desk:mt-4 ${isMobile ? "" : "mb-14"} gap-3 align-start`}
                    onSubmit={e => handleSubmit(e, `${process.env.NEXT_PUBLIC_FORM_PATH}/${lang}/${terminal}/insert-lost-and-found`, "OGGETTI_SMARRITI")}
                    >
                        <>
                            <input type="hidden" name="type" value={form?.enuTypeForm}/>
                            <input type="hidden" name="aeroporto" value={process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE}/>
                            <input type="hidden" name="logged" value="true"/>
                            <input type="hidden" name="code" value=""></input>
                            <input type="hidden" name="success" value={`${terminal}/${lang}/assistenza-clienti/scrivici/grazie`}></input>
                            <input type="hidden" name="error" value={`${terminal}/${lang}/assistenza-clienti/scrivici/errore`}></input>
                            <input type="hidden" name="informativa" value="1"></input>
                            {form?.lstBase.map((item: IBase, index)=>{
                                    return (
                                        <React.Fragment key={index}>
                                            {(item.enuTypeBase != "testo" && item.enuTypeBase != "telCell" && item.enuTypeBase != "email" && item.enuTypeBase != "localita" && item.enuTypeBase != "sesso" && item.enuTypeBase != "DataSmarrimento" && item.xconListForm.targeturl === undefined)
                                                ? <input ref={inputRef} className={`p-3 rounded-xl ${isMobile ? "w-full text-xs" : "max-w-full text-sm"} mt-2 mb-2`} name={item.enuTypeBase} placeholder={item.lblTitleBase[lang]} required={item.flgRequired} value={findValue(item.enuTypeBase)} onChange={(e) => (setValue(item.enuTypeBase,e.target.value))} onInvalid={e => handleValidity(e)}></input>
                                                : <></>
                                            }
                                            {
                                                (item.enuTypeBase === "telCell")
                                                ?   <div className="relative">
                                                        <input ref={inputRef}className={`p-3 text-center rounded-xl my-4 w-full ${isMobile ? " text-xs" : " text-sm"}`} name={item.enuTypeBase} placeholder={item.lblTitleBase[lang]} required={item.flgRequired} value={findValue(item.enuTypeBase)} onChange={(e) => (setValue(item.enuTypeBase,e.target.value))} onInvalid={e => handleValidity(e)} form="helpForm"></input>
                                                        <select ref={inputRef} onInvalid={(e : any) => handleValidity(e)} className={`absolute border-2 border-lightgrey max-w-fit text-center left-1 p-1 rounded-xl w-full ${isMobile ? "text-xs top-0" : "text-sm top-3.5"} ${isTablet ? "text-sm top-3.5" : ""} mt-2 mb-2`} name="prefix" form="helpForm">
                                                            {
                                                                entries.map((pref: any) => {
                                                                    return <option value={pref[1]} selected={pref[1] === "+39" ? true : false} placeholder={pref[1]}>{pref[0]} : {pref[1]}</option>
                                                                })
                                                            }
                                                        </select> 
                                                    </div>
                                                :   <></>
                                            }  
                                            {(item.enuTypeBase != "testo" && item.enuTypeBase != "sesso" && item.enuTypeBase != "DataSmarrimento" && item.xconListForm.targeturl != undefined)
                                                ? <ListConverter isMobile={isMobile} item={item} title={""} name={item.enuTypeBase}/>
                                                : <></>
                                            }
                                            {(item.enuTypeBase === "email" && item.xconListForm.targeturl === undefined)
                                                ? <input ref={inputRef} className={`p-3 rounded-xl ${isMobile ? "w-full text-xs" : "max-w-full text-sm"} ${!isEmailValid && email != "" ? "border-2 border-red" : ""} mt-2 mb-2`} name={item.enuTypeBase} placeholder={item.lblTitleBase[lang]} required={item.flgRequired} value={email} onChange={(e) => (validateEmail(e.target.value))} onInvalid={e => handleValidity(e)}></input>
                                                : <></>
                                            }
                                            {(item.enuTypeBase === "testo" && item.xconListForm.targeturl === undefined)
                                                ? <textarea ref={ref} className={`p-3 rounded-xl min-h-full  w-full mt-2 mb-2 ${isMobile ? "text-xs" : "text-sm"}`} placeholder={item.lblTitleBase[lang]} required={item.flgRequired} name={item.enuTypeBase} value={findValue(item.enuTypeBase)} onChange={(e) => setValue(item.enuTypeBase,e.target.value)} onInput={(e : ChangeEvent<HTMLTextAreaElement>) => handleInput(e, item.enuTypeBase)} style={{resize: "none", scrollMargin: 0}} onInvalid={e => handleValidity(e)}></textarea>
                                                : <></>
                                            }
                                            {(item.enuTypeBase === "sesso")
                                                ?   <div className={`flex ${isMobile ? "-translate-y-1" : ""} items-center mt-2 mb-2`}>
                                                    <h1 className="mr-4 ml-1">{item.lblTitleBase[lang]}</h1>
                                                        <div className={` ${isMobile ? "flex flex-row mb-2" : ""}`}> 
                                                            <input className={`w-4 h-4 translate-y-1`} type="radio" value="M" name={"sesso"} form="helpForm" onChange={() => setSexValue("M")} defaultChecked={sexValue === "M" ? true : false}></input>
                                                            <span className="ml-4 text-sm">M</span>
                                                            <input className={`ml-4 w-4 h-4 translate-y-1`} type="radio" value="F" name={"sesso"} form="helpForm" onChange={() => setSexValue("F")} defaultChecked={sexValue === "F" ? true : false}></input>
                                                            <span className="ml-4 text-sm">F</span>
                                                        </div>
                                                    </div>
                                                :   <></>
                                            }
                                            {(item.enuTypeBase === "localita")
                                                ?   <div>
                                                        <span className="mr-4 ml-1">{item.lblTitleBase[lang]}</span>
                                                        <select ref={inputRef} className={`p-3 rounded-xl w-full ${isMobile ? "text-xs" : "text-sm"} mt-2 mb-2`} name="localita"  onChange={(e) => {setSelectedCountry(e.target.value)}} onInvalid={e => handleValidity(e)}>
                                                            <option value="NULL" selected>{t('form.none')}</option>
                                                            {
                                                                countries.countries.map((country: any) => {
                                                                    return <option value={country.value}>{lang === "it" ? country.label : country.label_en}</option>
                                                                })
                                                            }
                                                        </select> 
                                                    </div>
                                                : <></>
                                            }
                                            {(item.enuTypeBase === "DataSmarrimento" && item.xconListForm.targeturl === undefined)
                                                ? <div className="customPickerWrapper w-full desk:flex desk:flex-1 relative items-center my-2">
                                                    <ReactDatePicker className="text-sm" dateFormat="dd/MM/yyyy" locale={lang === 'it' ? 'it' : 'en'} placeholderText={formDate.value != "" ? formDate.value : item.lblTitleBase[lang]} minDate={add(new Date(), { years: -10 })} maxDate={new Date()} wrapperClassName="customPicker" selected={formDate.date} onChange={(date) => setDate(item.enuTypeBase, date!)} />
                                                    <CustomIcon iconName="chevron-r" className={` ${isMobile ? "-translate-y-8" : ""} ${isTablet ? "-translate-y-10" : ""} w-2 absolute right-1 transition-transform origin-center duration-300 scale-75`} />
                                                </div>
                                                : <></>
                                            }
                                        </React.Fragment>
                                    )
                            })}
                        </>
                    </form>
                </div>
                : <></>
            }
            <div className="flex flex-row-reverse mb-4" >
            { form?.txbInformationDescription
                    ? <>
                        <div dangerouslySetInnerHTML={{ __html: form!.txbInformationDescription[lang] }} className="ml-2 leading-6"/>
                        <input onInvalid={(e : any) => handleValidity(e)} className="w-8" type="checkbox" form="helpForm" required defaultChecked={isChecked} onClick={()=>setIsChecked(!isChecked)}></input>
                    </>
                    : <></>
                }
            </div>
            <div className={`${isMobile ? "pt-2" : "pt-4"} flex flex-row-reverse justify-between`}>
                <button className={`active:bg-lightblue font-bold whitespace-nowrap rounded-3xl px-4 py-3 hover:bg-lightblue hover:text-white transition duration-300 max-w-min bg-black text-white`} type="submit" form="helpForm">{form?.lblSend[lang]}</button>
            </div>
            </div>
            { notification && 
                    <div className="text-center mt-2">
                        <h1 className="text-red">{notification}</h1>
                    </div>
            }
        </div>
        </div>
    )
}

const toggleCaptchaBadge = (show: boolean) => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (badge && badge instanceof HTMLElement) {
      badge.style.visibility = show ? 'visible' : 'hidden';
    }
};

export default FormWrapper;
