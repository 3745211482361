import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatTime, timeToMs } from "@/utils";
import { useIsMobile } from "@/hooks";

import Button from "../Button/Button";
import "react-datepicker/dist/react-datepicker.css";
import CustomPicker from "../CustomPicker/CustomPicker";
import CustomTimePicker from "../CustomTimePicker/CustomTimePicker";
import { addHours, isBefore } from "date-fns";

const parkingPanelInitialValuesDate = () => {
    let inDate = new Date();
    let outDate = new Date();
    const minutes = inDate.getMinutes();
    const remainder = minutes % 30;
    inDate.setMinutes(minutes + 30 - Math.abs(remainder));
    inDate = new Date(inDate.getTime() + timeToMs(2, 'hours') + timeToMs(30, 'minutes'))
    outDate = new Date(inDate.getTime() + timeToMs(3, 'hours'))
    return {
        checkInDate: inDate,
        checkOutDate: outDate,
    }
};

interface ParkingFilterPanelProps {
    data?: any
}

const ParkingFilterPanel: React.FC<ParkingFilterPanelProps> = ({ data }) => {

    const { i18n } = useTranslation();
	const lang = i18n.language;
    const [form, setFormDate] = useState<{ checkInDate: Date | null, checkOutDate: Date | null }>(parkingPanelInitialValuesDate());
    const { t } = useTranslation();
    const [ isMobile ] = useIsMobile();

    const searchButton = useCallback(() => {

        let queryParams = new URLSearchParams({
            product: 'PARK',
            airport: process?.env?.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE ? process?.env?.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE?.toString().toUpperCase() : 'MXP',
            checkInDate: form?.checkInDate ? form?.checkInDate.toLocaleDateString('en-GB') : '',
            checkInTime: form?.checkInDate ? formatTime(form?.checkInDate as Date) : '',
            checkOutDate: form?.checkOutDate ? form?.checkOutDate.toLocaleDateString('en-GB') : '',
            checkOutTime: form?.checkOutDate ? formatTime(form?.checkOutDate as Date) : ''
        });

        return (
            <a href={process.env.NEXT_PUBLIC_AIRPORT_SHOP + `/${lang}/search/parking?` + queryParams} target="_blank">
                <Button className="active:bg-lightblue" text={`${data?.lstTabber[0]?.lblButton[lang]}`} />
            </a>
        )
    }
        , [lang, form]
    );

    const maxTime = () => {
        const date = new Date();
        date.setHours(23);
        date.setMinutes(59);
        return date;
    }

    const minCheckInDate = (inDate: Date) => {
        let minInDate = new Date()
        const minutes = minInDate.getMinutes();
        const remainder = minutes % 30;
        minInDate.setMinutes(minutes + 30 - Math.abs(remainder));
        minInDate = new Date(minInDate.getTime() + timeToMs(2, 'hours') + timeToMs(30, 'minutes'))
        let nowDate = new Date(minInDate.getFullYear(),minInDate.getMonth(),minInDate.getDate()+1)
        if (inDate >= nowDate) minInDate.setHours(0, 0, 0, 0)
        return minInDate
    }

    const minCheckOutDate = (inDate: Date, outDate: Date) => {
        let minOutDate = new Date(inDate.getTime() + timeToMs(3, 'hours'))
        if (outDate.toLocaleDateString('en-GB') !== minOutDate.toLocaleDateString('en-GB')) minOutDate.setHours(0, 0, 0, 0)
        return minOutDate
    }

    const changeParkingInTime = (inDate: Date) => {

        setFormDate({ ...form, checkInDate: inDate, checkOutDate: addHours(inDate, 3) })
        
    }

    const changeParkingOutTime = (outDate: Date) => {

        if (isBefore(outDate,  addHours(form.checkInDate!, 3))) {

            setFormDate({ ...form, checkOutDate: addHours(form.checkInDate!, 3) })

        } else {

            setFormDate({ ...form, checkOutDate: outDate })
        }        
        
    }

    useEffect(
        () => {
            if (form?.checkOutDate && form?.checkOutDate.getTime() < minCheckOutDate(form?.checkInDate ? form?.checkInDate : new Date(), form?.checkOutDate ? form?.checkOutDate : new Date()).getTime() || form?.checkOutDate!.getTime() > minCheckOutDate(form?.checkInDate ? form?.checkInDate : new Date(), form?.checkOutDate ? form?.checkOutDate : new Date()).getTime())
                setFormDate({ ...form, checkOutDate: minCheckOutDate(form.checkInDate ? form.checkInDate : new Date(), form.checkOutDate ? form.checkOutDate : new Date()) })
        }, [form.checkInDate])

    useEffect(() => {
        searchButton()
    }, [])

    return <>
        <div className="text-black bg-lightgrey rounded-2xl flex w-full px-3 py-5 desk:p-10 flex-col items-start gap-4 desk:gap-4 desk:flex-row desk:items-center justify-between whitespace-nowrap">
        <div className="flex w-full items-center justify-between desk:gap-4">
                <p className="">
                    {t('flights.from')}
                </p>
                <div className=" customPickerWrapper w-[255px] desk:w-full flex desk:flex-1 items-center gap-1">
                    <CustomPicker minDate={minCheckInDate(form.checkInDate ? form.checkInDate : new Date())} selected={form.checkInDate} popperPlacement={"bottom"} onChange={(date: Date) => changeParkingInTime(date)} />
                    <CustomTimePicker minTime={minCheckInDate(form.checkInDate ? form.checkInDate : new Date())} maxTime={maxTime()} timeIntervals={30} selected={form.checkInDate} onChange={(date: Date) => changeParkingInTime(date)} />
                </div>
            </div>

            <div className="flex w-full items-center justify-between desk:gap-4">
                <p className=" desk:mr-0">
                    {t('flights.to')}
                </p>
                <div className="customPickerWrapper w-[255px] desk:w-full flex desk:flex-1 items-center gap-1">
                    <CustomPicker minDate={minCheckOutDate(form.checkInDate ? form.checkInDate : new Date(), form.checkOutDate ? form.checkOutDate : new Date())} selected={form.checkOutDate} onChange={(date: Date) => changeParkingOutTime(date)} />
                    <CustomTimePicker minTime={minCheckOutDate(form.checkInDate ? form.checkInDate : new Date(), form.checkOutDate ? form.checkOutDate : new Date())} maxTime={maxTime()} timeIntervals={30} selected={form.checkOutDate} onChange={(date: Date) => changeParkingOutTime(date)} />
                </div>
            </div>
            <div className={`${isMobile ? "flex justify-center w-[100%]" : "" }`}>
                {searchButton()}
            </div>
        </div>

    </>
}

export default ParkingFilterPanel

