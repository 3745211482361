import { IsDialogLoginOpenAtom, SideMenuAtom } from "@/atoms";
import { IFirstLevelNavItem } from "@/interfaces/INavigationDTO";
import { useEffect, useMemo, useState } from "react";
import { hoveredElementIdAtom } from "@/atoms/NavigationAtoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useRouter } from "next/router";
import { logout } from "@/api/auth";
import { useAuth } from "@/context/AuthContext";
import { useTranslation } from "react-i18next";

import styles from "./Navigation.module.css";
import useIsMobile from "@/hooks/useIsMobile";
import CustomIcon from "../CustomIcon/CustomIcon";
import getPathByLink from "@/utils/getPathByLink";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import LoginDialog from "../LoginDialog/LoginDialog";
import AccountDialog from "./AccountDialog";

interface INavigationProps {
  imageUrl?: {
    path: string;
    description: string;
    alt: string;
  };
  navItems: IFirstLevelNavItem[];
  hostPageData?: any;
  language: string;
  onSearchClick: () => any
}

const Navigation: React.FC<INavigationProps> = ({
  imageUrl,
  navItems,
  hostPageData = null,
  language,
  onSearchClick = () => {}
}: INavigationProps) => {

  const [isMobile, isTablet, isDesktop] = useIsMobile();
  const [isDialogLoginOpen, setIsLoginDialogOpen] = useRecoilState(IsDialogLoginOpenAtom);
  const accountDialogID = 999

  const router = useRouter();
  const { i18n } = useTranslation();
  const lang = language ? language : i18n.language;
  const setIsMobileMenuOpen = useSetRecoilState(SideMenuAtom);
  const [hoveredElementId, setHoveredElementId] = useRecoilState(hoveredElementIdAtom);
  const auth = useAuth();
  const [bgColorAccount, setBgColorAccount] = useState("");

  useEffect(() => { if(auth?.user) setBgColorAccount("bg-lightblue/20")}, [auth?.user])

  const mappedNavItems = useMemo(
    () =>
      navItems.map((item) => {
        return {
          label: item.labelMultilanguage[lang],
          link: getPathByLink(item, lang),
          id: item.metainfo.id
        };
      }),
    [navItems, lang]
  );

  const handleLoginDialog = () => {
    setIsLoginDialogOpen(!isDialogLoginOpen);
  };

  const handleAccountDialog = () => {
    accountDialogID !== hoveredElementId ? setHoveredElementId(accountDialogID) : setHoveredElementId(null)
  }

  const handleSearchClick = () => {
    onSearchClick()
  }

  const handleLogout = async () => {
    if (auth?.user) {
      const result = await logout(auth.user.token);
      auth.removeUser();
    }
  };

  const goToProfilePage = () => {

    router.push(isDesktop ? `/${lang}/profile/account` : `/${lang}/profile`);
  }

  return (
    <nav
      className={`text-black px-6 h-16 flex justify-between ${!isTablet && !isMobile ? "justify-center" : ""
        } items-center w-full max-w-full}`}
    >
      {isMobile || isTablet ? (
        <div className={`flex-1`}>
          <div className="" onClick={() => setIsMobileMenuOpen(true)}>
            <CustomIcon iconName="burger" />
          </div>
        </div>
      ) : null}

      <div
        className={`logoContainer cursor-pointer`}
        onClick={() => router.push(`/${lang}`)}
      >
        <img width={248} className="aspect-[185.87/26]" height={37} src={imageUrl?.path} alt="" />
      </div>

      {isTablet || isMobile ? <div className="w-5/12"></div> : null}

      {!isTablet && !isMobile ? (
        <div
          className={`${styles.navItemsContainer
            } flex gap-4 w-fit justify-center ${!isTablet && !isMobile ? "max-w-6/12" : ""
            }`}
        >
          {mappedNavItems.map((item) => {
            return (
              <div
                onClick={() => {
                  setHoveredElementId(
                    item.id === hoveredElementId ? null : item.id
                  );
                }}
                className={`cursor-pointer`}
                key={item.id}
                style={{ height: "calc(50% + 12px)" }}
              >
                <div
                  className={`${styles.navItem} font-bold bg-white flex flex-col items-center active`}
                >
                  <div className="w-min whitespace-nowrap">{item.label}</div>
                  <div
                    className={`${item.id === hoveredElementId
                      ? "h-1 w-full absolute bottom-0 bg-[#6ABACE]"
                      : styles.decorator
                      }`}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}

      <div
        className={`flex gap-6 ${!isTablet && !isMobile ? "w-3.5/12" : "w-2/12"
          } justify-end`}
      >
        <div className="flex justify-center cursor-pointer items-center">
          <div className={`${bgColorAccount} mr-3 rounded-[14px] p-2`} onClick={auth?.user ? (isMobile || isTablet ? goToProfilePage : handleAccountDialog) : handleLoginDialog}>
            <CustomIcon iconName="user" />
          </div>

          {/* Dialog account  */}
          {hoveredElementId === accountDialogID &&
            <div className="inline-block relative z-[21] cursor-default">
              <AccountDialog
                userInfo={auth?.user}
              />
            </div>}

          {!isTablet && !isMobile ? (

            auth?.user ?
              <a
                href={`/${lang}`}
                className="underline hover:text-[lightblue]"
                onClick={handleLogout}
              >
                {"Logout"}
              </a> :
              <a
                className="underline hover:text-[lightblue]"
                onClick={handleLoginDialog}
              >
                {"Login"}
              </a>
          ) : null}
        </div>
        {isDialogLoginOpen && !auth?.user && (
          <LoginDialog />
        )}
        <div className="flex justify-center gap-4 items-center">
          
          <a href={lang == 'it'? '/it/assistenza/passeggeri-ridotta-mobilita' : '/en/assistance/passengers-reduced-mobility'}>
            <CustomIcon iconName="prm" />
          </a>

          {!isTablet && !isMobile && <div onClick={() => handleSearchClick()} className="w-fit cursor-pointer">
            <CustomIcon iconName="search" />
          </div>}
          {!isTablet && !isMobile ? <CustomIcon iconName="divider" /> : null}
          {!isTablet && !isMobile ? (
            <LanguageSelector hostPageData={hostPageData} />
          ) : null}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
