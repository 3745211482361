
interface ITerminalSelectorProps {
    selectedId?: string,
    setId: (newId: string) => void
}

const terminalList = [
    'all',
    't1',
    't2'
];

const TerminalSelector: React.FC<ITerminalSelectorProps> = ({ selectedId, setId }: ITerminalSelectorProps) => {

    return (
        <div className="terminal-container flex items-center gap-2">
            {terminalList.map(terminalId => {
                return <TerminalItem key={terminalId} onClick={(newId) => setId(newId)} itemId={terminalId} isSelected={terminalId === selectedId} />
            })}
            {/* <div style={selectedId === 'all' ? {background: 'red'} : {}} onClick={() => setId('all')} className="bg-white terminalItem cursor-pointer">
                ALL
            </div>
            <div style={selectedId === 't1' ? {background: 'red'} : {}} onClick={() => setId('t1')} className="bg-white terminalItem cursor-pointer">
                T1
            </div>
            <div style={selectedId === 't2' ? {background: 'red'} : {}} onClick={() => setId('t2')} className="bg-white terminalItem cursor-pointer">
                T2
            </div> */}
        </div>
    )
}

interface ITerminalItemProps {
    itemId: string,
    isSelected: boolean,
    onClick: (newId: string) => void
}

const TerminalItem: React.FC<ITerminalItemProps> = ({ itemId, isSelected, onClick }: ITerminalItemProps) => {
    return (
        <div onClick={() => onClick(itemId)} className={`flex justify-center w-[54px] h-[54px] text-sm items-center rounded-2xl font-bold transition duration-500 terminalItem cursor-pointer ${isSelected ? 'bg-lightblue text-white' : 'bg-white text-lightblue'}`}>
            {itemId?.toUpperCase() || ''}
        </div>
    )
}

export default TerminalSelector;