import { useRecoilValue, useSetRecoilState } from "recoil"
import { AirlineIataAtom, FlightDetailsAtom, LanguageAtom, LastFlightDetailsCallAtom } from "../../atoms"
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { MovementType, flightPanelInitialValues } from "../../config/flightPages";
import CustomIcon from "../CustomIcon/CustomIcon";
import { configColor, formatTime } from "../../utils";
import { useIsMobile, useModule } from "../../hooks";
import { t } from "i18next";
import { MoonLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { ICrysisStateApiResponse } from "../../interfaces/ICrysisStateDTO";

interface IFlightsFilterPanelProps {
    hostPageType: string,
    language: string
}

const FlightDetail: React.FC<IFlightsFilterPanelProps> = ({ hostPageType, language }: IFlightsFilterPanelProps) => {

    const router = useRouter();
    const { i18n } = useTranslation();
    const lang = language ? language : i18n.language;
    const [form, setForm] = useState({ ...flightPanelInitialValues, movementType: hostPageType.includes('volo_dettaglio_partenze') ? 'D' : (hostPageType.includes('volo_dettaglio_arrivi') ? 'A' : 'notDefined') });
    const setFlighData = useSetRecoilState(FlightDetailsAtom)
    const setAirlineIata = useSetRecoilState(AirlineIataAtom)
    const flightDetails = useRecoilValue(FlightDetailsAtom)
    const setLastFlightDetailsCall = useSetRecoilState(LastFlightDetailsCallAtom)
    const flightDetailsCallDate = useRecoilValue(LastFlightDetailsCallAtom)
    const [isMobile] = useIsMobile()
    const crysisInfo = useModule<ICrysisStateApiResponse>(process.env.NEXT_PUBLIC_BE_BASE_PATH + "/rest/v1/SEA-LinMxp/pages/web/statoDiCrisi")

    function getQueryParams() {
        let queryParams = new URLSearchParams({
            airportReferenceIata: process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE || '',
            loadingType: flightPanelInitialValues.loadingType,
            movementType: form.movementType,
            querySearch: router.query.flightNumber ? router.query.flightNumber as string : router.query.flightId as string
        })
        return queryParams
    }

    const getMonth = (data: any) => {
        let date = new Date(data)
        let month = date.toLocaleString(`${lang + "-" + lang.toLocaleUpperCase()}`,
            {
                day: '2-digit',
                month: 'short'
            });
        return month.toUpperCase()
    }

    async function getFlightDetail() {

        return await fetch(
            `${process.env.NEXT_PUBLIC_FLIGHTS_BASE_PATH}/${lang}/operative/flights/lists?flightId=${router.query.flightId}`, {
                headers: { 
                    'Content-Type': 'application/json',
                    'keyId': process.env.NEXT_PUBLIC_GATEWAY_KEY as string 
                }
            }
        );
        //let queryParams = getQueryParams();
        //return await fetch(`${process.env.NEXT_PUBLIC_FLIGHTS_BASE_PATH}/${lang}/autocomplete/search${queryParams ? ('?' + queryParams) : ''}`, { headers: { 'Content-Type': 'application/json', 'keyId': process.env.NEXT_PUBLIC_GATEWAY_KEY as string } })
    }

    useEffect(() => {
        getFlightDetail()
            .then(response => response.json())
            .then((response) => {
                setLastFlightDetailsCall(new Date())
                setFlighData(response?.data[0])
                setAirlineIata(response?.data[0]?.airlineIata)
            })
    }, [router.query])

    return (
        <div className="text-black">
            {flightDetails ?
                <>
                    <div className="rounded-3xl desk:px-10 desk:py-6 p-5 flex flex-col desk:flex-row desk:items-center bg-lightgrey text-2xl shadow-xl">
                        <div className="flex justify-between items-center desk:flex-[1]">
                            <div className="font-semibold desk:mr-16 whitespace-nowrap">
                                {getMonth(flightDetails?.originFlightDate)}
                            </div>
                            {isMobile &&
                                <>
                                    {!crysisInfo?.data?.flgHideStatus ? (
                                        crysisInfo?.data?.lblLabelStatus[lang] || flightDetails?.statusPubblicDescription ?
                                            <StateChip
                                                flightItem={flightDetails}
                                                bgChip={crysisInfo?.data?.flgColorStatus ? configColor('bg', crysisInfo?.data?.enuColorStatus) : undefined}
                                                crysisLbl={crysisInfo?.data?.lblLabelStatus && crysisInfo?.data?.lblLabelStatus[lang] ? crysisInfo?.data?.lblLabelStatus[lang].toLocaleUpperCase() : undefined} />
                                            : null)
                                        : null}
                                </>}
                        </div>
                        <div className="flex desk:flex-[3] desk:px-16 justify-between desk:border-x-2 desk:border-x-darkgrey">
                            <div className="font-semibold desk:gap-4 flex desk:flex-row flex-col desk:items-center items-start">
                                <FlightTime
                                    flightItem={flightDetails}
                                    movement="D" />
                                <div className="whitespace-nowrap font-bold desk:font-semibold desk:text-2xl text-xl">
                                    {flightDetails?.routing && flightDetails?.routing.length <= 2 ?
                                        (flightDetails?.routing[0]?.airportIata === "MXP" || flightDetails?.routing[0]?.airportIata === "LIN" ?
                                            flightDetails.routing[0]?.terminal + " " : "") + flightDetails?.routing[0]?.airportIata
                                        : flightDetails.movementType == 'D' ?
                                            (flightDetails.routing[1]?.terminal ? flightDetails.routing[1]?.terminal : "") + " " + flightDetails?.routing[1]?.airportIata :
                                            (flightDetails.routing[0]?.terminal ? flightDetails.routing[0]?.terminal : "") + " " + flightDetails?.routing[0]?.airportIata
                                        }
                                </div>
                            </div>
                            <div className="flex flex-col desk:mx-12 items-center mt-[7px] mr-[10px] desk:mt-0 desk:mr-0">
                                <div><CustomIcon iconName="departureplane" className={`${isMobile && "w-10"}`} /></div>
                                <div>
                                    {isMobile ?
                                        <CustomIcon iconName="longarrow-mobile" /> :
                                        <CustomIcon iconName="longarrow" />}
                                </div>
                                <div className="text-[10px] whitespace-nowrap">{flightDetails.aircraftType}</div>
                            </div>
                            <div className="font-semibold desk:gap-4 flex desk:flex-row flex-col desk:items-center items-start">
                                <FlightTime
                                    flightItem={flightDetails}
                                    movement="A" />
                                <div className="whitespace-nowrap font-bold desk:font-semibold desk:text-2xl text-xl">
                                    {flightDetails?.routing && flightDetails?.routing.length <= 2 ?
                                        (flightDetails?.routing[1]?.airportIata === "MXP" || flightDetails?.routing[1]?.airportIata === "LIN" ?
                                            flightDetails.routing[1]?.terminal + " " : "") + flightDetails?.routing[1]?.airportIata
                                        : flightDetails.movementType == 'D' ?
                                        (flightDetails.routing[2]?.terminal ? flightDetails.routing[2]?.terminal : "") + " " + flightDetails?.routing[2]?.airportIata :
                                        (flightDetails.routing[1]?.terminal ? flightDetails.routing[1]?.terminal : "") + " " + flightDetails?.routing[1]?.airportIata
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex desk:flex-[1] flex-col items-end desk:mt-0 mt-8">
                            {!isMobile &&
                                <>
                                    {!crysisInfo?.data?.flgHideStatus ? (
                                        crysisInfo?.data?.lblLabelStatus[lang] || flightDetails?.statusPubblicDescription ?
                                            <StateChip
                                                flightItem={flightDetails}
                                                bgChip={crysisInfo?.data?.flgColorStatus ? configColor('bg', crysisInfo?.data?.enuColorStatus) : undefined}
                                                crysisLbl={crysisInfo?.data?.lblLabelStatus && crysisInfo?.data?.lblLabelStatus[lang] ? crysisInfo?.data?.lblLabelStatus[lang].toLocaleUpperCase() : undefined} />
                                            : null)
                                        : null}
                                </>}
                            <div className="flex items-center desk:gap-2 gap-1 desk:text-base text-[13px] desk:mt-3 whitespace-nowrap ml-10 ">
                                {flightDetailsCallDate ?
                                    <>
                                        <div>{t('flights.lastUpdateDetail')}:</div>
                                        <div className="w-10">{flightDetailsCallDate?.getHours() + ':' + (flightDetailsCallDate?.getMinutes() < 10 ? '0' : '') + flightDetailsCallDate?.getMinutes()}</div>
                                        <div className="cursor-pointer" onClick={() => window.location.reload()}><CustomIcon iconName="refresh" /></div>
                                    </>
                                    : <></>}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col desk:items-center mt-12">
                        <div className="flex flex-col desk:flex-row text-center desk:h-28">
                            <div className={`flex desk:flex-col desk:px-9 ${isMobile && "justify-between"} items-center`}>
                                <div className="desk:mb-5 text-sm">{t('flights.flightNumber')}</div>
                                <div className="font-semibold text-2xl whitespace-nowrap">{flightDetails?.flightNumber}</div>
                            </div>
                            {process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE === 'mxp' ?
                                <>
                                    {isMobile && <hr className="my-5" />}
                                    <div className={`flex desk:flex-col desk:border-l-2 ${form.movementType !== "D" ? "" : " desk:border-r-2 "} desk:border-x-lightgrey desk:px-9 ${isMobile && "justify-between"} items-center`}>
                                        <div className="desk:mb-5 text-sm">Terminal</div>
                                        <div className="font-semibold text-2xl whitespace-nowrap">
                                            {form.movementType === "D" ?
                                                (flightDetails?.routing ?
                                                    flightDetails.routing[0].terminal : null) :
                                                (flightDetails?.routing.map((item) => { if (item?.airportIata === process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE?.toUpperCase()) return item?.terminal }))}
                                        </div>
                                    </div>
                                </> : <div className={`desk:border-l-2 desk:border-x-lightgrey`}></div>
                            }  
                            {isMobile && <hr className="my-5" />}
                            {form.movementType !== "D" ?
                                <div className={`flex desk:flex-col desk:border-x-2 desk:border-x-lightgrey desk:px-9 ${isMobile && "justify-between"} items-center`}>
                                    <div className="desk:mb-5 text-sm">{t('flights.baggageCarousel')}</div>
                                    <div className="font-semibold text-2xl whitespace-nowrap">{flightDetails?.baggageClaim}</div>
                                </div>
                                : 
                                <>
                                    <div className={`flex desk:flex-col desk:border-r-2 desk:border-x-lightgrey desk:px-9 ${isMobile && "justify-between"} items-center`}>
                                        <div className="desk:mb-5 text-sm">Check-In</div>
                                        <div className="font-semibold text-2xl whitespace-nowrap">{flightDetails?.checkIn ? flightDetails?.checkIn : '-'}</div>
                                    </div>
                                    {isMobile && <hr className="my-5" />}
                                    <div className={`flex desk:flex-col desk:border-r-2 desk:border-x-lightgrey desk:px-9 ${isMobile && "justify-between"} items-center`}>
                                        <div className="desk:mb-5 text-sm">Area</div>
                                        <div className="font-semibold text-2xl whitespace-nowrap">{flightDetails?.areaType}</div>
                                    </div>
                                    {isMobile && <hr className="my-5" />}
                                    <div className={`flex desk:flex-col desk:border-r-2 desk:border-x-lightgrey desk:px-9 ${isMobile && "justify-between"} items-center`}>
                                        <div className="desk:mb-5 text-sm">Gate</div>
                                        <div className="font-semibold text-2xl whitespace-nowrap">{flightDetails?.gate ? flightDetails?.gate : '-'}</div>
                                    </div>
                                </>}
                            {isMobile && <hr className="my-5" />}
                            <div className={`flex desk:flex-col desk:px-9 ${isMobile && "justify-between"} items-center`}>
                                <div className="desk:mb-5 text-sm whitespace-nowrap">{t('destinations.airline')}</div>
                                <div className="text-end">{flightDetails?.airlineDescription}</div>
                            </div>
                        </div>
                        {flightDetails?.codeShare &&
                            <div className="flex desk:flex-row flex-col desk:mt-5 mt-10 desk:gap-2">
                                <div className="mb-2 desk:mb-0 text-sm">Codesharing</div>
                                <div className="desk:flex grid grid-cols-4 font-semibold gap-4 whitespace-nowrap desk:text-base text-sm">{flightDetails?.codeShare.map(item => (
                                    <div key={item.codeShareFlightNumber}>{item?.codeShareFlightNumber}</div>
                                ))}</div>
                            </div>}
                    </div>
                </>
                : (flightDetailsCallDate ? <div className="flex justify-center mt-12"><EmptyState /></div> : <MoonLoader className="m-auto" color="#6ABACE" />)}
        </div>
    )
}

const FlightTime: React.FC<any> = ({ flightItem, movement }: any) => {
    const time = movement === MovementType.D ? formatTime(new Date(flightItem?.scheduledTime)) : formatTime(new Date(flightItem?.scheduledArrivalTime));
    const delayTime = movement === MovementType.D ? formatTime(new Date(flightItem?.estimatedTime)) : formatTime(new Date(flightItem?.estimatedArrivalTime));
    return (
        <div className="flex desk:flex-col desk:mt-0 desk:mb-0 mb-2 mt-3">
            <div className={"time scheduledTime " + (delayTime === time ? 'desk:font-bold font-normal text-lg' : 'font-normal line-through text-lg -mb-[10px] mr-2 desk:mr-0')}>
                {time || '-'}
            </div>
            {delayTime !== time ? <div className="text-lg desk:font-bold">
                {delayTime || '-'}
            </div> : null}
        </div>
    )
}

interface IFlightChipProps {
    flightItem: any,
    bgChip?: string,
    crysisLbl?: string
}

const StateChip: React.FC<IFlightChipProps> = ({ flightItem, bgChip = "bg-lightblue", crysisLbl }: IFlightChipProps) => {
    const { statusPubblicDescription } = flightItem

    return (
        <div className={`${bgChip} px-2 py-1 rounded-lg w-fit`}>
            <div className="font-semibold capitalize text-sm text-white">
                {crysisLbl || statusPubblicDescription || null}
            </div>
        </div>
    )
}

const EmptyState: React.FC = () => {
    return (
        <div className='flex items-center gap-4 my-4 desk:my-10 m-auto'>
            <div className='bg-lightgrey h-[60px] w-[60px] flex items-center justify-center rounded-3xl'>
                <CustomIcon iconName="noflights" />
            </div>
            <span className='font-semibold text-lg'>{t('flights.flightDetailEmpty')}</span>
        </div>
    )
}

export default FlightDetail
