import { useModule } from "../../hooks"
import { IBaseApiResponse } from "../../interfaces/IApiResponse";
import { IModuleDTO } from "../../interfaces/IModuleDTO";
import { useTranslation } from "react-i18next";

interface ICategoryListApiResponse extends IBaseApiResponse {
    data: ICatgegoryElement[]
}

interface ICatgegoryElement extends IModuleDTO {
    lblCategoryName: { [key: string]: string }
}

const targetUrl = process.env.NEXT_PUBLIC_BE_BASE_PATH + `/rest/v1/SEA-LinMxp/contents/serviceCategory?query=xtaxAirport.ti.label=%27${process.env.NEXT_PUBLIC_AIRPORT_REFERENCE}%27`

const ServiceSelector: React.FC<any> = ({ setValue, setCategory }) => {
    const { t, i18n } = useTranslation()
    const lang = i18n.language;

    const categoryList = useModule<ICategoryListApiResponse>(targetUrl);

    return (
        <div className="cursor-pointer">
            <div onClick={()=> {setValue(t('service.serviceCategory')), setCategory("")} } className="py-3 px-6 border-b-2 border-b-gray-150 text-gray-500">{t('service.serviceCategory')}</div>
            {categoryList?.data?.map((categoryId, index) => {


                return <div key={categoryId.metainfo.id} className={`py-3 px-6 ${index != categoryList?.data?.length - 1 && "border-b-2 border-b-gray-150"}`}
                    onClick={() => { setValue(categoryId.lblCategoryName[lang]), setCategory(categoryId.lblCategoryName[lang]) }}>
                    {categoryId.lblCategoryName[lang]}
                </div>
            })}
        </div>
    )
}

export default ServiceSelector;