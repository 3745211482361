import { configColor } from "@/utils";
import { FlightDetailsAtom, HideCrumbsAtom, LanguageAtom } from "../../atoms";
import { useRecoilValue } from "recoil";
import { useIsMobile, useModule } from "../../hooks";
import { IVisoreLancioApiResponse } from "../../interfaces/IVisoreLancioDTO";


import DOMPurify from "isomorphic-dompurify";
import SearchPanel from "../SearchPanel/SearchPanel";
import ModuleComponentProps from "../../interfaces/ModuleComponent";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import ModuleThreeWrapper from "../ModuleThreeWrapper/ModuleThreeWrapper";
import TrackingButton from "../Tracking/TrackingButton";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const VisoreLancio: React.FC<ModuleComponentProps> = ({ data, language }: ModuleComponentProps) => {
    const moduleData = data as IVisoreLancioApiResponse;

    const isHideCrumbs = useRecoilValue(HideCrumbsAtom)
    const { i18n, t } = useTranslation();
    const lang = language ? language : i18n.language;

    const [isMobile] = useIsMobile()
    const flightDetails = useRecoilValue(FlightDetailsAtom)
    const [destinationData, setDestinationData] = useState<any>();
    const [imagePath, setImagePath] = useState("");

    const textColor = moduleData?.data?.enuTitleColor ? configColor('text', moduleData?.data?.enuTitleColor) : "text-white";


    const imageHeight = () => {
        switch (moduleData?.data?.enuImageHeight) {
            case "bassa":
                return "h-56";
            case "media":
                return "h-[19rem]";
            case "alta":
                return "desk:h-[30rem] h-[26rem]";
        }
    }

    const widgetMargin = () => {
        switch (moduleData?.data?.enuImageHeight) {
            case "bassa":
                return "mt-6";
            case "media":
                return moduleData?.data?.lblSubtitle && moduleData?.data?.lblSubtitle[lang] ? "mt-0" : "mt-8";
            case "alta":
                return moduleData?.data?.lblSubtitle && moduleData?.data?.lblSubtitle[lang] ? "mt-52" : "mt-56";
        }
    }

    // const widgetPosition = () => {
    //     switch (moduleData?.data?.enuImageHeight) {
    //         case "bassa":
    //             return "desk:bottom-8 -bottom-[3rem]";
    //         case "media":
    //             return "desk:-bottom-8 -bottom-[2rem]";
    //         case "alta":
    //             return "desk:-bottom-[12rem] -bottom-[15rem]";
    //     }
    // }

    async function getDestinatioData () {
        if (flightDetails && flightDetails.flightId) {
            let destination = moduleData.data.xtaxDestinations.ti?.find((destination: any) => {
                return flightDetails.routing[flightDetails?.routing[0]?.airportIata === "MXP" || flightDetails?.routing[0]?.airportIata === "LIN" ? 1 : 0].airportDescription === destination.lblAirportDestinationDescription
            })

            await fetch(destination?.targeturl ? destination?.targeturl : "").then(response => response.json()).then(jsonData => { setDestinationData(jsonData) })

        }

        return null
    }

    function getImagePath () {
        let mediaserverpath = "";
        let resourcename = "";

        if (!isMobile) {
            if (!destinationData?.data?.upiImageDesktop?.mediaserverpath) {
                mediaserverpath = moduleData?.data?.upiImageDesk?.mediaserverpath
                resourcename = moduleData?.data?.upiImageDesk?.resourcename
            }
            else {
                mediaserverpath = destinationData?.data?.upiImageDesktop?.mediaserverpath
                resourcename = destinationData?.data?.upiImageDesktop?.resourcename
            }
        }
        else {
            if (!destinationData?.data?.upiImageMobile?.mediaserverpath) {
                mediaserverpath = moduleData?.data?.upiImageMobile?.mediaserverpath
                resourcename = moduleData?.data?.upiImageMobile.resourcename
            }
            else {
                mediaserverpath = destinationData?.data?.upiImageMobile?.mediaserverpath
                resourcename = destinationData?.data?.upiImageMobile?.resourcename
            }
        }
        setImagePath(mediaserverpath + "/" + resourcename);
    }

    const switchType = () => {
        switch (moduleData?.data?.enuTypeInfo) {
            case "visore con M02-Widget-lancio in overlay":
                return <SearchPanel language={lang} targetUrl={moduleData?.data.xconM02.targeturl} data={null} />;
            case "visore con M03-Widget custom":
                return <ModuleThreeWrapper language={lang} targetUrl={moduleData?.data.xconM03.targeturl} data={null} />;
            default:
                return null
        }
    }

    useEffect(() => {
        getDestinatioData();
        getImagePath()
    }, [flightDetails, isMobile])

    return (
        <div className="desk:pb-14">
            <div
                className={`bg-cover relative bg-no-repeat ${moduleData?.data?.enuTypeInfo === "visore senza overlay" || (moduleData?.data?.enuTypeInfo !== "visore senza overlay" && moduleData?.data?.grpTypology === "grpWidgetOverlayLunch") ? "" : ""} ${imageHeight()}`}
                style={{ backgroundImage: `url( ${imagePath})` }}>
                <div className="max-w-cont-1 mx-auto w-full px-6">
                    {
                        !isHideCrumbs ?
                            <div className="pt-4">
                                <Breadcrumbs language={lang} colorCrumbs={moduleData?.data?.enuTitleColor} />
                            </div> : null
                    }

                    <div className={`pt-14 ${textColor}`}>
                        <div className='font-bold text-4xl desk:text-5xl font-secondary'>
                            {moduleData?.data?.lblTitle && moduleData?.data?.lblTitle[lang] ?

                                <div className={'flex flex-col w-full'}>
                                    <h1 className='font-semibold text-[33px] desk:text-5xl leading-[34px] font-secondary' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(moduleData?.data?.lblTitle[lang]) }} />
                                    <h2 className='text-xl font-primary font-normal desk:text-2xl' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(moduleData?.data?.lblSubtitle && moduleData?.data?.lblSubtitle[lang] ? moduleData?.data?.lblSubtitle[lang] : "") }} />
                                </div> :

                                flightDetails && flightDetails.flightId && flightDetails.routing?.length <= 2 ?

                                    flightDetails?.routing[0]?.airportIata === "MXP" || flightDetails?.routing[0]?.airportIata === "LIN" ?

                                        <div className='flex w-full items-center justify-between mx-auto my-0'>
                                            <h3 className='font-semibold text-2xl desk:text-3xl desk:leading-[34px] leading-7 font-secondary'>{flightDetails?.airlineDescription + " " + flightDetails?.flightNumber + t('flights.flightTo') + flightDetails?.routing[1]?.airportLabel}</h3>
                                            <TrackingButton flightDetails={flightDetails} />
                                        </div> :
                                        <div className='flex w-full items-center justify-between mx-auto my-0'>
                                            <h3 className='text-bold text-xl desk:text-3xl leading-[34px] font-secondary'>{flightDetails?.airlineDescription + " " + flightDetails?.flightNumber + t('flights.flightFrom') + flightDetails?.routing[0]?.airportLabel}</h3>
                                            <TrackingButton flightDetails={flightDetails} />
                                        </div>
                                    : 
                                
                                flightDetails && flightDetails.flightId && flightDetails.routing?.length > 2 ?

                                    flightDetails.movementType == 'D' ?

                                        <div className='flex w-full items-center justify-between mx-auto my-0'>
                                            <h3 className='font-semibold text-2xl desk:text-3xl desk:leading-[34px] leading-7 font-secondary'>{flightDetails?.airlineDescription + " " + flightDetails?.flightNumber + t('flights.flightTo') + flightDetails?.routing[2]?.airportLabel}</h3>
                                            <TrackingButton flightDetails={flightDetails} />
                                        </div> :
                                        <div className='flex w-full items-center justify-between mx-auto my-0'>
                                            <h3 className='text-bold text-xl desk:text-3xl leading-[34px] font-secondary'>{flightDetails?.airlineDescription + " " + flightDetails?.flightNumber + t('flights.flightFrom') + flightDetails?.routing[0]?.airportLabel}</h3>
                                            <TrackingButton flightDetails={flightDetails} />
                                        </div>
                                
                                : null
                                
                                
                                }
                                
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full relative max-w-cont-2 mx-auto desk:-mt-[60px] -mt-[20px] px-6 z-20'>
                {switchType()}
            </div>
        </div>
    );
}

export default VisoreLancio; 
