import { t } from "i18next";
import CustomIcon from "../CustomIcon/CustomIcon";

const EmptyState: React.FC = () => {

    return (
       <div className="flex flex-col items-center gap-4">
        <div className="w-fit">
            <CustomIcon iconName="no-result" />
        </div>
        <div className="text-2xl font-semibold text-center">
            {t('destinations.noResult')}
        </div>
       </div>  
    )
}

export default EmptyState;