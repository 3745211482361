const gridCol = (
  len: number,
  max: number = len,
  display: string = "default"
) => {
  let gridCols: any = {
    1: "grid-cols-1",
    2: "grid-cols-2",
    3: "grid-cols-3",
    4: "grid-cols-4",
    5: "grid-cols-5",
    6: "grid-cols-6",
    7: "grid-cols-7",
    8: "grid-cols-8",
    9: "grid-cols-9",
    10: "grid-cols-10",
    11: "grid-cols-11",
    12: "grid-cols-12",
  };
  let mobgridCols: any = {
    1: "mob:grid-cols-1",
    2: "mob:grid-cols-2",
    3: "mob:grid-cols-3",
    4: "mob:grid-cols-4",
    5: "mob:grid-cols-5",
    6: "mob:grid-cols-6",
    7: "mob:grid-cols-7",
    8: "mob:grid-cols-8",
    9: "mob:grid-cols-9",
    10: "mob:grid-cols-10",
    11: "mob:grid-cols-11",
    12: "mob:grid-cols-12",
  };
  let tabgridCols: any = {
    1: "tab:grid-cols-1",
    2: "tab:grid-cols-2",
    3: "tab:grid-cols-3",
    4: "tab:grid-cols-4",
    5: "tab:grid-cols-5",
    6: "tab:grid-cols-6",
    7: "tab:grid-cols-7",
    8: "tab:grid-cols-8",
    9: "tab:grid-cols-9",
    10: "tab:grid-cols-10",
    11: "tab:grid-cols-11",
    12: "tab:grid-cols-12",
  };
  let deskgridCols: any = {
    1: "desk:grid-cols-1",
    2: "desk:grid-cols-2",
    3: "desk:grid-cols-3",
    4: "desk:grid-cols-4",
    5: "desk:grid-cols-5",
    6: "desk:grid-cols-6",
    7: "desk:grid-cols-7",
    8: "desk:grid-cols-8",
    9: "desk:grid-cols-9",
    10: "desk:grid-cols-10",
    11: "desk:grid-cols-11",
    12: "desk:grid-cols-12",
  };

  const retval =
    display === "mob"
      ? mobgridCols[Math.min(len, max)]
      : display === "tab"
      ? tabgridCols[Math.min(len, max)]
      : display === "desk"
      ? deskgridCols[Math.min(len, max)]
      : gridCols[Math.min(len, max)];

  return retval;
};

export default gridCol;
