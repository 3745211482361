import { FreeMode } from "swiper";
import { ICardDTO } from "@/interfaces/ICardDTO";
import { useMemo, useState } from "react";
import { useDataCard } from "@/hooks";
import { IInfoLaunchesApiResponse } from "@/interfaces/IInfoLaunchesDTO";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { useTranslation } from "react-i18next";

import DOMPurify from "isomorphic-dompurify";
import configColor from "@/utils/configColor";
import ProgressBar from "@ramonak/react-progress-bar";
import useIsMobile from "@/hooks/useIsMobile";
import InformativeCard from "../Card/InformativeCard";
import CustomIcon from "../CustomIcon/CustomIcon";

interface IInfoLaunchesProps {
  data: IInfoLaunchesApiResponse,
  language: string
}

const InfoLaunches: React.FC<IInfoLaunchesProps> = ({ data, language }: IInfoLaunchesProps) => {

  const [isMobile, isTablet] = useIsMobile();
  const moduleData = data;
  const { i18n } = useTranslation();
  const lang = language ? language : i18n.language;

  const cardList = useMemo(() => {
    return moduleData?.data?.lstLaunch?.map((item) => {
      return item.xconCard.targeturl
    }) || []
  }, [moduleData])

  const dataCard = useDataCard<ICardDTO[]>({ urls: cardList })

  const [_, setActiveIndex] = useState(0);

  const lenghtlist = dataCard?.length
  const enuBackgroundModule = configColor('bg', moduleData?.data?.enuBackgroundModule);

  return (
    <>
      <div className={` ${enuBackgroundModule} ${isMobile ? " mt-8 mb-8" : ""} `}>
        <div className="flex justify-center">
          <Swiper
            // className={`select-none `}
            slidesPerView="auto"
            direction='horizontal'
            spaceBetween={20}
            slidesOffsetBefore={isMobile ? 25 : 130}
            slidesOffsetAfter={isMobile ? 25 : 130}
            onRealIndexChange={newInstance => setActiveIndex(newInstance.realIndex)}
            modules={[FreeMode]}
            freeMode
            // centeredSlides={false}
          >
            {dataCard?.map((itemCard, index) => {

              const itemId = index
              const title = DOMPurify.sanitize(itemCard.data.lblTitleInfo2[lang]);
              const enuTitleColorInfo2 = configColor('text', itemCard.data.enuTitleColorInfo2)
              const imageUrl = itemCard.data.upiImageInfo2.mediaserverpath + '/' + itemCard.data.upiImageInfo2.resourcename

              return (
                <>
                  <SwiperSlide key={itemId} className='desk:py-7 pt-10 pr-10 flex w-auto resize-none'>
                    <InformativeCard
                      title={title}
                      titleColor={enuTitleColorInfo2}
                      imageUrl={imageUrl}
                      lang={lang}
                      lstDescription={itemCard.data.lstDescription}
                    />
                  </SwiperSlide>
                  {lenghtlist > index + 1 &&
                    <SwiperSlide className="w-auto">
                      <div className="w-[10px] pt-[5.2rem] mt-[27px] border-l-[#C4C4C4] border-l-[1px]"></div>
                    </SwiperSlide>
                  }
                </>
              )
            })}

            {isMobile || isTablet ?
              <div className="flex justify-center py-5">
                <div className="w-[70%]">
                  <Progress lenghtlist={lenghtlist} lenghtBar={isMobile ? 1 : 2} />
                </div>
              </div>
              : null}
          </Swiper >
        </div>
      </div>
    </>
  );
};

interface ProgressProps {
  lenghtlist?: number
  lenghtBar?: number
}

const Progress: React.FC<ProgressProps> = ({ lenghtlist = 0, lenghtBar = 1 }: ProgressProps) => {
  const swiperRef = useSwiper();
  return (
    <ProgressBar completed={swiperRef.activeIndex + 1} maxCompleted={(lenghtlist - lenghtBar) + 1} bgColor={'#000000'} baseBgColor={'#FFFFFF'} isLabelVisible={false} height={'5px'} />
  )
}

export default InfoLaunches;
