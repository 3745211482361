import { useState } from "react";
import CustomIcon from "../CustomIcon/CustomIcon";
import TextInput from "../TextInput/TextInput";
import Button from "../Button/Button";
import { IPageDTOResponse } from "@/interfaces/IPageDTO";
import { useRecoilState, useSetRecoilState } from "recoil";
import { GlobalSearchInfoAtom, GlobalSearchResultAtom, GlobalSearchTermAtom } from "@/atoms";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../hooks";


const GlobalSearchBar: React.FC = () => {

    const { i18n } = useTranslation()
    const lang = i18n.language;
    const [isMobile] = useIsMobile()
    const [searchTerm, setSearchTerm] = useRecoilState(GlobalSearchTermAtom)
    const setGlobalSearchResult = useSetRecoilState(GlobalSearchResultAtom)
    const setGlobalSearchInfo = useSetRecoilState(GlobalSearchInfoAtom)
    const { t } = useTranslation()
    const handleSearch = () => {

        setGlobalSearchInfo((prevValue) => { return { ...prevValue, callInProgress: true, searchStarted: true } })

        fetch(process.env.NEXT_PUBLIC_BE_BASE_PATH + '/rest/v1/SEA-LinMxp/contents/abstractPage?query=xtaxAirports.ti.label=%27' + process.env.NEXT_PUBLIC_AIRPORT_REFERENCE + '%27%20AND%20_fulltext+like+%27' + searchTerm + '%27')
            .then(response => response.json())
            .then((responseData: IPageDTOResponse) => {
                setGlobalSearchInfo((prevValue) => { return { ...prevValue, resultListInfo: responseData.resultInfo } })
                setGlobalSearchResult(responseData.data.filter(result => result.lblSeoTitle[lang]))
            })
            .finally(() => {

                setGlobalSearchInfo((prevValue) => { return { ...prevValue, callInProgress: false } });
            })
            .catch((error) => {

                setGlobalSearchInfo((prevValue) => { return { ...prevValue, resultListInfo: null, callInProgress: false } })
                console.error('[GlobalSearchBar] Error -> ', error);
            })
    }

    const handleReset = () => {
        setSearchTerm('')
        setGlobalSearchInfo((prevValue) => { return { ...prevValue, resultListInfo: null, searchStarted: false } })
        setGlobalSearchResult([])
    }

    return (
        <div className={`${isMobile ? "" : " gap-2"} flex`}>
            <div className="searchBar desk:h-fit bg-lightgrey rounded-xl rounded-r-none desk:rounded-r-xl w-[85%] desk:w-[80%]">
                <TextInput
                    className="leading-7"
                    placeholder={t('globalSearch.search').toString()}
                    onKeyDown={(e: KeyboardEvent) => e.key.toLowerCase() === 'enter' ? handleSearch() : null}
                    onChange={(e: any) => { setSearchTerm(e.target?.value), e.target?.value === "" ? handleReset() : null }}
                    generalBgColor="#f2f2f2"
                    inputBgColor="#f2f2f2"
                    postfix={searchTerm && searchTerm.length ?
                        <SearchBarChip text={t("globalSearch.delete")}
                            onClick={() => handleReset()}
                        /> : null}
                    value={searchTerm}
                    prefix={!isMobile ? <CustomIcon iconName="search" /> : null}
                />
            </div>
            <div className={`desk:w-[20%] w-[15%]`}>
                {isMobile ?
                    <div
                        className={`${!searchTerm ? "opacity-30 pointer-events-none" : ""} flex rounded-xl rounded-l-none bg-[#333] h-full justify-center items-center`}
                        onClick={searchTerm ? () => handleSearch() : () => { }}>
                        <CustomIcon
                            className={`${!searchTerm ? "opacity-30 pointer-events-none" : ""} w-[20px]`}
                            iconName="search"
                            color="white" />
                    </div>
                    : <Button
                        rounded="rounded-r-full desk:rounded-full desk:h-[3rem] items-center"
                        className={`${!searchTerm ? "opacity-30 cursor-default pointer-events-none" : ""} m-auto desk:px-8`}
                        onClick={searchTerm ? () => handleSearch() : () => { }}
                        text={t('globalSearch.searchBtn')} />}
            </div>
        </div>
    )
}

interface ISearchBarChipProps {
    text: string,
    iconName?: string,
    onClick?: () => any
}
const SearchBarChip: React.FC<ISearchBarChipProps> = ({ text, iconName = 'close', onClick = () => console.log('Chip clicked') }: ISearchBarChipProps) => {
    return (
        <div onClick={() => onClick()} className="flex text-mediumgrey items-center cursor-pointer">
            <div className="text-sm">{text}</div>
            <div className="h-[24px] w-[24px] mt-[2px]">
                <CustomIcon className="m-auto w-[24px] h-[24px]" color="#999999" iconName={iconName} />
            </div>
        </div>
    )
}

export default GlobalSearchBar;