import { useIsMobile, useModule } from "@/hooks";
import { configColor, getPathByLink } from "@/utils";
import { LanguageAtom } from "@/atoms";
import { useRecoilValue } from "recoil";
import { useMemo, useRef, useState } from "react";
import { IAlertApiResponse } from "@/interfaces/IAlertDTO";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import CustomIcon from "../CustomIcon/CustomIcon";
import { useTranslation } from "react-i18next";
import React from "react";

interface IAlertProps {
  targetUrl: string,
  language: string
}

interface SliderControllerProps {
  sliderLength: number
  lang: string
}

const Alert: React.FC<IAlertProps> = ({ targetUrl, language }: IAlertProps) => {

  const moduleData = useModule<IAlertApiResponse>(targetUrl);
  const { i18n } = useTranslation();
  const lang = language ? language : i18n.language;
  const swiperRef = useRef<null | any>(null);
  const [isMobile] = useIsMobile();

  const alertAutoplayTime = process.env.NEXT_PUBLIC_CAROUSEL_ALERT_MILLISECONDS && !isNaN(Number(process.env.NEXT_PUBLIC_CAROUSEL_ALERT_MILLISECONDS)) ? Number(process.env.NEXT_PUBLIC_CAROUSEL_ALERT_MILLISECONDS) : 3000;

  const [_, setActiveIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false)
  const openClose = () => { setIsOpen(!isOpen); swiperRef?.current?.swiper.autoplay.stop() }

  const filterRule = (item: any) => new Date(item.dteStart + " " + item.lblStartTime).getTime() <= new Date().getTime() && new Date(item.dteEnd + " " + item.lblEndTime).getTime() >= new Date().getTime()
  const slideList = useMemo(() => {
    return moduleData?.data?.filter(item => filterRule(item)).map((alertItem: any, index) => {

      let alertItemId: number = alertItem.metainfo.id
      let flgSkillIcon: string = alertItem.flgSkillIcon ? "block" : "hidden"
      let flgTarget: string = alertItem.flgTarget ? "_blanck" : "_self"
      let lblTitle: string = alertItem.lblTitle[lang]
      let lblLink: string = alertItem.lblLink[lang]
      let enuRowColor: string = configColor('border', alertItem.enuRowColor)
      let txbSubtitle: string = alertItem.txbSubtitle[lang]
      let lblLabelCta: string = alertItem.lblLabelCta[lang]

      let isVisible = alertItem.xtaxAirports?.ti.filter((ti: any) => {
            
        return ti.label === process.env.NEXT_PUBLIC_AIRPORT_REFERENCE
      }).length > 0;

      if (lblTitle == " " || lblTitle == "") return;
      if (!isVisible) return;

      return (
        
        <SwiperSlide key={alertItemId} className="bg-[#000000] text-[#FFFFFF] select-none pb-2">
          <div className="flex gap-3 w-full px-6 my-3 max-w-cont-1 mx-auto">
            <div>
              <CustomIcon iconName="info" className={`fill-[#FFFFFF] w-5 ${flgSkillIcon}`} />
            </div>

            <div className="flex flex-col w-full">

              <div className="flex gap-3 items-center justify-start w-[90%] desk:w-[60%]">
                <p className="text-[#FFFFFF] font-bold text-base overflow-hidden desk:truncate ">
                  {lblTitle}
                </p>
                <div className={`${txbSubtitle ? 'block' : 'hidden'} ${isMobile && 'absolute top-1 right-2 p-4'}`} onClick={openClose}>
                  <CustomIcon iconName="arrow_alert"  color="white" className={`fill-[#FFFFFF] cursor-pointer transition-transform origin-center duration-300 ${isOpen ? 'rotate-90' : 'rotate-0'} w-2`} />
                </div>
              </div>

              <div className={`text-[#FFFFFF] font-normal text-base transition-all duration-300 ${isOpen ? 'mt-5 my-7 h-auto' : 'h-0'} w-[90%]`}>
                <p className={`${txbSubtitle ? 'block' : 'hidden'} truncate whitespace-normal overflow-hidden h-[80%]`}>
                  {txbSubtitle}{txbSubtitle?.length > 1580 ? '...' : ''}
                </p>
                <a className={`${isOpen ? 'block' : 'hidden'} pt-4 w-fit underline underline-offset-2 hover:no-underline h-[20%]`} href={getPathByLink(alertItem, lang)} target={flgTarget}>
                  {lblLabelCta}
                </a>
              </div>

            </div>
          </div>
          <div className={`border-4 ${enuRowColor} absolute bottom-0 w-full`}></div>
        </SwiperSlide>
      );
    })
  }, [moduleData, lang, isOpen])

  return (
    <div className="box-alert">
      <Swiper
        ref={swiperRef}
        spaceBetween={0}
        slidesPerView={1}
        onRealIndexChange={newInstance => setActiveIndex(newInstance.realIndex)}
        loop={true}
        autoplay={{ delay: alertAutoplayTime, disableOnInteraction: true }}
        onSlideChange={() => setIsOpen(false)}
        modules={[Navigation, Autoplay]}
        className="flex sticky top-0 z-50"
      >
        {slideList}

        {moduleData && moduleData?.data.length && moduleData?.data.length !== 0 && slideList.filter(item => {  return item !== undefined}).length > 1 ? <SliderController sliderLength={slideList.filter(item => {  return item !== undefined}).length} lang={lang} /> : null}

      </Swiper >
    </div>
  );
};


const SliderController: React.FC<SliderControllerProps> = ({ sliderLength = 0, lang = 'it' }: SliderControllerProps) => {

  const swiperRef = useSwiper();

  return (
    <div className="hidden desk:flex absolute text-[#FFFFFF] right-0 pr-9 pl-9 py-3 z-10 w-fit h-fit gap-3 items-center select-none bg-black">
      <div onClick={() => swiperRef.slidePrev()}>
        <CustomIcon iconName="arrow_alert" color="white" className={`fill-white h-4 rotate-180 cursor-pointer`} />
      </div>
      <p className="font-bold">{swiperRef.realIndex + 1} {lang === "it" ? "di" : "of"} {sliderLength}</p>
      <div onClick={() => swiperRef.slideNext()}>
        <CustomIcon iconName="arrow_alert" color="white" className={`fill-white h-4 cursor-pointer`} />
      </div>
    </div>
  )
}

export default Alert;
