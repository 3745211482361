import { IModuleDataDTO } from "@/interfaces/IPageDTO";
import { ParkingList, VideoPlayer } from "../index";

import Slider from "../Slider/Slider";
import CTAList from "../CTAList/CTAList";
import ChipList from "../ChipList/ChipList";
import Paragraph from "../Paragraph/Paragraph";
import LaunchList from "../LaunchList/LaunchList";
import VisoreInfo from "../VisoreInfo/VisoreInfo";
import EventLaunch from "../EventLaunch/EventLaunch";
import InfoGraphic from "../InfoGraphic/InfoGraphic";
import SearchPanel from "../SearchPanel/SearchPanel";
import VisoreLancio from "../VisoreLancio/VisoreLancio";
import BannerViewer from "../BannerViewer/BannerViewer";
import InfoLaunches from "../InfoLaunches/InfoLaunches";
import VisoreCarousel from "../VisoreCarousel/VisoreCarousel";
import ModuleFourWrapper from "../ModuleFourWrapper/ModuleFourWrapper";
import ModuleThreeWrapper from "../ModuleThreeWrapper/ModuleThreeWrapper";
import ModuleTwelveWrapper from "../ModuleTwelveWrapper/ModuleTwelveWrapper";
import ListParagraph from "../ListParagraph/ListParagraph";
import Stepper from "../Stepper/Stepper";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import dynamic from "next/dynamic";

interface IModuleMatcherProps {
  moduleData: IModuleDataDTO[],
  excludes?: string[],
  lang: string
}

const ModuleMatcher2: React.FC<IModuleMatcherProps> = ({ moduleData, excludes, lang } : IModuleMatcherProps) => {

  const renderModule = (

    moduleType: string,
    moduleId: number,
    moduleData: any

  ): JSX.Element | null => {
    
    switch (moduleType) {
      
      case "m01":
        return <Slider language={lang} key={moduleId} data={moduleData} />;
      case "m02":
        return <SearchPanel language={lang} key={moduleId} targetUrl="" data={moduleData} />;
      case "m03":
        return <ModuleThreeWrapper language={lang} key={moduleId} targetUrl="" data={moduleData} />;
      case "m04": 
        return <ModuleFourWrapper language={lang} key={moduleId} data={moduleData} />;
      case "m05": 
        return <LaunchList language={lang} key={moduleId} data={moduleData} targetUrl={""} />
      case "m06": 
        return <VisoreCarousel language={lang} key={moduleId} data={moduleData} hidden={false}/>;
      case "m07": 
        return <BannerViewer language={lang} key={moduleId} data={moduleData} />;
      case "m08": 
        return <EventLaunch language={lang} key={moduleId} data={moduleData} hidden={false}/>;
      case "m09": 
        return <CTAList language={lang} key={moduleId} data={moduleData} />;
      case "m10": 
        return <ChipList language={lang} key={moduleId} data={moduleData} hidden={false}/>;
      case "m11":
        return <Paragraph language={lang} key={moduleId} data={moduleData} />;
      case "m12": 
        return <ModuleTwelveWrapper language={lang} key={moduleId} data={moduleData} hidden={false}/>;
      case "m13": 
        return <InfoGraphic language={lang} key={moduleId} data={moduleData} />;
      case "m14": 
        return <Stepper language={lang} key={moduleId} data={moduleData} />;
      case "m15": 
        return <ParkingList language={lang} key={moduleId} data={moduleData} />;
      case "m16": 
        return <InfoLaunches language={lang} key={moduleId} data={moduleData} />;
      case "m17": 
        return <VisoreInfo language={lang} key={moduleId} data={moduleData} hidden={false}/>;
      case "m18":
        return <VisoreLancio language={lang} key={moduleId} data={moduleData} hidden={false}/>;
      case "m19":
        return <VideoPlayer language={lang} key={moduleId} data={moduleData} />
      case "m20":
        return <ListParagraph language={lang} key={moduleId} data={moduleData} />;
      default:
        return null;
    }
  };

  return (
    <>
      {moduleData?.filter(item => !excludes?.includes(item.linkedcontenttype)).map((moduleItem) => {

        return (
          <ErrorBoundary key={moduleItem.id} customMessage={"Module of content type: " + moduleItem.linkedcontenttype + ' is broken'}>
            {renderModule(
              moduleItem.linkedcontenttype,
              moduleItem.id,
              moduleItem.moduleData
            )}
          </ErrorBoundary>
        )
      })}
    </>
  )
}

export default ModuleMatcher2;
