import { IParagraphListApiResponse } from "../../interfaces/IParagraphList";
import { useIsMobile } from "../../hooks";
import { useTranslation } from "react-i18next";

import Layout from "./Layout";

interface IParagraphProps {
    data: IParagraphListApiResponse,
    language: string
}

const ListParagraph: React.FC<IParagraphProps> = ({ data, language }: IParagraphProps) => {

    const moduleData = data;
    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;
    const [isMobile] = useIsMobile();

    let resultString = '';
    let layoutType = moduleData?.data.enuTemplate;

    if (isMobile || layoutType?.toLowerCase().includes('una colonna')) {
        resultString = 'flex-col justify-center';
    }

    if (!isMobile && layoutType?.toLowerCase().includes('due colonne')) {
        resultString = 'flex justify-between grid grid-cols-2';
    };

    return (
        <div className={`${resultString} flex py-8 px-6 desk:py-12 max-w-cont-2 mx-auto w-full gap-8 gap-y-[70px]`}>
            {moduleData?.data?.lstParagraphs?.map((paragraph, index) => {

                return (
                    <div className={`flex-1`} key={index}>
                        <div className='text-2xl desk:text-2xl text-black font-bold mb-3 font-secondary'>{paragraph.lblTitle[lang]}</div>
                        <Layout isMobile={isMobile} paragraph={paragraph} language={lang}></Layout>
                    </div>
                )
            })}
        </div>
    );
}

export default ListParagraph;
