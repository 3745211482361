import { ReactNode } from "react";

interface ILayoutProps {
    isMobile: boolean,
    isTracking: boolean,
    content: ReactNode,
    isActive: boolean,
    step: number,
    bgColor: string
}

const StepperDot: React.FC<ILayoutProps> = ({ isActive, content, step, bgColor, isMobile, isTracking }: ILayoutProps) => {

    return (
        <div className={`flex gap-5 mb-2.5 desk:ml-14 ${step >= 3 ? "" : isMobile ? "max-h-18" : ""}`}>
            {
                step >= 3 && !isTracking ?
                <div className="relative flex after:content-[''] after:h-[calc(100%_-_90px)] after:w-0 after:absolute after:left-2/4 after:top-[90px]">
                    {
                        isActive ?  <div className={`flex items-center justify-center text-2xl font-semibold ${isMobile ? "h-14 w-14 mt-2" : "w-20 h-20"} rounded-3xl bg-${bgColor} text-white font-secondary`}>
                                        {step < 10 ? 0 : ""}{step}
                                    </div> 
                        :   (<div className={`flex items-center justify-center text-2xl font-bold ${isMobile ? "h-14 w-14 mt-2" : "w-20 h-20"} rounded-3xl bg-[#C4C4C4] text-white font-secondary`}>
                                {step < 10 ? 0 : ""}{step}
                            </div>)
                    }
                </div>
                : <div className="relative flex after:content-[''] after:h-[calc(100%_-_90px)] after:w-0 after:absolute after:border-l-4 after:border-l-[#c4c4c4] after:border-dotted after:left-2/4 after:top-[90px]">
                    {
                        isActive ?  (<div className={`flex items-center justify-center text-2xl font-semibold ${isMobile ? "h-14 w-14 mt-2" : "w-20 h-20"} rounded-3xl bg-${bgColor} text-white font-secondary`}>
                                        {step < 10 ? 0 : ""}{step}
                                    </div>)
                        : (<div className={`flex items-center justify-center text-2xl font-bold ${isMobile ? "h-14 w-14 mt-2" : "w-20 h-20"} rounded-3xl bg-[#C4C4C4] text-white font-secondary`}>
                            {step < 10 ? 0 : ""}{step}
                        </div>)
                    }
                </div>
            }
            <div className={`px-0 py-6`}>
                {content}
            </div>
            
        </div>
    )
}

export default StepperDot;
