import { useAuth } from "@/context/AuthContext";
import { t } from "i18next";
import { useEffect, useState } from "react";
import IMovement from "@/interfaces/IMovement";

interface ILoyaltyTableProps {
	lang: string,
    fetchMovements: any,
    itemsList: any
}

//TODO: Filtro
const LoyaltyTable: React.FC<ILoyaltyTableProps> = ({ lang, fetchMovements, itemsList }) => {

    const auth = useAuth();

    useEffect(() => {

        fetchMovements();
    }, [auth])

    return (
        itemsList.length > 0 ?
            <div className="flex-col" id="prize-history">
                <div className="text-lg text-black font-bold mb-7">{t('loyalty.pointsOverview')}</div>
                {itemsList ?
                    <div className="customTable">
                        <div className="flex head-row">
                            <div className="w-2/6 desk:w-1/6 head text-lightblue font-bold text-lg">{t('loyalty.date')}</div>
                            <div className="w-3/6 desk:w-4/6 head text-lightblue font-bold text-lg">{t('loyalty.detail')}</div>
                            <div className="w-1/6 desk:w-1/6 head text-lightblue font-bold text-lg">{t('loyalty.points')}</div>
                        </div>
                        {itemsList.map((movementItem: any, index: number) => (
                            <div className="row flex py-[20px] border-b border-darkgrey" key={index}>
                                <div className="w-2/6 desk:w-1/6 text-lg">{new Date(movementItem?.movementDate).toLocaleDateString(lang)}</div>
                                <div className="w-3/6 desk:w-4/6 flex-col">
                                    <div className="text-lg">
                                        {movementItem?.movementDescr}
                                    </div>
                                    <div className="flex text-sm">
                                        {movementItem?.movementTypeDescr}
                                    </div>
                                </div>
                                <div className="w-1/6 desk:w-1/6 text-lg">{Number(movementItem?.movementQuantity).toLocaleString(lang)} p</div>
                            </div>
                        ))}
                    </div>
                    : <></>}
            </div>
            : null);
}

export default LoyaltyTable;