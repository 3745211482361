const getPathByLink = (obj: any, lang: string) => {

    if (!obj) return '';
    /* if(!obj?.hasOwnProperty('grpLinkTypeTravel') && !obj?.hasOwnProperty('grpLinkType')) return ''; */

    if (obj?.hasOwnProperty('grpLinkTypeIcons')) {
        switch (obj?.grpLinkTypeIcons) {
            case "grpLinkIcons":
                return obj?.lblLinkIcons[lang]
            case "grpGenericPageIcons":
                return obj?.xconGenericPageIcons?.lblUrlPath[lang]
            case "grpNews":
                return obj?.xconNews?.lblUrlPath[lang]
        }
        return ""
    }

    if (obj?.hasOwnProperty('grpLinkTypeSubFooter')) {
        switch (obj?.grpLinkTypeSubFooter) {
            case "grpLinkSiteMapSubFooter":
                return obj?.lblLinkSiteMapSubFooter[lang]
            case "grpGenericPageSubFooter":
                return obj?.xconGenericPageSubFooter?.lblUrlPath[lang]
            case "grpNewsSubFooter":
                return obj?.xconNewsSubFooter?.lblUrlPath[lang]
        }
        return ""
    }

    if (obj?.hasOwnProperty('lblLinkCtaService') && obj?.lblLinkCtaService[lang]) {
        return obj?.lblLinkCtaService[lang]
    }

    if (obj?.hasOwnProperty('grpLinkType')) {
        switch (obj.grpLinkType) {
            case "grpLinkLibero":
            case "grpLink":
                if (!obj?.hasOwnProperty('lblLink')) return '';
                else if (obj?.hasOwnProperty('lblLinkBox') && obj?.lblLinkBox) return obj?.lblLinkBox[lang]
                return obj?.lblLink[lang] || ''
            case "grpGenericPage":
                if (!obj.hasOwnProperty('xconGenericPage')) return '';
                return obj?.xconGenericPage?.lblUrlPath ? obj?.xconGenericPage?.lblUrlPath[lang] : ''
            case "grpNews":
                if (!obj.hasOwnProperty('xconNews')) return '';
                return obj?.xconNews?.lblUrlPath[lang] || ''
        }
    }

    if (obj?.hasOwnProperty('grpLink')) {
        switch (obj?.grpLink) {
            case "grpLinkLibero":
            case "grpLink":
                if (!obj?.hasOwnProperty('lblLink')) return '';
                return obj?.lblLink[lang] || '';
            default:
                if (!obj.hasOwnProperty('xconGenericPage')) return '';
                return obj?.xconGenericPage?.lblUrlPath && obj.xconGenericPage?.lblUrlPath[lang] ? obj.xconGenericPage?.lblUrlPath[lang] : ''
        }
    }

    if (obj?.hasOwnProperty('lblLinkCtaEcommerce') && obj?.lblLinkCtaEcommerce[lang]) {
        return obj?.lblLinkCtaEcommerce[lang]
    }

    if (obj?.hasOwnProperty('lblLinkBox') && obj?.lblLinkBox[lang]) {
        return obj?.lblLinkBox[lang]
    }

    if (obj?.hasOwnProperty('grpLinkTypeTravel')) {
        switch (obj.grpLinkTypeTravel) {
            case "grpGenericPageTravel":
                if (!obj?.hasOwnProperty('xconGenericPageTravel')) return '';
                return obj?.xconGenericPageTravel?.lblUrlPath[lang]
        }
    }

    if (obj?.hasOwnProperty('lblUrlPath')) {
        return obj?.lblUrlPath[lang] || ''
    }

    if (obj?.hasOwnProperty('grpLinkTypeBoxLaunch')) {

        if (obj?.lblLinkBoxLaunch[lang]) return obj?.lblLinkBoxLaunch[lang]
        if (obj?.xconPopupBoxLaunch?.id) return ''
        if (obj?.xconGenericPageBoxLaunch?.id) return obj?.xconGenericPageBoxLaunch?.lblUrlPath[lang]
        if (obj?.xconNewsBoxLaunch?.id) return ''
        if (obj?.xconGenericPage?.id) return ''

        return ''
    }

    // return obj?.lblLink[lang] || ''
    return ''
}

export default getPathByLink;