type UnitTime = 'seconds' | 'minutes' | 'hours' | 'days' | 'months' | 'years';

const timeToMs = (num: number = 1, unitTime: UnitTime = 'seconds', reverse: boolean = false): number => {
  const unitValues: { [key in UnitTime]: number } = {
    'seconds': 1000,
    'minutes': 60 * 1000,
    'hours': 60 * 60 * 1000,
    'days': 24 * 60 * 60 * 1000,
    'months': 30.44 * 24 * 60 * 60 * 1000,
    'years': 365 * 24 * 60 * 60 * 1000
  };

  if (reverse) {
    return num / unitValues[unitTime];
  } else {
    return num * unitValues[unitTime];
  }
}

export default timeToMs;
