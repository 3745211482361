import { useEffect, useState } from "react";


const useIsMobile = () => {

    const [isMobile, setIsMobile] = useState(true);
    const [isTablet, setIsTablet] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);

    const handleResize = () => {
        setIsDesktop(window.innerWidth >= 1024)
        setIsTablet(window.innerWidth >= 768 && window.innerWidth <= 1024)
        setIsMobile(window.innerWidth < 768)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)


        return () => window.addEventListener('resize', handleResize)
    }, [isMobile, isTablet, isDesktop])

    useEffect(() => {
        handleResize()
    }, [])

    return [isMobile, isTablet, isDesktop];
}

export default useIsMobile;