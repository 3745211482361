import { ITaxonomyDTO } from "../../interfaces/IPageDTO"
import { LanguageAtom } from "../../atoms";
import { useRecoilValue } from "recoil";
import { useEffect, useMemo, useState } from "react";
import { NEWS_PAGE_URLS } from "../../config/newsPages"
import { useCategory, useDataCard } from "../../hooks";
import { INewsApiResponse, INewsManualList, INewsThemeDTO } from "../../interfaces/IAccordionListDTO"
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

const Chip = dynamic(() => import("../ChipList/Chip"), { ssr: false });
import BoxNews from "./BoxNews";
import Button from "../Button/Button";
import TitleSubtitle from "./TitleSubtitle";
import dynamic from "next/dynamic";

interface INewsListProps {
    lang: string,
    newsData: {
        grpNewsType: string,
        grpListType: string,
        xtaxNewsCategory: ITaxonomyDTO,
        lstNews: INewsManualList[],
        lstNewsThemesList: INewsThemeDTO[],
        lblTitle: { [key: string]: string },
        lblSubtitle: { [key: string]: string },
    }
}


const NewsList: React.FC<INewsListProps> = ({ lang, newsData }: INewsListProps) => {
    const router = useRouter();
    const { t } = useTranslation();

    const switchCase = () => {
        switch (newsData?.grpListType) {
            case "grpNewsThemesList":
                return <ThemeNews newsData={newsData} />
            case "grpNews":
                if (newsData?.grpNewsType === "grpNewsAutomatic")
                    return (<>
                        <AutomaticNews newsData={newsData} />
                        <div className="flex justify-center desk:mt-16 mt-10">
                            <Button
                                text={t('news.seeAllTheNews')}
                                onClick={() => router.push(NEWS_PAGE_URLS[lang])} />
                        </div>
                    </>)
                else
                    return (<>
                        <ManualNews newsData={newsData} />
                        <div className="flex justify-center desk:mt-16 mt-10">
                            <Button
                                text={t('news.seeAllTheNews')}
                                onClick={() => router.push(NEWS_PAGE_URLS[lang])} />
                        </div>
                    </>)
        }
    }

    return (
        <div>
            <div> <TitleSubtitle
                title={newsData?.lblTitle && newsData?.lblTitle[lang] ? newsData?.lblTitle[lang] : ""}
                subTitle={newsData?.lblSubtitle && newsData?.lblSubtitle[lang] ? newsData?.lblSubtitle[lang] : ""} /> </div>
            {switchCase()}
        </div>
    )
}

const AutomaticNews: React.FC<Pick<INewsListProps, 'newsData'>> = ({ newsData }) => {

    const rowData = useCategory<INewsApiResponse>('news', 'Informazione')
    // newsData?.xtaxNewsCategory?.ti[0]?.label)
    const lang = useRecoilValue(LanguageAtom);

    const data = useMemo(() => {
        return rowData?.data?.map((el: any) => { return { ...el, id: el.metainfo.id, title: el?.lblTitleNews, subTitle: el?.lblSubtitleNews } })
    }, [rowData])

    return (
        <>
            {newsData?.lstNewsThemesList ?
                <div>
                    {newsData?.lstNewsThemesList?.map((chip) => (
                        <Chip
                            key={chip?.id}
                            text={chip?.lblNewsThemeTag[lang]}
                            bgColor="lightblue"
                            txtColor="white"
                            hrefPath=""
                            onClick={() => { }} />))
                    }
                </div >
                : null}
            <BoxNews
                data={data || []}
                lang={lang} />
        </>
    )
}

const ManualNews: React.FC<Pick<INewsListProps, 'newsData'>> = ({ newsData }) => {

    const cardList = useMemo(() => {
        return newsData?.lstNews?.map((news) => {
            return news.xconNews.targeturl
        })
    }, [newsData])

    const rowData = useDataCard<INewsApiResponse[]>({ urls: cardList })
    const lang = useRecoilValue(LanguageAtom);

    const data = useMemo(() => {
        return rowData?.map((el: any) => { return { ...el?.data, id: el?.metainfo?.id, title: el?.data?.lblTitleNews, subTitle: el?.data?.lblSubtitleNews } })
    }, [rowData])

    return (
        <BoxNews
            data={data || []}
            lang={lang} />
    )
}

const ThemeNews: React.FC<Pick<INewsListProps, 'newsData'>> = ({ newsData }) => {

    const { i18n } = useTranslation();
    const lang = i18n.language;

    const [selectedId, setSelectedId] = useState(0)

    const categoryArray: [INewsApiResponse, ((arg: string) => void)] = useCategory<[INewsApiResponse, ((arg: string) => void)]>('news', newsData?.lstNewsThemesList[selectedId]?.xtaxNewsCategoryThemes?.ti ? newsData?.lstNewsThemesList[selectedId]?.xtaxNewsCategoryThemes?.ti[0]?.label : "TUTTE")
    const data = useMemo(() => {
        return categoryArray[0]?.data?.map((el: any) => { return { ...el, id: el.metainfo.id, title: el?.lblTitleNews, subTitle: el?.lblSubtitleNews } })
    }, [categoryArray[0], selectedId])

    const seleziona = (id: any, category: string) => {
        setSelectedId(id);
        categoryArray[1](category)
    }

    useEffect(() => {
        if(newsData.lstNewsThemesList && newsData.lstNewsThemesList[0])
            seleziona(newsData?.lstNewsThemesList[0]?.id!, "TUTTE")
    },[])

    return (
        <>
            {newsData?.lstNewsThemesList ?
                <div className="flex desk:gap-2 gap-y-6 gap-x-1 desk:mt-5 mt-2 mb-8 flex-wrap">
                    {newsData?.lstNewsThemesList?.map((chip) => (
                        <div className="">
                            <Chip
                                className="!no-underline cursor-pointer"
                                key={chip?.id}
                                text={chip?.lblNewsThemeTag[lang].toUpperCase()}
                                bgColor={selectedId === chip?.id ? "bg-lightblue" : "bg-lightgrey"}
                                txtColor={selectedId === chip?.id ? "!text-white" : "text-lightblue"}
                                hrefPath=""
                                onClick={() => seleziona(chip?.id, chip?.xtaxNewsCategoryThemes?.ti ? chip?.xtaxNewsCategoryThemes?.ti[0]?.label : "TUTTE")} />
                        </div>))
                    }
                </div >
                : null}
            <BoxNews
                data={data || []}
                lang={lang} />
        </>
    )
}

export default NewsList;
