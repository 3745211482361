const AVAILABLE_CONTINENTS_IT = [
    {
        label: 'Africa',
        id: 1,
        airports: ['mxp']
    },
    {
        label: 'America',
        id: 2,
        airports: ['mxp']
    },
    {
        label: "Asia",
        id: 3,
        airports: ['mxp']
    },
    {
        label: "Europa",
        id: 4,
        airports: ['mxp', 'lin']
    },
]

export default AVAILABLE_CONTINENTS_IT;