import { LanguageAtom } from "@/atoms";
import { useModule } from "@/hooks";
import { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

interface IListConverterProps {
    item: any;
    title: string;
    name: string;
    isMobile: boolean;
}

const ListConverter: React.FC<IListConverterProps> = ({ item, name, title, isMobile }: IListConverterProps) => {

    const { i18n } = useTranslation();
    const lang = i18n.language;
    const targetUrl = item.xconListForm?.targeturl
    const list = useModule<any>(targetUrl);
    const [value, setValue] = useState("");
    const [selected, setSelected] = useState(false);
    // const data : Array<any> = [  ];
    // let firstValue = "";
    const { t } = useTranslation();
    const inputRef = useRef<any>(null);

    const handleValidity = (e: any) =>{
        if(e.target.validity.valueMissing)
            e.target.setCustomValidity(lang === "it" ? "Campo necessario" : "Mandatory field");
        else
            e.preventDefault();
    }

    /*NOTE: Parte commentata è per ordinare le liste in ordine alfabetico, però bisognerebbe cambiare i valori di conseguenza sennò problemi con il CRM */

    switch (name) {
        case 'terminal': return <select ref={inputRef} className={`p-3 rounded-xl w-full ${isMobile ? "text-xs" : "text-sm"} mt-2 mb-2 `} style={ !selected ? { color: "#999999"} : { color: "black"}} id={name} name="terminal" required form="helpForm" onChange={() => (setSelected(true))} onInvalid={e => handleValidity(e)}>
                                    <option id={"0"} value="" disabled selected >{t('form.airport') + '*'}</option>
                                    <option id={"T0"} value={"T0"} placeholder="Linate" style={{ color: "black"}} >Linate</option> 
                                    <option id={"T1"} value={"T1"} placeholder="Malpensa T1" style={{ color: "black"}}>Malpensa T1</option>  
                                    <option id={"T2"} value={"T2"} placeholder="Malpensa T2" style={{ color: "black"}}>Malpensa T2</option>       
                                </select>
        case 'sesso': return <div>
                                { list?.data?.lstAction.map((item : any) => {
                                    return  <>
                                                <input ref={inputRef} onInvalid={(e : any) => handleValidity(e)} className="align-center ml-4 mt-4 translate-y-0.5" name={name} value={value} type="radio" hidden={item?.lblName[lang] === "Sesso" ? true : false} checked={item.lblName[lang] === value} onClick={() => setValue(item.lblName[lang])}></input> 
                                                <label className={`ml-2 ${isMobile ? "text-xs" : "text-sm"}`}>{item.lblName[lang]}</label>
                                            </>
                                })}
                             </div>
        case 'localita': return <select className={`p-3 rounded-xl ${isMobile ? "w-full text-xs" : "max-w-full text-sm"} mt-2 mb-2`} name={name} onInvalid={(e : any) => handleValidity(e)}>
                                    {/* { list?.data?.lstAction.map((item : any, index: number) => {
                                        if(index === 0){
                                            firstValue = item.lblName[lang];
                                        } else {
                                            data.push(item.lblName[lang]);
                                            data.sort();
                                        }
                                    })}
                                    <option value={0}>{firstValue}</option> */}
                                    { list?.data?.lstAction.map((item : any, index: any) => {
                                        return <option key={index} value={item.lblValue}>{item.lblName[lang]}</option>
                                    })}
                                </select>
        default: return <select className={`p-3 rounded-xl ${isMobile ? "w-full text-xs" : "max-w-full text-sm"} mt-2 mb-2`} name={name} onInvalid={(e : any) => handleValidity(e)}>
                            {/* { list?.data?.lstAction.map((item : any, index: number) => {
                                if(index === 0){
                                    firstValue = item.lblName[lang];
                                } else {
                                    data.push(item.lblName[lang]);
                                    data.sort();
                                }
                            })}
                            <option value={0}>{firstValue}</option> */}
                            { list?.data?.lstAction.map((item : any, index: any) => {
                                // if(data.length > 1)
                                return <option key={index} value={index}>{item.lblName[lang]}</option>
                            })}
                        </select>
    }
}


export default ListConverter;
