import { IStepperItem, IlstLaunch } from "@/interfaces/IStepper";
import { IlstIcon, IlstCTA } from "@/interfaces/IStepper";
import CustomIcon from "@/components/CustomIcon/CustomIcon";
import { useTranslation } from "react-i18next";
import { getPathByLink } from "../../../utils";

interface IStepperProps {
    isMobile: boolean,
    content: IStepperItem,
    language: string
}

const EditorialOnly: React.FC<IStepperProps> = ({ isMobile, content, language }: IStepperProps) => {

    const lang = language;

    if (content.lstCTA.length > 0) {

        return (
            <>
                <h1 className="min-h-[66px] text-lg desk:text-2xl text-base/1 text-[#333333] font-bold">{content.lblTitle[lang]}</h1>
                <h1 className="mb-2">{content.txbSubtitle[lang]}</h1>

                {content.lstCTA.map((list: IlstCTA, index) => {

                    const link = getPathByLink(list, lang)
                    return (
                        <a key={index} href={link} target={list?.flgTarget ? "_blank" : "_self"} className={`flex justify-center w-fit h-37 cursor-pointer font-bold bg-[#F2F2F2] rounded-2xl p-4 text-[#6ABACE] uppercase mb-3 ${!link ? "pointer-events-none cursor-default" : ""}`}>
                            {list.lblCta[lang]}
                            <CustomIcon color="" iconName="arrow" className={"fill-[#6ABACE] w-2 ml-2"} />
                        </a>
                    );

                })}
            </>
        )

    } else if (content.lstIcons.length > 0) {

        return (
            <>
                <h1 className="min-h-[66px] text-lg desk:text-2xl text-base/1 text-[#333333] font-bold">{content.lblTitle[lang]}</h1>
                <h1 className="mb-2">{content.txbSubtitle[lang]}</h1>

                <div className="flex items-center gap-3">
                    {content.lstIcons.map((icon: IlstIcon, index) => {
                        const link = getPathByLink(icon, lang)
                        return (
                            <div key={index} className="flex justify-center">
                                <a key={index} href={link} target={icon?.flgTargetIcons ? "_blank" : "_self"} className={!link ? "pointer-events-none cursor-default" : ""}>
                                    <img src={icon?.upiLogoCtaDesktop?.mediaserverpath + "/" + icon?.upiLogoCtaDesktop?.resourcename} className="h-[55px] w-[55px] desk:h-[70px] desk:w-[70px] rounded-3xl mx-auto"></img>
                                    <h1 className={`flex justify-center desk:mt-3 mt-2 ${link ? "cursor-pointer" : ""} `}>{icon.lblLabelCta[lang]}</h1>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </>
        )

    } else if (content.lstLaunch.length > 0) {

        return (
            <>
                <h1 className="min-h-[66px] text-lg desk:text-2xl text-base/1 text-[#333333] font-bold">{content.lblTitle[lang]}</h1>
                <h1 className="mb-2">{content.txbSubtitle[lang]}</h1>

                {content.lstLaunch.map((icon: IlstLaunch, index) => {

                    return (
                        <div key={index}>
                            <div className="flex mb-2 desk:gap-3 gap-5">
                                <div className="flex justify-center desk:items-center items-start">
                                    <img src={icon?.upiImageLaunchDesktop[lang].mediaserverpath + "/" + icon?.upiImageLaunchDesktop[lang].resourcename} className="min-w-[50px]"></img>
                                </div>
                                <div className="flex flex-col desk:ml-4 desk:mt-1">
                                    <h1 className="font-bold text-[#333333]">{icon.lblTitleLaunch[lang]}</h1>
                                    <span className="text-[#333333]">{icon.txbSubtitleLaunch[lang]}</span>
                                </div>
                            </div>
                        </div>
                    )

                })}
            </>
        )
    } else {
        return (
            <>
                <h1 className="min-h-[66px] text-lg desk:text-2xl text-base/1 text-[#333333] font-bold">{content.lblTitle[lang]}</h1>
            </>
        )
    }
}

export default EditorialOnly;
