import { IShoppingCardProps } from "./ICard";

import CustomIcon from "../CustomIcon/CustomIcon";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { CloseIcon } from "../CloseIcon/CloseIcon";


const ShoppingListCard : React.FC<IShoppingCardProps> = ({language, id, title, titleColor = 'text-black', subtitleColor = 'text-black', imageUrl, subtitle, ctaText, onClickPath, hint, onClick }: IShoppingCardProps) => {
    
    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;
    const [showMap, setShowMap] = useState({value: false, shopId: 0});
    const [logo, setLogo] = useState<string>()

    async function getShops() {
        await fetch(`${process.env.NEXT_PUBLIC_IMAGE_PATH}/afm/s/252/155${imageUrl!.replace('/Assets/afm', '')}`, 
        {headers: {'keyId' : process.env.NEXT_PUBLIC_IMAGE_KEY!}})
        .then(response => {
            return response.blob()
        }).then(blob => {
            setLogo(URL.createObjectURL(blob));
        }).catch(
            error => console.error('[ShoppingListCard] image error -> ', error)
        );
    }

    useEffect(()=>{
        getShops()
    },[])

    return (
        <>
            <div className="absolute" hidden={!showMap.value}>
                <div className="w-screen h-screen" style={{ padding: 30, position: "fixed", top:0, left:0, bottom:0, right:0, border:"none", overflow: "hidden", zIndex: 999999}}>                 
                    <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                        background: "black",
                        opacity: 0.1
                    }}
                    />

                    <div className="cursor-pointer relative z-[52]" onClick={() => setShowMap({value: false, shopId: 0})}>
                        <button
                        className="bg-black p-0 rounded-3xl cursor-pointer w-5 absolute top-0 right-0"
                        >
                            <CloseIcon onClick={() => setShowMap({value: false, shopId: 0})} />
                        </button>
                    </div>
                    { showMap.value && showMap.shopId === id ?
                        <iframe
                            sandbox="allow-scripts" 
                            width="100%"
                            height="100%"
                            src={`/webmaps/neoMap.jsp?lang=${lang}&target=${id}`}
                        />
                        : <></>
                    }

                </div>
            </div>
            <div className="rounded-3xl bg-[#F2F2F2] relative h-fit desk:w-[16rem]  mob:w-60 mb-4">
                <div style={{ backgroundImage: `url(${logo})` , backgroundSize: 260}} className="bg-no-repeat bg-center bg-cover h-40 bg-white rounded-t-3xl shadow-lg"></div>

                <div className="h-36 p-3 pb-7 desk:pb-12 bg-lightgrey shadow-lg rounded-b-2xl">
                    <div className={`flex flex-col gap-4`}>
                        <div>
                            <div className="flex items-center -mt-2 cursor-pointer" onClick={() => setShowMap({value: true, shopId: id})}
                            >
                                <CustomIcon iconName="marker" className="w-5" />
                                <a className="underline underline-offset-2 text-sm">{hint}</a>
                            </div>
                            <h2 className={`font-bold text-lg desk:text-xl ${titleColor} -mt-2`}>{title}</h2>
                        </div>
                        <div className="flex gap-2 -mt-2">
                            <a className={`font-bold text-sm ${subtitleColor}`} href={onClickPath}>{subtitle}</a>
                            <CustomIcon iconName="arrow" className="w-2" />
                        </div>
                    </div>
                    <div className="cursor-pointer" onClick={e => onClick(e)} >
                        {ctaText ? <a className={`absolute bottom-0 right-0 bg-[#6ABACE] text-[#FFFFFF] py-3 px-6 desk:px-2 rounded-tl-3xl rounded-br-3xl text-sm font-bold`}>{ctaText}</a> : null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShoppingListCard