import { useRecoilValue } from "recoil";
import GlobalSearchBar from "../GlobalSearchBar/GlobalSearchBar";
import GlobalSearchResultList from "../GlobalSearchResultList/GlobalSearchResultList";
import { GlobalSearchResultAtom } from "@/atoms";
import { useIsMobile } from "../../hooks";

const SearchMenu: React.FC = () => {

    const [isMobile] = useIsMobile()

    return (
        <div className={`${isMobile? "" : "z-40 absolute top-[4rem] max-h-[550px] bg-white shadow-2xl overflow-y-auto w-full"}`}>
            <div className={`${isMobile ? "" : "mx-auto w-[1067px] py-[86px]"} main-content flex flex-col`}>
                <div className="search-bar ">
                    <GlobalSearchBar />
                </div>
                <GlobalSearchResultList />
                <div className="popular-search-list"></div>
            </div>
        </div>
    )
}

export default SearchMenu;