import configColor from "./configColor";
import getPathByLink from "./getPathByLink";
import gridCol from "./gridCol";
import isHexColorCode from "./isHexColorCode";
import navigateTo from "./navigateTo";
import formatTime from "./formatTime";
import formatDate from "./formatDate";
import addDays from './addDays'
import timeToMs from "./timeToMs";


export {
    configColor,
    getPathByLink,
    gridCol,
    isHexColorCode,
    navigateTo,
    formatTime,
    formatDate,
    addDays,
    timeToMs
};
