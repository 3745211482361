export const FLIGHT_DETAIL_PAGE_URLS: { [movementType: string]: { [key: string]: string } } = {
    //Directions A for Arrivals, D for Departures
    'A': {
        'it': '/it/voli/arrivi/volo-in-arrivo',
        'en': '/en/flights/arrivals/flight-detail'
    },
    'D': {
        'it': '/it/voli/partenze/volo-in-partenza',
        'en': '/en/flights/departures/flight-detail'
    }
};

export const FLIGHT_LIST_PAGE_URLS: { [movementType: string]: { [key: string]: string } } = {
    'A': {
        'it': '/it/voli/arrivi',
        'en': '/en/flights/arrivals'
    },
    'D': {
        'it': '/it/voli/partenze',
        'en': '/en/flights/departures'
    }
}

export enum MovementType {
    A = 'A',
    D = 'D'
}

export const flightPanelInitialValues = { movementType: 'D', dateFrom: new Date(), dateTo: '', loadingType: 'P', airlineDescription: '', airportDestinationIata: '', flightNumber: '', searchText: '', terminal: process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE === 'mxp' ? 'all' : 't0' };